<div class="uk-position-relative uk-visible-toggle" tabindex="-1" uk-slideshow="ratio: 8:2">
    <ul class="uk-slideshow-items">
        <li>
            <img src="../../assets/images/qone-bnr.jpg" alt="" uk-cover>
            <div class="uk-position-bottom uk-position-medium uk-text-left uk-light">
                <div class="uk-container uk-container-large">
                    <h1 class="uk-margin-remove qt-font-bold">Q-One</h1>
                    <h1 class="uk-margin-remove qt-color-white uk-visible@m" style="font-size: 1.2rem;">Elevate property management with our powerful software. 
                    </h1>
                </div>
            </div>
        </li>
    </ul>
    <div class="uk-light">
        <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slideshow-item="previous"></a>
        <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slideshow-item="next"></a>
    </div>
</div>
 

<!-- <div id="quickatt"></div> -->
<div class="uk-section uk-section-muted qt-bg-white"  >
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left" uk-grid>
            <div>
                <div class="uk-text-left">
                    
                    <h2 class="qt-font-weight-500  uk-margin-remove-top uk-margin-remove-bottom">We take care of everything, so you don’t have to
                    </h2>
                    <p>Q-One is your perfect companion when it comes to managing properties across multiple locations. It has been meticulously designed to overcome a variety of problems that hinder efficient property management. </p>
                     
                    <p>Bring in automation, transparency, and world-class practices with the best-in-class apartment management software. We bring in A-grade security to ensure round-the-clock supervision of all your properties. In the hands of the residents, Q-One also becomes a handy tool for them to make their community living experience more fun and convenient.
                    </p>
                    
                    <p>Retrieving parked vehicles is made easy by our cutting-edge mapping system, which will allow the valet attendants to locate the parked vehicles without causing any delay. Moreover, through the application, the guests are notified on the arrival of the vehicle when it is ready for pickup, thus adding convenience.
                    </p>
               
                </div>
            </div>
            <div> 
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right" style="padding-top: 0;">
                   
                    <div class="uk-padding">
                        <img src="../../assets/images/Property-Management.jpg">
                    </div>
                </div>
           </div>
        </div>
    </div>
</div>
 

 


<div ></div>
<div class="uk-section uk-section-muted">
    <div class="uk-container uk-container-large">
  
   
    
            <div class=" uk-text-left " uk-grid>
               <div>
                <div class="uk-text-left">
                    <h3 class="qt-font-weight-500 uk-margin-remove-top uk-margin-remove-bottom"> Smart Living at your Fingertips
                    </h3>
                    <p><b>Features of the Resident App:
                    </b></p>
                    
                <ul>
                    <li>Integrated Payment Gateway- Provides residents with a range of payment options along with Instant receipts
                    </li>
                    <li>Helpdesk for residents- Raise maintenance requests with ease and track their status
                    </li>
                    <li>Facility/Amenity Booking- Check the availability of common facilities and make your bookings</li>
                    <li>Document Management- Ensures that all documents pertaining to property and tenancy are securely stored
                    </li>
                    <li>Visitor Management System- Visitor information is immediately communicated to the residents, and they can regulate the visitors coming to their apartments
                    </li>
                   
                </ul>
                </div>
            </div>
            </div>

            <div class=" uk-text-left " uk-grid>
                <div>
                 <div class="uk-text-left">
                     <h3 class="qt-font-weight-500 uk-margin-remove-top uk-margin-remove-bottom"> The key to efficient property management
                     </h3>
                     <p><b>Features of the Admin Panel:</b></p>
                     
                 <ul>
                     <li>Units and Users- As an association member, community manager, or property manager, the admin panel will assist you in managing all units and user data from a single point
                    </li>
                     <li>Facility Booking- A robust set of choices is provided to the association to configure both the booking parameters and the common facilities for online booking</li>
                     <li>Helpdesk Tracker- Effectively track requests, complaints, and suggestions, and easily create and set up task owners and timelines
                    </li>
                     <li>Asset and Inventory Management- Maintain accurate details about every asset in the apartment complex; set up reminders for maintenance; and keep records of relevant documents, service histories, and complaints
                     </li>
                     <li>Billing and Collection Automation- With automatic invoicing and pre-configured formulas for calculating dues, you can achieve on-time and hassle-free billing </li>
                     <li>Expense Management- Create and manage purchase requests, bulk upload expenses, and manage vendor invoicing and payments
                    </li>
                     <li>Integrated Payment Gateway- Q-One has been well integrated with many major payment gateways and makes facility booking and paying move-in and move-out charges an easy process </li>
                     <li>Broadcasts- Q-One gives you ample broadcast options like email broadcasts, SMS broadcasts, and push notifications
                    </li>
                     <li>Reports- Comprehensive reports that cover all the aspects of monitoring and managing properties and apartment complexes </li>
 
                 </ul>
                 </div>
             </div>
     
     
             </div>
           

    </div>
</div>
 
 
 
 
<app-contactform></app-contactform>