import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-why-qaptive',
  templateUrl: './why-qaptive.component.html',
  styleUrls: ['./why-qaptive.component.scss']
})
export class WhyQaptiveComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
