// import { Component, OnInit } from '@angular/core';

// @Component({
//   selector: 'app-contactform',
//   templateUrl: './contactform.component.html',
//   styleUrls: ['./contactform.component.scss']
// })
// export class ContactformComponent implements OnInit {

//   constructor() { }

//   ngOnInit(): void {
//   }

// }



import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, FormGroupDirective, Validators, NgForm } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ErrorStateMatcher } from '@angular/material/core';
import { ContactService } from '../shared/service/contact.service';
import { Router } from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import { environment } from '../../environments/environment';
import { formatDate} from '@angular/common';
 

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);

    return (invalidCtrl || invalidParent);
  }
  
}

@Component({
  selector: 'app-contactform',
  templateUrl: './contactform.component.html',
  styleUrls: ['./contactform.component.scss']
})
export class ContactformComponent implements OnInit  {
 
  now = new Date();
  nowFormatted: string;
  public contactFormGroup: FormGroup;
  name: string;
  email: string;
  phone: string;
  position: string;
  comments: string; 
  
  constructor(
    private http: HttpClient, 
    private contactservice: ContactService, 
    private router: Router, 
    private _formBuilder: FormBuilder, 
    private _snackBar: MatSnackBar,
   
    ) { }
  
  onSubmit(form) {    
    this.contactservice.contactSend(form.value).subscribe((data: any) => {
      if (data.status_code == 200) {
        this.router.navigate(['/contact']);
        this.openSnackBar('Submitted successfully', 'Dismiss');  
        this.contactFormGroup = new FormGroup({
          name: new FormControl('', [Validators.required]),
          email: new FormControl('', [Validators.required, Validators.email, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]),
          phone: new FormControl('', [Validators.required]),
          position: new FormControl('', [Validators.required]),
          comments: new FormControl('', [Validators.required]),
        });
      } else {        
        this.openSnackBar('Please try after some time', 'Dismiss');        
      }
    });
  }
  openSnackBar(message, action) {
    this._snackBar.open(message, action, { duration: 5000 });
  }
  
  ngOnInit(): void {
    this.contactFormGroup = new FormGroup({
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]),
      phone: new FormControl('', [Validators.required]),
      position: new FormControl('', [Validators.required]),
      comments: new FormControl('', [Validators.required]),
    });
 
     
  }

  public hasError = (controlName: string, errorName: string) =>{
    return this.contactFormGroup.controls[controlName].hasError(errorName);
  }

}

