import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { MetaService } from '../meta.service';
@Component({
  selector: 'app-video-animations',
  templateUrl: './video-animations.component.html',
  styleUrls: ['./video-animations.component.scss']
})
export class VideoAnimationsComponent implements OnInit {
  title = 'High-Quality Video Animations Services in India | Qaptive';
  constructor(
    private titleService: Title, 
    private metaService: MetaService,
    private meta: Meta
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.meta.updateTag(
      { name: 'description', content: "Elevate your brand with engaging video animations in India! We offer custom-made animations that are both creative and effective. " }
    );
    this.metaService.createCanonicalURL();
  }

}
