import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { MetaService } from '../meta.service';
@Component({
  selector: 'app-quik-laundry',
  templateUrl: './quik-laundry.component.html',
  styleUrls: ['./quik-laundry.component.scss']
})
export class QuikLaundryComponent implements OnInit {
  title = 'Best Smart Laundry Management Software & App | Quiklaundry';
  constructor(
    private titleService: Title, 
    private metaService: MetaService,
    private meta: Meta
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.meta.updateTag(
      { name: 'description', content: "Discover the best Laundry Management Software & App. Streamline operations with an efficient Laundry Management System for optimal results." }
    );
    this.metaService.createCanonicalURL();
  }

}

 