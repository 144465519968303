<div class="uk-position-relative uk-visible-toggle" tabindex="-1" uk-slideshow="ratio: 8:2">
    <ul class="uk-slideshow-items">
        <li>
            <img src="../../assets/images/hospitality_1.jpg" alt="Hospitality Management Solutions" uk-cover>
            <div class="uk-position-bottom uk-position-medium uk-text-left uk-light">
                <div class="uk-container uk-container-large">
                    <h1 class="uk-margin-remove qt-font-bold">Hospitality Management Solutions</h1>
                    <p class="uk-margin-remove qt-color-white uk-visible@m">To develop, manage and rebuild the business process.</p>
                </div>
            </div>
        </li>
    </ul>
    <div class="uk-light">
        <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slideshow-item="previous"></a>
        <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slideshow-item="next"></a>
    </div>
</div>

<div class="uk-section uk-section-muted">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left">
                    <h5 class="qt-color-dark qt-font-bold uk-text-uppercase">Top-rated hospitality management solutions</h5>
                    <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Innovative, robust and cost-effective IT solutions in the hospitality industry.
                    </h2>
                </div>
            </div>
            <div> 
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right">
                    <p> Hospitality is the salient domain that is always in demand and in need of innovation. Our top-rated hospitality management solutions provide robust IT services to develop, manage, and upgrade business models in the hospitality industry. Due to immense competition in the digital era, the survival and success of our hospitality management lie in the customized services and exceptional guest experience. 
                        <br>
                        <br>
                        Qaptive helps business enterprises from the hospitality industry to meet their business requirements and objectives with innovation, robust, and cost-effective IT solutions. We partner with our clientele in the vertical of hospitality to develop, manage, and rebuild the business process, thereby contributing to their greater business outcomes.
                    </p>
                </div>
           </div>
        </div>
    </div>
</div>

<div class="uk-section uk-section-muted uk-padding-remove-top uk-visible@m">
    <div class="uk-container uk-container-large">
        <div class="uk-height-large uk-background-cover uk-light uk-flex" uk-parallax="bgy: -200" style="background-image: url('../../assets/images/hospitality_2.jpg');"> 
        </div> 
    </div>
</div>

<div class="uk-section uk-section-muted uk-padding uk-padding-remove-top uk-padding-remove-left uk-padding-remove-right uk-hidden@m">
    <div class="uk-container uk-container-large">
        <div><img src="../../assets/images/hospitality_2_mob.jpg"></div>
        <!-- uk-visible@m -->
    </div>
</div>
 

<div class="uk-section uk-section-muted uk-padding-remove-top">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left">
                    <h5 class="qt-color-dark qt-font-bold uk-text-uppercase">Periodic challenges </h5>
                    <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Key challenges in hospitality software development</h2>
                </div>
            </div>
            <div> 
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right">
                    <p>Through our continuous and uninterrupted efforts in this field, we are committed to providing help to our clients in overcoming different periodic challenges that include

                    </p>
                     
                </div>
           </div>
        </div>
    </div>
</div>

<div class="uk-section uk-section-muted uk-padding-remove-top uk-visible@m">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-center" uk-grid>
            <div>
                <div class="uk-height-large uk-background-cover uk-light uk-flex" uk-parallax="bgy: -200" style="background-image: url('../../assets/images/hospitality_3.jpg');">
                </div>
            </div>
            <div>
                <div class="uk-height-large uk-background-cover uk-light uk-flex" uk-parallax="bgy: -200" style="background-image: url('../../assets/images/hospitality_4.jpg');">
                </div>
            </div>
        </div>
    </div>
</div>
   

<div class="uk-section uk-section-muted uk-padding-remove-top uk-hidden@m">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-center" uk-grid>
            <div>
                <img src="../../assets/images/hospitality_3_mob.jpg">
            </div>
            <div>
                <img src="../../assets/images/hospitality_4_mob.jpg">
            </div>
        </div>
    </div>
</div>
  

<div class="uk-section uk-section-muted uk-padding-remove-top">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div> 
                <h4 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Business expansion</h4>
                <p>To proliferate client business enterprises through reducing costs and optimal processes.</p>
            </div>
            <div> 
                <h4 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Increasing demands</h4>
                <p> Meeting ever-increasing demands of clients.</p>     
            </div> 
            <div> 
                <h4 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Risk management</h4>
                <p> Evaluating risk involved in hospitality infrastructure.</p>     
            </div> 
        </div>
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div> 
                <h4 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Agility</h4>
                <p>Providing a fast-paced environment that helps in the effective integration of entities at the client's end</p>
            </div>
            <div> 
                <h4 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Simplified life cycle</h4>
                <p> To facilitate an easy development lifecycle of customer acquisitions and interactions</p>     
            </div> 
            <div> 
                <h4 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Management</h4>
                <p>To make way for quick speed-to-market and orderly management of the client’s flourishing product and services portfolio.
                </p>     
            </div> 
        </div>
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div> 
                <h4 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Innovation</h4>
                <p>Utilization of current and cutting-edge technologies for developing intuitive business models.</p>
            </div>
            <div> 
                <p>&nbsp;</p>     
            </div> 
            <div>  
                <p>&nbsp;</p>     
            </div> 
        </div>
    </div>
</div>

<div class="uk-section uk-section-muted uk-padding-remove-top uk-visible@m">
    <div class="uk-container uk-container-large">
        <div class="uk-height-large uk-background-cover uk-light uk-flex" uk-parallax="bgy: -200" style="background-image: url('../../assets/images/hospitality_5.jpg');"> 
        </div> 
    </div>
</div>
<div class="uk-section uk-section-muted uk-padding uk-padding-remove-top uk-padding-remove-left uk-padding-remove-right uk-hidden@m">
    <div class="uk-container uk-container-large">
        <div><img src="../../assets/images/hospitality_5_mob.jpg"></div>
        <!-- uk-visible@m -->
    </div>
</div>
 
<div class="uk-section uk-section-muted  uk-padding-remove-top">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left">
                    <h5 class="qt-color-dark qt-font-bold uk-text-uppercase">What we offer</h5>
                    <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Superior service quality and exceptional customer services.
                    </h2>
                </div>
            </div>
            <div> 
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right">
                    <p><a href="https://www.qaptive.co.in/">Qaptive</a> enables clients from the hospitality industry to simplify their existing business operations and to provide a digital interface for business growth. We help our clients to develop comprehensive solutions that retain customers by superior service quality and exceptional customer services with significant cost savings.

                    </p>
                </div>
           </div>
        </div>
    </div>
</div>
<div class="uk-section uk-section-muted uk-padding-remove-top uk-visible@m">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-center" uk-grid>
            <div>
                <div class="uk-height-large uk-background-cover uk-light uk-flex" uk-parallax="bgy: -200" style="background-image: url('../../assets/images/hospitality_6.jpg');">
                </div>
            </div>
            <div>
                <div class="uk-height-large uk-background-cover uk-light uk-flex" uk-parallax="bgy: -200" style="background-image: url('../../assets/images/hospitality_7.jpg');">
                </div>
            </div>
        </div>
    </div>
</div>

<div class="uk-section uk-section-muted uk-padding-remove-top uk-hidden@m">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-center" uk-grid>
            <div>
                <img src="../../assets/images/hospitality_6_mob.jpg">
            </div>
            <div>
                <img src="../../assets/images/hospitality_7_mob.jpg">
            </div>
        </div>
    </div>
</div>

 

<div class="uk-section uk-section-muted uk-padding-remove-top">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left"> 
                    <h5 class="qt-color-dark qt-font-bold uk-text-uppercase">&nbsp;</h5>
                    <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Our expertise is offered in <br>the domains, such as</h2>
                </div>
            </div>
            <div> 
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right">  
                    <ul class="uk-list">
                        <li>Travel booking solutions</li>   
                        <li>Customer relationship management with features like</li>   
                        <li>Campaigns</li>   
                        <li>Loyalty programs</li>   
                        <li>Surveys</li>   
                        <li>Discounts</li>     
                        <li>Facilities to manage rates for different room types, policies, incentives, and others.</li>     
                        <li>Provides hotel information, rate announcements, marketing campaigns on various social media sites.</li>     
                        <li>Catering management solution.</li>     
                        <li>Laundry management solution for the clients.</li>     
                        <li>Inventory management solution. </li>     
                   </ul> 
                </div>
           </div>
        </div>
    </div>
</div>

<div class="uk-section uk-section-muted uk-padding-remove-top uk-visible@m">
    <div class="uk-container uk-container-large">
        <div class="uk-height-large uk-background-cover uk-light uk-flex" uk-parallax="bgy: -200" style="background-image: url('../../assets/images/hospitality_8.jpg');"> 
        </div> 
    </div>
</div>

<div class="uk-section uk-section-muted uk-padding uk-padding-remove-top uk-padding-remove-left uk-padding-remove-right uk-hidden@m">
    <div class="uk-container uk-container-large">
        <div><img src="../../assets/images/hospitality_8_mob.jpg"></div>
        <!-- uk-visible@m -->
    </div>
</div>

<div class="uk-section uk-section-muted uk-padding-remove-top">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left"> 
                    <h5 class="qt-color-dark qt-font-bold uk-text-uppercase">&nbsp;</h5>
                    <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Benefits of our hospitality management solutions</h2>
                </div>
            </div>
            <div> 
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right">  
                     <p>
                        Our core expertise lies in aligning the technologies and IT strategy toward fulfilling the business goals of our consumers from the hospitality industry.
                     </p>
                    <h4 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">In the process, we have commitment to
                    </h4>
                    <ul class="uk-list">
                        <li>Implement a trustworthy and reliable strategy</li>   
                        <li>Deliver quality and best-in-class results</li>   
                        <li>Cost-effectiveness in IT operations</li>   
                        <li>Fabricate innovative hospitality solutions</li>   
                        <li>Sustain cross-platform product usability</li>   
                        <li>An Effective rise in ROI</li>   
                   </ul> 
                </div>
           </div>
        </div>
    </div>
</div>

 