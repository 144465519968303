<div class="uk-position-relative uk-visible-toggle" tabindex="-1" uk-slideshow="ratio: 8:2">
    <ul class="uk-slideshow-items">
        <li>
            <img src="../../assets/images/loqqat_ride_case_study_1.jpg" alt="Loqqat Ride" uk-cover>
            <div class="uk-position-bottom uk-position-medium uk-text-left uk-light">
                <div class="uk-container uk-container-large">
                    <h1 class="uk-margin-remove qt-font-bold">Employee Ridership Solution</h1>
                    <p class="uk-margin-remove qt-color-white uk-visible@m">Corporates</p>
                </div>
            </div>
        </li>
    </ul>
    <div class="uk-light">
        <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slideshow-item="previous"></a>
        <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slideshow-item="next"></a>
    </div>
</div>

<div class="uk-section uk-section-muted">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left">
                    <h5 class="qt-color-dark qt-font-bold uk-text-uppercase">To manage the daily commute of the employees</h5>
                    <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Business Problem</h2>
                </div>
            </div>
            <div> 
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right">
                    <p>  
                        Our client was finding it difficult to manage the daily commute of the employees and thus to increase employee productivity. Clients wanted to use <a href="https://www.qaptive.co.in/fleet-management-solutions">fleet management solution</a>; in order to help them in optimizing fleet operations, managing the commute of employees. A methodical transport management technology that helps any organization to establish smooth commutation for its employee. To transform the hectic transit, to a plain sailing and seamless experience, for its employees. The software makes technology an important part of it and thereby ensuring a stress-free, safe, and punctual transportation experience for employees.
                    </p>
                </div>
           </div>
        </div>
    </div>
</div>

<div class="uk-section uk-section-muted uk-padding-remove-top uk-visible@m">
    <div class="uk-container uk-container-large">
        <div class="uk-height-large uk-background-cover uk-light uk-flex" uk-parallax="bgy: -200" style="background-image: url('../../assets/images/loqqat_ride_case_study_2.jpg');"> 
        </div> 
    </div>
</div>

<div class="uk-section uk-section-muted uk-padding uk-padding-remove-top uk-padding-remove-left uk-padding-remove-right uk-hidden@m">
    <div class="uk-container uk-container-large">
        <div><img src="../../assets/images/loqqat_ride_case_study_2_mob.jpg"></div>
        <!-- uk-visible@m -->
    </div>
</div>

<div class="uk-section uk-section-muted uk-padding-remove-top">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left">
                    <h5 class="qt-color-dark qt-font-bold uk-text-uppercase">Streamline every aspect of fleet operations of an organization </h5>
                    <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Solution</h2>
                </div>
            </div>
            <div> 
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right">
                    <p> Our solution helped to streamline every aspect of fleet operations of an organization to manage the employee commute. It also helped for implementing proactive maintenance and commute processes. Our employee fleet management solution incorporated with features such as:
                    </p>
                    <ul class="uk-list">
                        <li>Instant notification</li>  
                        <li>Real-time tracking</li>  
                        <li>Advanced reservation for employees</li>  
                        <li>Amenities reservation such as wheelchair space and like rack</li>  
                        <li>Provides manual check-in</li>  
                   </ul>
                    <p> We provide a finer transport management software that addresses all the issues concerning fleet management for employee commute, a step ahead.
                    </p>
                </div>
                
           </div>
        </div>
    </div>
</div> 
 

<div class="uk-section uk-section-muted uk-padding-remove-top uk-padding-remove-bottom uk-visible@m">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-center" uk-grid>
            <div>
                <div class="uk-height-large uk-background-cover uk-light uk-flex" uk-parallax="bgy: -200" style="background-image: url('../../assets/images/loqqat_ride_case_study_3.jpg');">
                </div>
            </div>
            <div>
                <div class="uk-height-large uk-background-cover uk-light uk-flex" uk-parallax="bgy: -200" style="background-image: url('../../assets/images/loqqat_ride_case_study_4.jpg');">
                </div>
            </div>
        </div>
    </div>
</div> 
<div class="uk-section uk-section-muted uk-padding-remove-top uk-padding-remove-bottom uk-hidden@m">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-center" uk-grid>
            <div>
                <img src="../../assets/images/loqqat_ride_case_study_3_mob.jpg">
            </div>
            <div>
                <img src="../../assets/images/loqqat_ride_case_study_4_mob.jpg">
            </div>
        </div>
    </div>
</div> 


<div class="uk-section uk-section-muted">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left">
                    <h5 class="qt-color-dark qt-font-bold uk-text-uppercase">Stay informed in real-time</h5>
                    <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Outcomes</h2>
                </div>
            </div>
            <div> 
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right">
                     <ul class="uk-list">
                        <li>Stay informed in real-time.
                        </li>  
                        <li>View alerts and gets notifications at-a-glance.</li>
                        <li> Customize data visualization and drill down into metrics to see detailed reporting.</li>
                        <li>Plan and optimize employee commute.</li>
                        <li> Easily schedule and adjust schedules accordingly to reduce costs.</li>   
                        <li>Navigate and route management services.</li>   
                        <li>A smooth and hi-tech fleet management solution for employees and an advanced web interface to control and access your entire fleet with ease.
                        </li>   
                        <li>Ensuring more safety and security during the employee commute.</li>   
                        <li>Loqqatride turns to be the eminent choice for a perfect solution of mobile technology to manage employee transportation.</li>   
                   </ul>
                </div>
           </div>
        </div>
    </div>
</div> 
  

<div class="uk-section uk-section-muted uk-padding-remove-top uk-visible@m">
    <div class="uk-container uk-container-large">
        <div class="uk-height-large uk-background-cover uk-light uk-flex" uk-parallax="bgy: -200" style="background-image: url('../../assets/images/loqqat_ride_case_study_5.jpg');"> 
        </div> 
    </div>
</div> 
<div class="uk-section uk-section-muted uk-padding uk-padding-remove-top uk-padding-remove-left uk-padding-remove-right uk-hidden@m">
    <div class="uk-container uk-container-large">
        <div><img src="../../assets/images/loqqat_ride_case_study_5_mob.jpg"></div>
        <!-- uk-visible@m -->
    </div>
</div>



<div class="uk-section uk-section-muted uk-padding-remove-top">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left">
                    <h5 class="qt-color-dark qt-font-bold uk-text-uppercase">Web-based employee fleet management software</h5>
                    <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">About the Client</h2>
                </div>
            </div>
            <div> 
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right"> 
                    <p>Our client from the health care industry works with employees, partners, physicians and other health workers to promote wellness, develop reliable and affordable health plans to support patients with clear thoughtful guidance. With more than 110 employees utilizing shuttle service for their commute to work, managing transportation for multiple shifts is a stressful activity that required coordination with multiple departments. Hence the employee transportation was critical to meet the multiple shift timings.<br>
                   
                    </p>
                </div>
           </div>
        </div>
    </div>
</div>
