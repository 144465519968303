import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { MetaService } from '../meta.service';
@Component({
  selector: 'app-wayfinding',
  templateUrl: './wayfinding.component.html',
  styleUrls: ['./wayfinding.component.scss']
})
export class WayfindingComponent implements OnInit {
  title = 'Advanced Indoor Navigation & Wayfinding App';
  constructor(
    private metaService: MetaService,
    private titleService: Title, 
    private meta: Meta
  ) { }


  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.meta.updateTag(
      { name: 'description', content: "Discover seamless indoor navigation with our advanced wayfinding app & software. The ultimate indoor navigation app powered by cutting-edge technology." }
    );
    this.metaService.createCanonicalURL();
  }


}


 