 


<!-- <div class="uk-section uk-section-muted qt-bg-dark">
    <div class="uk-container uk-container-small">
        <ul uk-accordion="multiple: true">
            <li class="uk-open">
                <a class="uk-accordion-title" href="#">
                    <span class="uk-heading-small qt-heading-small">IOS Developer</span>
                </a>
                <div class="uk-accordion-content">
                    <p>Do you consider yourself to be a creative individual who is willing to step outside of the box in an effort to raise the bar in mobile technology development?
                        Then you might be the right team member we are looking for!
                        <br>
                        <br>
                        Our ideal candidate should be able to actively contribute to the development of fast, secure, and reliable apps, work well under tight deadlines, and have an eye for detail.
                    </p>
                    <h5 class="qt-color-light qt-font-bold uk-text-uppercase">iOS Developer Duties and Responsibilities:</h5> 
                    <ul class="uk-list uk-list-bullet">
                        <li>Design and build advanced app features for the iOS platform</li>
                        <li>Independently craft project solutions by applying solid Object-Oriented-Design principles</li>
                        <li>Working in a team of talented iOS engineers developing amazing native apps</li> 
                        <li>Work closely with product management & UX to execute an idea from concept to delivery using excellent software design, coding, & processes</li>  
                        <li>Continuously discover, evaluate, and implement new technologies to maximize development efficiency</li>  
                    </ul>
                    <h5 class="qt-color-light qt-font-bold uk-text-uppercase">iOS Developer Requirements:</h5> 
                    <ul class="uk-list uk-list-bullet">
                        <li>3+ years of experience in Native iOS development using Swift and Objective C</li>
                        <li>Strong computer fundamentals and OO programming concepts</li>
                        <li>Understanding of Apple’s design principles and interface guidelines</li>  
                        <li>Good knowledge on AutoLayout, offline storage, threading, memory management and performance tuning</li>  
                        <li>Experience working with iOS frameworks and APIs such as Cocoa Touch, Foundation, MapKit, etc.</li> 
                        <li>Familiarity with native build tools (Expo, React-native cli, gradle)</li>  
                        <li>Familiarity with RESTful APIs to connect iOS applications to back-end services</li>  
                        <li>Familiarity with cloud message APIs and Push notifications</li>  
                        <li>Knowledge on Swift UI is a plus</li>  
                        <li>Solid understanding of mobile landscape, iOS architectures, trends, popular Third-party Libraries & APIs used in iOS Development</li>   
                    </ul>
                    <p>
                       <strong>Immediate joiners will be given preference, no remote working options available.</strong>

                        <br>
                        <br>
                        Please include Job title as a Subject of mail while sending resumes. Kindly mail your updated profile to the HR Manager along with the current, expected CTC and notice period details.
                        <br>
                        <br>
                        <strong>Job Type: Full-time</strong>
                    </p>
                    <div class="qt-badge-light">
                        <span class="uk-badge">Swift</span>
                        <span class="uk-badge">Swift UI</span>
                        <span class="uk-badge">Cocoa Touch</span>
                        <span class="uk-badge">GIT</span>
                        <span class="uk-badge">MapKit</span> 
                        <span class="uk-badge">UI / UX</span> 
                    </div> 
                </div>
                <hr class="qt-ht-dark">
            </li>
            <li>
                <a class="uk-accordion-title" href="#">
                    <span class="uk-heading-small qt-heading-small">Software Testing Intern</span>
                </a>
                <div class="uk-accordion-content">
                    <p>We expect Software Testing Interns to be significantly more than manual testers - you write scripts to automate testing and create tools so developers can test their own code. As a Test Intern, you can collaborate with a team of developers, identify weak spots, and constantly design better and creative ways to detect issues.
                    </p>
                    <h5 class="qt-color-light qt-font-bold uk-text-uppercase">Job Responsibilities</h5> 
                    <ul class="uk-list uk-list-bullet">
                        <li>Improving test coverage, faster releases and ensure production health</li>
                        <li>Work closely with development teams to improve test coverage and quality</li>
                        <li>Hands-on ability to automate repeated tasks and build test coverage through existing or new infrastructure</li> 
                        <li>Write moderately complex code/scripts to test systems, implementing test harnesses and infrastructure as necessary</li>  
                        <li>Continuously discover, evaluate, and implement new technologies to maximize development efficiency</li>  

  


                    </ul>
                    <h5 class="qt-color-light qt-font-bold uk-text-uppercase">Minimum qualifications:</h5> 
                    <ul class="uk-list uk-list-bullet">
                        <li>1-2 years of relevant work experience, including development and/or test automation experience or equivalent training or certified in the software testing.</li>
                        <li>Coding experience in Java or Python</li>
                        <li>Extensive knowledge of Linux environments</li>  
                        <li>Sound knowledge of test methodologies, writing test plans, creating test cases, and debugging</li>   
                    </ul>
                    <p>
                       <strong>CANDIDATES FROM ERNAKULAM/KOCHI only preferred.</strong>

                        <br>
                        <br>
                        Please include Job title as a Subject of mail while sending resumes. Kindly mail your updated profile to HR Manager along with the current, expected CTC and notice period details.
                    </p>
                    <div class="qt-badge-light">
                        <span class="uk-badge">Manual Testing</span>
                        <span class="uk-badge">Selenium</span>
                        <span class="uk-badge">Automation Testing</span>
                        <span class="uk-badge">GIT</span> 
                    </div> 
                </div>
                <hr class="qt-ht-dark">
            </li>
             
            
        </ul>
    </div>
</div> -->


<div class="uk-section uk-section-muted">
    <div class="uk-container uk-container-xsmall careerform-bg">
        <h2 class="qt-font-weight-500 uk-margin uk-margin-remove-top uk-text-center">
            Apply for your position 
        </h2>
        <br>
        <form [formGroup]="careerFormGroup" #careerForm="ngForm" (ngSubmit)="onSubmit(careerForm)"> 
            <div class="uk-child-width-expand@s uk-text-left" uk-grid>
                <div >
                    <div class="uk-text-left">
                        <div class="uk-margin filez ">
                            <input type="file" />
                        </div> 
                        <div class="uk-margin">
                            <mat-form-field>
                                <input matInput required formControlName="name"  class="uk-input" type="text" placeholder="Full Name">
                                <mat-error *ngIf="hasError('name', 'required')">Name is required</mat-error>
                            </mat-form-field> 
                        </div> 
                        <div class="uk-margin">
                            <mat-form-field>
                                <input matInput required formControlName="email" class="uk-input" type="email" placeholder="Email">
                                <!-- <mat-error *ngIf="hasError('email', 'required')">Email is required</mat-error> -->
                                <mat-error *ngIf="careerFormGroup.get('email').hasError('email')">Please enter a valid email address</mat-error>
                            </mat-form-field> 
                        </div> 

                        <div class="uk-margin"> 
                            <mat-form-field>
                                
                                <input matInput required formControlName="phone" maxlength="15" minlength="10" class="uk-input" type="text" placeholder="Phone">
                                <mat-error *ngIf="hasError('phone', 'required')">Phone is required</mat-error>
                            </mat-form-field> 
                        </div> 
                        <div class="uk-margin dd_contact">
                            <mat-form-field appearance="fill" >
                                <mat-label>Marital Status</mat-label>
                                <select matNativeControl required formControlName="position" class="option-cr">                            
                                    <option value="male">Male</option>
                                    <option value="male">Female</option>
                                    <option value="male">Others</option>
                                </select>
                            </mat-form-field>
                        </div>
                        
                        <div class="uk-margin">
                            <p><b>Current Address</b></p> 
                        </div> 
                        <div class="uk-margin">
                            <mat-form-field>
                                <input matInput required formControlName="email" class="uk-input" type="email" placeholder="Current Address">
                                <!-- <mat-error *ngIf="hasError('email', 'required')">Email is required</mat-error> -->
                                <!-- <mat-error *ngIf="careerFormGroup.get('email').hasError('email')">Current Address</mat-error> -->
                            </mat-form-field> 
                        </div> 
                        <div class="uk-margin dd_contact">
                            <mat-form-field appearance="fill" >
                                <mat-label>State</mat-label>
                                <select matNativeControl required formControlName="position" class="option-cr">                            
                                    <option value="Assistant Business Developement Manager">Kerala</option>
                                </select>
                            </mat-form-field>
                        </div> 
                        <div class="uk-margin dd_contact">
                            <mat-form-field appearance="fill" >
                                <mat-label>Country</mat-label>
                                <select matNativeControl required formControlName="position" class="option-cr">                            
                                    <option value="Assistant Business Developement Manager">India</option>
                                </select>
                            </mat-form-field>
                        </div> 
                        <div class="uk-margin">
                            <mat-form-field>
                                <input matInput required formControlName="email" class="uk-input" type="email" placeholder="Pin Code">
                                <!-- <mat-error *ngIf="hasError('email', 'required')">Email is required</mat-error> -->
                                <!-- <mat-error *ngIf="careerFormGroup.get('email').hasError('email')">Current Address</mat-error> -->
                            </mat-form-field> 
                        </div> 
                        <div class="uk-margin">
                            <p class="floatl"><b>Permanent Address</b></p> 
                            <div class="floatr">
                            <input type="checkbox" id="vehicle3" name="vehicle3" value="Boat">
<label for="vehicle3"> Same as Above</label>
</div>
                        </div> 
                        <div class="uk-margin">
                            <mat-form-field>
                                <input matInput required formControlName="email" class="uk-input" type="email" placeholder="Current Address">
                                <!-- <mat-error *ngIf="hasError('email', 'required')">Email is required</mat-error> -->
                                <!-- <mat-error *ngIf="careerFormGroup.get('email').hasError('email')">Current Address</mat-error> -->
                            </mat-form-field> 
                        </div> 
                        <div class="uk-margin dd_contact">
                            <mat-form-field appearance="fill" >
                                <mat-label>State</mat-label>
                                <select matNativeControl required formControlName="position" class="option-cr">                            
                                    <option value="Assistant Business Developement Manager">Kerala</option>
                                </select>
                            </mat-form-field>
                        </div> 
                        <div class="uk-margin dd_contact">
                            <mat-form-field appearance="fill" >
                                <mat-label>Country</mat-label>
                                <select matNativeControl required formControlName="position" class="option-cr">                            
                                    <option value="Assistant Business Developement Manager">India</option>
                                </select>
                            </mat-form-field>
                        </div> 
                        <div class="uk-margin">
                            <mat-form-field>
                                <input matInput required formControlName="email" class="uk-input" type="email" placeholder="Pin Code">
                                <!-- <mat-error *ngIf="hasError('email', 'required')">Email is required</mat-error> -->
                                <!-- <mat-error *ngIf="careerFormGroup.get('email').hasError('email')">Current Address</mat-error> -->
                            </mat-form-field> 
                        </div> 
                        <div class="uk-margin">
                            <p><b>Work Experience</b></p> 
                        </div> 

                        <div class="uk-margin  uk-padding-remove-top uk-margin-remove-top mult">
                            <div class="uk-child-width-1-4@m pt30" uk-grid uk-lightbox="animation: slide">
                                <div>
                                    <mat-form-field>
                                        <input matInput required formControlName="email" class="uk-input" type="text" placeholder="Organization">
                                        <!-- <mat-error *ngIf="hasError('email', 'required')">Email is required</mat-error> -->
                                        <!-- <mat-error *ngIf="careerFormGroup.get('email').hasError('email')">Current Address</mat-error> -->
                                    </mat-form-field> 
                                </div>
                                <div>
                                    <mat-form-field>
                                        <input matInput required formControlName="email" class="uk-input" type="text" placeholder="Designation">
                                        <!-- <mat-error *ngIf="hasError('email', 'required')">Email is required</mat-error> -->
                                        <!-- <mat-error *ngIf="careerFormGroup.get('email').hasError('email')">Current Address</mat-error> -->
                                    </mat-form-field> 
                                </div>
                                <div>
                                    <mat-form-field>
                                        <input matInput required formControlName="email" class="uk-input" type="date" placeholder="Start Date">
                                        <!-- <mat-error *ngIf="hasError('email', 'required')">Email is required</mat-error> -->
                                        <!-- <mat-error *ngIf="careerFormGroup.get('email').hasError('email')">Current Address</mat-error> -->
                                    </mat-form-field> 
                                </div>
                                <div>
                                    <mat-form-field>
                                        <input matInput required formControlName="email" class="uk-input" type="date" placeholder=" End date  ">
                                        <!-- <mat-error *ngIf="hasError('email', 'required')">Email is required</mat-error> -->
                                        <!-- <mat-error *ngIf="careerFormGroup.get('email').hasError('email')">Current Address</mat-error> -->
                                    </mat-form-field> 
                                </div>
                            </div>   
                         <p style="text-align: right; margin: 0;"><button class="addbtn">+</button></p>       
                        </div>


                        <div class="uk-margin">
                            <p><b>Academic Tracking</b></p> 
                        </div> 

                        <div class="uk-margin  uk-padding-remove-top uk-margin-remove-top mult">
                            <div class="uk-child-width-1-4@m pt30" uk-grid uk-lightbox="animation: slide">
                                <div>
                                    <mat-form-field>
                                        <input matInput required formControlName="email" class="uk-input" type="text" placeholder="Organization">
                                        <!-- <mat-error *ngIf="hasError('email', 'required')">Email is required</mat-error> -->
                                        <!-- <mat-error *ngIf="careerFormGroup.get('email').hasError('email')">Current Address</mat-error> -->
                                    </mat-form-field> 
                                </div>
                                <div>
                                    <mat-form-field>
                                        <input matInput required formControlName="email" class="uk-input" type="text" placeholder="Designation">
                                        <!-- <mat-error *ngIf="hasError('email', 'required')">Email is required</mat-error> -->
                                        <!-- <mat-error *ngIf="careerFormGroup.get('email').hasError('email')">Current Address</mat-error> -->
                                    </mat-form-field> 
                                </div>
                                <div>
                                    <mat-form-field>
                                        <input matInput required formControlName="email" class="uk-input" type="date" placeholder="Start Date">
                                        <!-- <mat-error *ngIf="hasError('email', 'required')">Email is required</mat-error> -->
                                        <!-- <mat-error *ngIf="careerFormGroup.get('email').hasError('email')">Current Address</mat-error> -->
                                    </mat-form-field> 
                                </div>
                                <div>
                                    <mat-form-field>
                                        <input matInput required formControlName="email" class="uk-input" type="date" placeholder=" End date  ">
                                        <!-- <mat-error *ngIf="hasError('email', 'required')">Email is required</mat-error> -->
                                        <!-- <mat-error *ngIf="careerFormGroup.get('email').hasError('email')">Current Address</mat-error> -->
                                    </mat-form-field> 
                                </div>
                            </div>   
                         <p style="text-align: right; margin: 0;"><button class="addbtn">+</button></p>       
                        </div>



                        <div class="uk-margin dd_contact">
                            <mat-form-field appearance="fill" >
                                <mat-label>You're Applying for</mat-label>
                                <select matNativeControl required formControlName="position" class="option-cr">                            
                                    <!-- <option  value="Web Developer -Junior">Web Developer -Junior</option> -->
                                    <!-- <option value="PHP Interns">PHP Interns</option> -->
                                    <!-- <option value="React native Developer">React native Developer</option> -->
                                    <!-- <option value="React native Interns">React native Interns</option> -->
                                    <option value="Assistant Business Developement Manager">Assistant Business Developement Manager</option>
                                    <!-- <option value="Flutter Interns">Flutter Interns</option> -->
                                    <!-- <option value="IOS Developer">IOS Developer</option> -->
                                    <!-- <option value="Software Testing Junior">Software Testing Junior</option> -->
                                </select>
                            </mat-form-field>
                        </div> 

                        <div class="uk-margin resume-up">
                            <label for="file">Upload Resume*</label><br>
                            <input formControlName="file" id="file" type="file" class="form-control" (change)="onFileChange($event)">
                            <!-- <div *ngIf="f.file.touched && f.file.invalid" class="alert alert-danger">
                                <div *ngIf="f.file.errors.required">File is required.</div>
                            </div> -->
                            <!-- <input class="uk-input" type="file" placeholder="Resume"> -->
                        </div> 


                        <div class="uk-margin">
                            <mat-form-field>
                                <input matInput required formControlName=" " class="uk-input" type="tel" placeholder="Current or last drawn salary">
                                <!-- <mat-error *ngIf="hasError('experience', 'required')">Current or last drawn salary</mat-error> -->
                            </mat-form-field> 
                        </div> 

                        <div class="uk-margin">
                            <mat-form-field>
                                <input matInput required formControlName=" " class="uk-input" type="tel" placeholder="Expecting Salary">
                                <!-- <mat-error *ngIf="hasError('experience', 'required')">Current or last drawn salary</mat-error> -->
                            </mat-form-field> 
                            <div class="floatr">
                                <label for="vehicle"> Is it Negotiable</label>
                                <input type="checkbox" id="vehicle" name="vehicle" value="Boat">
  
    </div>
                        </div> 

                        <div class="uk-margin">
                            <mat-form-field>
                                <input matInput required formControlName=" " class="uk-input" type="tel" placeholder="Notes">
                                <!-- <mat-error *ngIf="hasError('experience', 'required')">Current or last drawn salary</mat-error> -->
                            </mat-form-field> 
                        </div> 

                     

                  
                        <div class="uk-margin">
                            <button class="uk-button uk-button-secondary qt-btn qt-bg-dark qt-color-white uk-margin-large" id="submit-button" style="margin: 30px 0;" type="submit" [disabled]="!careerFormGroup.valid">Submit</button>
                            <span  id="loading"></span> <span style="display: none;" id="afterLoadingText">We appreciate your interest to our company, we will contact you soon. </span><span style="display: none;" id="afterLoadingTextError">We are facing some difficulty in server, please try after sometime. </span>
                        </div> 
                    </div>
                </div> 
                
            </div>
        </form>
    </div>
</div>


<div id="modal-center" class="uk-flex-top" uk-modal>
    <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">

        <button class="uk-modal-close-default" type="button" uk-close></button>

       <h3 class="uk-text-center">Enter Your Contact Number or Email</h3>
       <input type="text" class="contact-form" placeholder="Email or Contact No">
       <p class="uk-text-center"><button class="contact-btn">SUBMIT</button></p>

    </div>
</div>