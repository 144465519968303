import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { MetaService } from '../meta.service';
@Component({
  selector: 'app-hospitality-management-solutions',
  templateUrl: './hospitality-management-solutions.component.html',
  styleUrls: ['./hospitality-management-solutions.component.scss']
})
export class HospitalityManagementSolutionsComponent implements OnInit {
  title = 'Optimize Your Hospitality Business with our Innovative Management Solutions in India';
  constructor(
    private titleService: Title, 
    private metaService: MetaService,
    private meta: Meta
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.meta.updateTag(
      { name: 'description', content: "Simplify Your Hospitality Business Operations & Optimize Your Inventory, Reservations, and More with Our Advanced Hospitality Management Software." }
    );
    this.metaService.createCanonicalURL();
  }

}
