<div class="uk-position-relative uk-visible-toggle" tabindex="-1" uk-slideshow="ratio: 8:2">
    <ul class="uk-slideshow-items">
        <li>
            <img src="../../assets/images/healthcare_management_1.jpg" alt="Healthcare Management" uk-cover>
            <div class="uk-position-bottom uk-position-medium uk-text-left uk-light">
                <div class="uk-container uk-container-large">
                    <h1 class="uk-margin-remove qt-font-bold">Healthcare Management Solutions</h1>
                    <p class="uk-margin-remove qt-color-white uk-visible@m">Amp up your infrastructural capabilities
                    </p>
                </div>
            </div>
        </li>
    </ul>
    <div class="uk-light">
        <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slideshow-item="previous"></a>
        <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slideshow-item="next"></a>
    </div>
</div>

<div class="uk-section uk-section-muted">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left">
                    <h5 class="qt-color-dark qt-font-bold uk-text-uppercase">Healthcare application development solutions</h5>
                    <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Optimize health records, appointment schedules, medical system, administrative protocols
                    </h2>
                </div>
            </div>
            <div> 
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right">
                    <p> The drastic increase in the number of healthcare consumers and rapidly evolving healthcare practises, traditional clinic management are not sufficient due to the rocketing needs of the healthcare industry.
                        <br>
                        <br>
                        The main objective of our healthcare management solutions is to help hospitals and medical practises efficiently handle their day-to-day operations.
                        Health management solutions that integrate multiple services provide a ray of hope to organizations. Our solution helps your organization to optimize health records, appointment schedules, medical system, administrative protocols, legal and compliance requirements, business intelligence and revenue cycle management.
                        <br>
                        <br>
                        Our solution for healthcare management allows you to amp up your infrastructural capabilities and provide you healthcare application development solutions for recording, analysing and sharing patient data.
                        
                    </p>
                </div>
           </div>
        </div>
    </div>
</div>

<div class="uk-section uk-section-muted uk-padding-remove-top uk-visible@m">
    <div class="uk-container uk-container-large">
        <div class="uk-height-large uk-background-cover uk-light uk-flex" uk-parallax="bgy: -200" style="background-image: url('../../assets/images/healthcare_management_2.jpg');"> 
        </div> 
    </div>
</div>
<div class="uk-section uk-section-muted uk-padding uk-padding-remove-top uk-padding-remove-left uk-padding-remove-right uk-hidden@m">
    <div class="uk-container uk-container-large">
        <div><img src="../../assets/images/healthcare_management_2_mob.jpg"></div>
        <!-- uk-visible@m -->
    </div>
</div>
 
<div class="uk-section uk-section-muted uk-padding-remove-top uk-padding uk-padding-remove-left uk-padding-remove-right">
    <div class="uk-container uk-container-large">
        <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">The growing challenges</h2>
    </div>
</div>

    
<div class="uk-section uk-section-muted uk-padding-remove-top">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left"> 
                    <h4 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Patient experience management</h4>
                    <p>Patience, experience and response to advanced solutions has a greater impact on the investments being made today in this field. It is imperative to check the feedback and patient experience are positive.
                    </p>
                </div>
            </div>
            <div>
                <div class="uk-text-left"> 
                    <h4 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Managing the data</h4>
                    <p>A lot of data storage issues could come up due to the rendering of smart devices solutions into the processes. Thus, adoption of advanced tools and innovation comes with a data crisis.
                    </p>
                </div>
            </div>
            <div>
                <div class="uk-text-left"> 
                    <h4 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Increasing the cost</h4>
                    <p>The rising demands in the healthcare industry also increases the healthcare costs too, which surges the strain on the healthcare industry.
                    </p>
                </div>
            </div>
            <div>
                <div class="uk-text-left"> 
                    <h4 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Medicinal evolvement</h4>
                    <p>Current and ongoing physician shortages and patient dissatisfaction due to the changing nature of the industry.
                    </p>
                </div>
            </div>
            <div>
                <div class="uk-text-left"> 
                    <h4 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Professional training</h4>
                    <p>The altering environment requires continuous training of administrative staff towards system changes and is proving to be difficult and inefficient.
                    </p>
                </div>
            </div>
             
        </div>
    </div>
</div>

<div class="uk-section uk-section-muted uk-padding-remove-top uk-visible@m">
    <div class="uk-container uk-container-large">
        <div class="uk-height-large uk-background-cover uk-light uk-flex" uk-parallax="bgy: -200" style="background-image: url('../../assets/images/healthcare_management_3.jpg');"> 
        </div> 
    </div>
</div> 


<div class="uk-section uk-section-muted uk-padding uk-padding-remove-top uk-padding-remove-left uk-padding-remove-right uk-hidden@m">
    <div class="uk-container uk-container-large">
        <div><img src="../../assets/images/healthcare_management_3_mob.jpg"></div>
        <!-- uk-visible@m -->
    </div>
</div>


<div class="uk-section uk-section-muted uk-padding-remove-top">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left">
                    <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Comprehensive healthcare management solutions</h2>
                    <p><a href="Qaptive to https://www.qaptive.co.in/">Qaptive</a> healthcare services are aimed at improving efficiencies and advancing quality of care by utilizing technology and innovation.
                    </p>
                </div>
            </div>
            <div> 
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right">
                    <h4 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Solutions</h4>
                    <p>We provide end-to-end eminent healthcare solutions; to build innovative hospitals integrating processes, people and equipment to help you gain new efficiencies and boost treatment to result in higher patient satisfaction.
                    </p>
                </div>
           </div>
        </div>
    </div>
</div>
 
    
<div class="uk-section uk-section-muted uk-padding-remove-top">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left"> 
                    <h4 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Outpatient management</h4>
                    <p>The solution that streamline the management of outpatients smoothly. Our convenient healthcare management solution provides an efficient module for outpatients that acts as the very first point of communication to schedule an appointment with the relevant doctors and ensure trouble-free consulting for patients.
                    </p>
                </div>
            </div>
            <div>
                <div class="uk-text-left"> 
                    <h4 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Patient portal</h4>
                    <p>Patient portals that allow access to the information, records of each check-up, including patient history, treatments and medications.
                    </p>
                </div>
            </div>
            <div>
                <div class="uk-text-left"> 
                    <h4 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Scheduling</h4>
                    <p>Solutions allowing patients to login to the portal, book their appointments according to the availability of physician, view their previous treatment, receive notifications prior to the appointment.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="uk-section uk-section-muted uk-padding-remove-top uk-padding-remove-bottom uk-visible@m">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-center" uk-grid>
            <div>
                <div class="uk-height-large uk-background-cover uk-light uk-flex" uk-parallax="bgy: -200" style="background-image: url('../../assets/images/healthcare_management_4.jpg');">
                </div>
            </div>
            <div>
                <div class="uk-height-large uk-background-cover uk-light uk-flex" uk-parallax="bgy: -200" style="background-image: url('../../assets/images/healthcare_management_5.jpg');">
                </div>
            </div>
        </div>
    </div>
</div>


<div class="uk-section uk-section-muted uk-padding-remove-top uk-padding-remove-bottom  uk-hidden@m">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-center" uk-grid>
            <div>
                <img src="../../assets/images/healthcare_management_4_mob.jpg">
            </div>
            <div>
                <img src="../../assets/images/healthcare_management_5_mob.jpg">
            </div>
        </div>
    </div>
</div>



<div class="uk-section uk-section-muted">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left"> 
                    <h4 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Medical billing</h4>
                    <p>Our solution is designed to offer customized modules that incorporate billing receipts. It includes billing of outpatient, inpatient, laboratory expenses, radiology etc are incorporated to the billing module. Additional features include
                    </p>
                    <ul class="uk-list">
                        <li>Compliance and billing</li> 
                        <li>Automated invoice generation</li> 
                        <li>Automated reminders to patients etc.</li> 
                   </ul> 
                </div>
            </div>
            <div>
                <div class="uk-text-left"> 
                    <h4 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">E-prescribing</h4>
                    <p>This solution provides the provision for its patients to send prescriptions to pharmacies. It avoids the unnecessary delay for getting the medicines.
                    </p>
                </div>
            </div>
            <div>
                <div class="uk-text-left"> 
                    <h4 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Enterprise content management</h4>
                    <p>Our solution allows you to improve your operational efficiency by providing you a platform to permeate data and related documents across versatile departments in the health industry to provide you better results and coordination.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="uk-section uk-section-muted uk-padding-remove-top uk-visible@m">
    <div class="uk-container uk-container-large">
        <div class="uk-height-large uk-background-cover uk-light uk-flex" uk-parallax="bgy: -200" style="background-image: url('../../assets/images/healthcare_management_6.jpg');"> 
        </div> 
    </div>
</div> 

<div class="uk-section uk-section-muted uk-padding uk-padding-remove-top uk-padding-remove-left uk-padding-remove-right uk-hidden@m">
    <div class="uk-container uk-container-large">
        <div><img src="../../assets/images/healthcare_management_6_mob.jpg"></div>
        <!-- uk-visible@m -->
    </div>
</div>

<div class="uk-section uk-section-muted uk-padding-remove-top">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left">
                    <h5 class="qt-color-dark qt-font-bold uk-text-uppercase">Unified system
                    </h5>
                    <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Why choose us</h2>
                </div>
            </div>
            <div> 
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right">
                    <h4 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">One handy solution</h4>
                    <p>Health management solution that allows you to manage, record and schedule the health care activities of an organization in one.
                    </p>
                    <h4 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Improves overall service</h4>
                    <p>It offers a wide range of features that accelerates the improvement of varied medical departments within a healthcare organization. It facilitates an easy functioning of different departments under a unified system.
                    </p>
                    <h4 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Patient personalized solutions</h4>
                    <p>Our solution allows patients to view their previous medical reports, make appointments and provide them personalized and user-friendly solutions on a web portal.
                    </p>
                </div>
           </div>
        </div>
    </div>
</div>
