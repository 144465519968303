import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { MetaService } from '../meta.service';
@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {
  title = 'Our Products | Best IT Company in Kochi';
  constructor(
    private router: ActivatedRoute,
    private titleService: Title, 
    private metaService: MetaService,
    private meta: Meta
    ) {
    
   }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.meta.updateTag(
      { name: 'description', content: "We build software products to address complex business problems. Some of our products are school bus tracking app, shuttle service app and student e-wallet etc." }
    );
    this.metaService.createCanonicalURL();
       
  }
  ngDoCheck() { 
    //console.log(this.router.snapshot.queryParamMap.get('page')) 
  if(this.router.snapshot.queryParamMap.get('page')) 
    document.getElementById(this.router.snapshot.queryParamMap.get('page')).scrollIntoView(true);
  }
}
