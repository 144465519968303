import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { MetaService } from '../meta.service';
@Component({
  selector: 'app-valet',
  templateUrl: './valet.component.html',
  styleUrls: ['./valet.component.scss']
})
export class ValetComponent implements OnInit {
  title = 'Simplify Your Parking Experience with Our Valet Parking App';
  constructor(
    private titleService: Title, 
    private metaService: MetaService,
    private meta: Meta
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.meta.updateTag(
      { name: 'description', content: "Make parking effortless with our valet parking software. Our user-friendly interface lets you reserve valet service, track your vehicle, & pay securely, all from your smartphone. " }
    );
    this.metaService.createCanonicalURL();
  }

}

 