import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { MetaService } from '../meta.service';

@Component({
  selector: 'app-budget-management-solutions',
  templateUrl: './budget-management-solutions.component.html',
  styleUrls: ['./budget-management-solutions.component.scss']
})
export class BudgetManagementSolutionsComponent implements OnInit {
  title = 'Effective Money Management Software Solutions | Qaptive';
  constructor(
    private titleService: Title, 
    private metaService: MetaService,
    private meta: Meta
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.meta.updateTag(
      { name: 'description', content: "Streamline your finances and take control of your finances with Qaptive's budget & money management software solutions in India." }
    );
    this.metaService.createCanonicalURL();
  }

}
