import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { MetaService } from '../meta.service';
@Component({
  selector: 'app-creative-design-services',
  templateUrl: './creative-design-services.component.html',
  styleUrls: ['./creative-design-services.component.scss']
})
export class CreativeDesignServicesComponent implements OnInit {
  title = 'Creative Web designing Services & Web Development Solutions in India | Qaptive ';

  constructor(
    private titleService: Title, 
    private metaService: MetaService,
    private meta: Meta
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.meta.updateTag(
      { name: 'description', content: "Transform your digital presence with custom creative web design solutions from the best web development company in India. We specialize in web designing, graphic design, and more." }
    );
    this.metaService.createCanonicalURL();
  }

}
