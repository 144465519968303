<div class="uk-position-relative uk-visible-toggle" tabindex="-1" uk-slideshow="ratio: 8:2">
    <ul class="uk-slideshow-items">
        <li>
            <img src="../../assets/images/software_service_1.jpg" alt="" uk-cover>
            <div class="uk-position-bottom uk-position-medium uk-text-left uk-light">
                <div class="uk-container uk-container-large">
                    <h1 class="uk-margin-remove qt-font-bold">Software Services</h1>
                    <p class="uk-margin-remove qt-color-white uk-visible@m">Reassure, reinvent, rationalize software services by <br>utilizing strategy, design and technology
                    </p>
                </div>
            </div>
        </li>
    </ul>
    <div class="uk-light">
        <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slideshow-item="previous"></a>
        <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slideshow-item="next"></a>
    </div>
</div>

<div class="uk-section uk-section-muted">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left">
                    <h5 class="qt-color-dark qt-font-bold uk-text-uppercase">Reinventing your business
                    </h5>
                    <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Restructure for Global Adaptability</h2>
                </div>
            </div>
            <div>
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right"> 
                    <p>Reassure, reinvent, rationalize software services by utilizing strategy, design, and technology. Software services using next-generation technologies for reinventing your business. We restructure for global adaptability by leveraging continuous software services powered by mobile, web, automation, and cloud. We help in disruptive growth in business, transform the channel strategies, and create competitive agility.    

                        
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="uk-section uk-section-muted uk-padding-remove-top uk-padding-remove-bottom uk-visible@m">
    <div class="uk-container uk-container-large">
        <div class="uk-height-large uk-background-cover uk-light uk-flex" uk-parallax="bgy: -200" style="background-image: url('../../assets/images/software_service_2.jpg');"> 
        </div> 
    </div>
</div>
<div class="uk-section uk-section-muted uk-padding uk-padding-remove-top uk-padding-remove-left uk-padding-remove-right uk-hidden@m">
    <div class="uk-container uk-container-large">
        <div><img src="../../assets/images/software_service_2_mob.jpg"></div>
        <!-- uk-visible@m -->
    </div>
</div>

<div class="uk-section uk-section-muted">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left">
                    <h5 class="qt-color-dark qt-font-bold uk-text-uppercase"> Continual enterprise transformation
                    </h5>
                    <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom ">Iterative, Intuitive and Incremental
                    </h2>
                </div>
            </div>
            <div>
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right"> 
                    <p> There's no denying that the process of building software is complex and includes steps such as design, development, testing, and deploying that require expertise. That’s why business organizations intending to develop software solutions, approach <a href="https://www.qaptive.co.in/ ">software development agencies</a> who are specialized in the whole process. We assure software services focused on superior user experiences through iterative, incremental activations and deliver continual enterprise transformation, at speed and at scale.

                    </p>
                </div> 
            </div>
        </div>
    </div>
</div>

<div class="uk-section uk-section-muted uk-padding-remove-top uk-padding-remove-bottom uk-visible@m">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-center" uk-grid>
            <div>
                <div class="uk-height-large uk-background-cover uk-light uk-flex" uk-parallax="bgy: -200" style="background-image: url('../../assets/images/software_service_3.jpg');">
                </div>
            </div>
            <div>
                <div class="uk-height-large uk-background-cover uk-light uk-flex" uk-parallax="bgy: -200" style="background-image: url('../../assets/images/software_service_4.jpg');">
                </div>
            </div>
        </div>
    </div>
</div>
<div class="uk-section uk-section-muted uk-padding-remove-top uk-padding-remove-bottom uk-hidden@m">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-center" uk-grid>
            <div>
                <img src="../../assets/images/software_service_3_mob.jpg">
            </div>
            <div>
                <img src="../../assets/images/software_service_4_mob.jpg">
            </div>
        </div>
    </div>
</div>


<div class="uk-section uk-section-muted">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left">
                    <h5 class="qt-color-dark qt-font-bold uk-text-uppercase"> Digital presence enablement</h5>
                    <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Web Development</h2>
                </div>
            </div>
            <div>
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right"> 
                    <p>
                        Building digital products whether it is an elementary website or a complex web application coupled with dozens of services in a sophisticated architecture is one other competency we excel in. We use an innovative technology stack and lightweight framework to build web applications that are portable and interoperable. Whether you are a startup or a fortune 100 enterprise, you can trust us with your requirement.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="uk-section uk-section-muted uk-padding-remove-top">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left"> 
                    <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Mobile Development</h2>
                    <p>Since businesses worldwide opt for “Mobile First” strategy, we continuously invest and invent the optimized mobile development strategies whether it is native or hybrid. We build, deliver, and maintain state of art mobile applications that primarily focus on the usability of the must-have features.


                    </p>
                </div>
            </div>
            <div>
                <div class="uk-text-left"> 
                    <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Cloud/Server Management</h2>
                    <p>
                        Whether it is a hosting environment for a website or an infrastructure to host data warehouses or highspeed computing, we are your one-stop provider. Talk to us to understand what cloud provisioning best suits for you.

                    </p> 
                </div>
            </div>
        </div>
    </div>
</div>

 



<div class="uk-section uk-section-muted uk-padding-remove-top uk-visible@m">
    <div class="uk-container uk-container-large">
        <div class="uk-height-large uk-background-cover uk-light uk-flex" uk-parallax="bgy: -200" style="background-image: url('../../assets/images/software_service_5.jpg');"> 
        </div> 
    </div>
</div>


<div class="uk-section uk-section-muted uk-padding uk-padding-remove-top uk-padding-remove-left uk-padding-remove-right uk-hidden@m">
    <div class="uk-container uk-container-large">
        <div><img src="../../assets/images/software_service_5_mob.jpg"></div>
        <!-- uk-visible@m -->
    </div>
</div>



<div class="uk-section uk-section-muted uk-padding-remove-top">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left">
                    <h5 class="qt-color-dark qt-font-bold uk-text-uppercase"> Keeping applications up And running
                    </h5>
                    <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Support services to ensure critical systems are up and running 24 × 7 
 </h2>
                </div>
            </div>
            <div>
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right"> 
                    <p>We create dedicated, detail-oriented teams that can do everything from performing bug fixes and minor enhancements to supporting large implementations of enterprise software. Whether it is Tier-1, Tier-2 or Tier-3 support, we make sure that your customers are served within SLA and the availability of the applications with the full functionality is never compromised.

                    </p>
                </div>
            </div>
        </div>
    </div>
</div>




<div class="uk-section uk-section-muted uk-padding-remove-top  uk-padding-remove-bottom uk-visible@m">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-center" uk-grid>
            <div>
                <div class="uk-height-large uk-background-cover uk-light uk-flex" uk-parallax="bgy: -200" style="background-image: url('../../assets/images/software_service_6.jpg');">
                </div>
            </div>
            <div>
                <div class="uk-height-large uk-background-cover uk-light uk-flex" uk-parallax="bgy: -200" style="background-image: url('../../assets/images/software_service_7.jpg');">
                </div>
            </div>
        </div>
    </div>
</div>
<div class="uk-section uk-section-muted uk-padding-remove-top  uk-padding-remove-bottom uk-hidden@m">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-center" uk-grid>
            <div>
                <img src="../../assets/images/software_service_6_mob.jpg">
            </div>
            <div>
                <img src="../../assets/images/software_service_7_mob.jpg">
            </div>
        </div>
    </div>
</div>




<div class="uk-section uk-section-muted ">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left"> 
                    <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Delivering Business Intelligence Tools</h2>
                    <p>With expertise in building solutions focus on business intelligence, we provide specialized software for customers to unlock insights and address acquisition concerns. We build solutions for data analytics and reporting by slicing and dicing the unorganized data from the data warehouses.


                </div>
            </div>
            <div>
                <div class="uk-text-left"> 
                    <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">IT Consulting</h2>
                    <p>
                        We provide our clients with advice for managing their software systems and architectures, building custom solutions, and driving innovations. Our primary goal of consultancy services is to take the business to the next level by optimizing analytics, processes, and workflows with specialized software solutions.
                        <br>
                        <br>
                        We also focus in business reengineering and transformation to rationalize the software applications which are not exactly helping business.

                    </p> 
                </div>
            </div>
        </div>
    </div>
</div>




<div class="uk-section uk-section-muted uk-padding-remove-top uk-visible@m">
    <div class="uk-container uk-container-large">
        <div class="uk-height-large uk-background-cover uk-light uk-flex" uk-parallax="bgy: -200" style="background-image: url('../../assets/images/software_service_8.jpg');"> 
        </div> 
    </div>
</div>
<div class="uk-section uk-section-muted uk-padding uk-padding-remove-top uk-padding-remove-left uk-padding-remove-right uk-hidden@m">
    <div class="uk-container uk-container-large">
        <div><img src="../../assets/images/software_service_8_mob.jpg"></div>
        <!-- uk-visible@m -->
    </div>
</div>


  