import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Qaptive';
  constructor(private router: Router) {}
  ngOnInit() {
    // this.router.events.subscribe((evt) => {
    //     if (!(evt instanceof NavigationEnd)) {
    //         return;
    //     }

    //     var scrollToTop = window.setInterval(function () {
    //         var pos = window.pageYOffset;
    //         if (pos > 0) {
    //             window.scrollTo(0, pos - 20); // how far to scroll on each step
    //         } else {
    //             window.clearInterval(scrollToTop);
    //         }
    //     }, 2); // how fast to scroll (this equals roughly 60 fps)
    // });
}
onActivate(event) {
    window.scroll(0,0);
    //or document.body.scrollTop = 0;
    //or document.querySelector('body').scrollTo(0,0)
  
}
}
