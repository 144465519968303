import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { AboutComponent } from './about/about.component';
import { ClinicalmanagementComponent } from './clinicalmanagement/clinicalmanagement.component';
import { SoftwareProductDevelopmentComponent } from './software-product-development/software-product-development.component';
import { SoftwareServicesComponent } from './software-services/software-services.component';
import { CreativeDesignComponent } from './creative-design/creative-design.component';
import { DigitalMarketingComponent } from './digital-marketing/digital-marketing.component';
import { WebDesignComponent } from './web-design/web-design.component';
import { SeoComponent } from './seo/seo.component';
import { CloudManagementComponent } from './cloud-management/cloud-management.component';
import { ItConsultingComponent } from './it-consulting/it-consulting.component';
import { CreativeDesignServicesComponent } from './creative-design-services/creative-design-services.component';
import { HealthcareManagementSolutionsComponent } from './healthcare-management-solutions/healthcare-management-solutions.component';
import { EducationManagementSolutionsComponent } from './education-management-solutions/education-management-solutions.component';
import { FleetManagementSolutionsComponent } from './fleet-management-solutions/fleet-management-solutions.component';
import { HospitalityManagementSolutionsComponent } from './hospitality-management-solutions/hospitality-management-solutions.component';
import { BudgetManagementSolutionsComponent } from './budget-management-solutions/budget-management-solutions.component';
import { LoqqatCaseStudyComponent } from './loqqat-case-study/loqqat-case-study.component';
import { PeekayCaseStudyComponent } from './peekay-case-study/peekay-case-study.component';
import { LoqqatRideCaseStudyComponent } from './loqqat-ride-case-study/loqqat-ride-case-study.component';
import { TownbridgeCaseStudyComponent } from './townbridge-case-study/townbridge-case-study.component';
import { VideoAnimationsComponent } from './video-animations/video-animations.component';
import { CareersComponent } from './careers/careers.component';
import { ProductsComponent } from './products/products.component';
import { ContactComponent } from './contact/contact.component';
import { WhyQaptiveComponent } from './why-qaptive/why-qaptive.component';


import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatSnackBarModule} from '@angular/material/snack-bar';

import { HttpClientModule } from '@angular/common/http';

import {LocationStrategy, HashLocationStrategy, PathLocationStrategy} from '@angular/common';
import { LoqqatForceComponent } from './loqqat-force/loqqat-force.component';
import { MealsComponent } from './meals/meals.component';
import { CanteenPrivacyComponent } from './canteen-privacy/canteen-privacy.component';
import { ValetComponent } from './valet/valet.component';
import { QcmComponent } from './qcm/qcm.component';
import { QoneComponent } from './qone/qone.component';
import { WayfindingComponent } from './wayfinding/wayfinding.component';
import { GpsComponent } from './gps/gps.component';
import { QwalletComponent } from './qwallet/qwallet.component';
import { QuickAttendenceComponent } from './quick-attendence/quick-attendence.component';
import { ECommerceComponent } from './e-commerce/e-commerce.component';
import { QuikLaundryComponent } from './quik-laundry/quik-laundry.component';
import { CareerFormComponent } from './career-form/career-form.component';
import { ProfileComponent } from './profile/profile.component';
import { ContactformComponent } from './contactform/contactform.component';
import { QueeComponent } from './quee/quee.component';
import { QdineComponent } from './qdine/qdine.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    AboutComponent,
    ClinicalmanagementComponent,
    SoftwareProductDevelopmentComponent,
    SoftwareServicesComponent,
    CreativeDesignComponent,
    DigitalMarketingComponent,
    WebDesignComponent,
    SeoComponent,
    CloudManagementComponent,
    ItConsultingComponent,
    CreativeDesignServicesComponent,
    HealthcareManagementSolutionsComponent,
    EducationManagementSolutionsComponent,
    FleetManagementSolutionsComponent,
    HospitalityManagementSolutionsComponent,
    BudgetManagementSolutionsComponent,
    LoqqatCaseStudyComponent,
    PeekayCaseStudyComponent,
    LoqqatRideCaseStudyComponent,
    TownbridgeCaseStudyComponent,
    VideoAnimationsComponent,
    CareersComponent,
    ProductsComponent,
    ContactComponent,
    WhyQaptiveComponent,
    LoqqatForceComponent,
    MealsComponent,
    CanteenPrivacyComponent,
    ValetComponent,
    QcmComponent,
    QoneComponent,
    WayfindingComponent,
    GpsComponent,
    QwalletComponent,
    QuickAttendenceComponent,
    ECommerceComponent,
    QuikLaundryComponent,
    CareerFormComponent,
    ProfileComponent,
    ContactformComponent,
    QueeComponent,
    QdineComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    HttpClientModule,
    MatSnackBarModule,
  ],
  providers: [
    {provide: LocationStrategy, useClass: PathLocationStrategy}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
