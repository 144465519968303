<div class="uk-position-relative uk-visible-toggle" tabindex="-1" uk-slideshow="ratio: 8:2">
    <ul class="uk-slideshow-items">
        <li>
            <img src="../../assets/images/budject_managment_1.jpg" alt="Budget Management Solutions" uk-cover>
            <div class="uk-position-bottom uk-position-medium uk-text-left uk-light">
                <div class="uk-container uk-container-large">
                    <h1 class="uk-margin-remove qt-font-bold">Budget Management Solutions</h1>
                    <p class="uk-margin-remove qt-color-white uk-visible@m">Evaluate and budget</p>
                </div>
            </div>
        </li>
    </ul>
    <div class="uk-light">
        <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slideshow-item="previous"></a>
        <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slideshow-item="next"></a>
    </div>
</div>

<div class="uk-section uk-section-muted">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left">
                    <h5 class="qt-color-dark qt-font-bold uk-text-uppercase">Design the budget</h5>
                    <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Plan into action</h2>
                                            <p>Our budget management solutions provide an online visual range to real and authentic presentation in anticipation of preserved budgets. These systems are concise to the point, precise and on target for management evaluation and customizing when required by the clientele.
                        <br>
                        <br>
                        It helps to bring down data integrity issues and improves the overall budget. These management solutions make an approximation of profits and expenditure for an upcoming period based on their objective for the business.
                    </p>
                </div>
            </div>
            <div> 
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right">

                    <ul class="uk-list">
                        
                        <h4 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">How do we help to upgrade the budgeting skills</h4>
                        <li>Trace the income</li> 
                        <li>Trace expenses</li> 
                        <li>Evaluate and budget</li> 
                        <li>Conserve and progress</li> 
                        <li>Organize and maintain</li> 
                        <h4 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom uk-padding uk-padding-remove-left uk-padding-remove-right uk-padding-remove-bottom">Different phases of budgeting</h4>
                        <li>The setting of goals and objectives</li> 
                        <li>Separate needs and wants</li> 
                        <li>Design the budget</li> 
                        <li>Plan into action</li> 
                        <li>Review after a specific time interval</li> 
                        <li>Sustain the budget</li> 
                   </ul> 
                </div>
           </div>
        </div>
    </div>
</div>

<div class="uk-section uk-section-muted uk-padding-remove-top  uk-visible@m">
    <div class="uk-container uk-container-large uk-visible@m">
        <div class="uk-height-large uk-background-cover uk-light uk-flex " uk-parallax="bgy: -200" style="background-image: url('../../assets/images/budject_managment_2.jpg');"> 
        </div> 
    </div>
    
</div>

<div class="uk-section uk-section-muted uk-padding-remove-top ">
    <div class="uk-container uk-container-large uk-hidden@m">
        <div><img src="../../assets/images/budject_managment_1_mob.jpg"></div>
    </div>
</div>
  