<div class="uk-position-relative uk-visible-toggle" tabindex="-1" uk-slideshow="ratio: 8:2">
    <ul class="uk-slideshow-items">
        <li>
            <img src="../../assets/images/townbridge_case_study_1.jpg" alt="Townbridge" uk-cover>
            <div class="uk-position-bottom uk-position-medium uk-text-left uk-light">
                <div class="uk-container uk-container-large">
                    <h1 class="uk-margin-remove qt-font-bold">Web Solution for Hospitality Sector</h1>
                    <p class="uk-margin-remove qt-color-white uk-visible@m">Hospitality</p>
                </div>
            </div>
        </li>
    </ul>
    <div class="uk-light">
        <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slideshow-item="previous"></a>
        <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slideshow-item="next"></a>
    </div>
</div>

<div class="uk-section uk-section-muted">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left">
                    <h5 class="qt-color-dark qt-font-bold uk-text-uppercase">A Strategy for the overall improvement of the services</h5>
                    <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Business Problem</h2>
                </div>
            </div>
            <div> 
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right">
                    <p>  
                        A computer software house employing robust IT services to develop, manage, and upgrade business models in the hospitality industry. To produce a strategy for the overall improvement of the services. Hospitality industry to meet their business requirements and goals with intuitive, robust, and cost-effective IT solutions. Our client wanted to adopt better technology for improved business operations, revenue, and reputation. A solution to overcome the delayed flow of data & information that makes daily operations very tedious for hotel staff. A system to satisfy the single point reporting of all properties, centralized monitoring.

                    </p>
                    <h4 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Scenario and Challenges</h4>
                    <ul class="uk-list">
                        <li>Absence of real-time integration</li>   
                        <li>Pulling out reports for each property was time-consuming and difficult</li>   
                        <li>Absence of a catering management solution</li>   
                        <li>Absence of laundry management solution</li>   
                        <li>Difficulty in bookings and issues of the sold-out date that leading to overall revenue loss</li>   
                        <li>Delay in decision making and instant monitoring</li>   
                   </ul>
                </div>
           </div>
        </div>
    </div>
</div>

<div class="uk-section uk-section-muted uk-padding-remove-top uk-visible@m">
    <div class="uk-container uk-container-large">
        <div class="uk-height-large uk-background-cover uk-light uk-flex" uk-parallax="bgy: -200" style="background-image: url('../../assets/images/townbridge_case_study_2.jpg');"> 
        </div> 
    </div>
</div>
<div class="uk-section uk-section-muted uk-padding uk-padding-remove-top uk-padding-remove-left uk-padding-remove-right uk-hidden@m">
    <div class="uk-container uk-container-large">
        <div><img src="../../assets/images/townbridge_case_study_2_mob.jpg"></div>
        <!-- uk-visible@m -->
    </div>
</div>
<div class="uk-section uk-section-muted uk-padding-remove-top">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left">
                    <h5 class="qt-color-dark qt-font-bold uk-text-uppercase">The intuitive, robust, and cost-effective solution in the hospitality industry.</h5>
                    <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Solution</h2>
                </div>
            </div>
            <div> 
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right">
                    <ul class="uk-list">
                        <li>The transition from on-premise to the cloud-based system</li>  
                        <li>Increased online reputation</li> 
                        <li>Catering management solution introduced.</li> 
                        <li>Laundry management solution introduced.</li> 
                        <li>Inventory management solution.</li> 
                        <li>Customer relationship management.</li> 
                        <li>Campaigns, loyalty programs, discounts, surveys.</li> 
                   </ul> 
                </div>
                
           </div>
        </div>
    </div>
</div> 
 

<div class="uk-section uk-section-muted uk-padding-remove-top uk-padding-remove-bottom uk-visible@m">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-center" uk-grid>
            <div>
                <div class="uk-height-large uk-background-cover uk-light uk-flex" uk-parallax="bgy: -200" style="background-image: url('../../assets/images/townbridge_case_study_3.jpg');">
                </div>
            </div>
            <div>
                <div class="uk-height-large uk-background-cover uk-light uk-flex" uk-parallax="bgy: -200" style="background-image: url('../../assets/images/townbridge_case_study_4.jpg');">
                </div>
            </div>
        </div>
    </div>
</div> 
<div class="uk-section uk-section-muted uk-padding-remove-top uk-padding-remove-bottom uk-hidden@m">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-center" uk-grid>
            <div>
                <img src="../../assets/images/townbridge_case_study_3_mob.jpg">
            </div>
            <div>
                <img src="../../assets/images/townbridge_case_study_4_mob.jpg">
            </div>
        </div>
    </div>
</div> 



<div class="uk-section uk-section-muted">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left">
                    <h5 class="qt-color-dark qt-font-bold uk-text-uppercase">Business expansion of enterprises</h5>
                    <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Outcomes</h2>
                </div>
            </div>
            <div> 
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right">
                     <ul class="uk-list">
                        <li>It resulted in the business expansion of enterprises</li>   
                        <li>Effective risk management</li>   
                        <li>Helped to meet their business requirements</li>   
                        <li>A cloud-based <a href="https://www.qaptive.co.in/hospitality-management-solutions ">hospitality management solution</a> has enabled the business to scale rapidly.</li>   
                        <li>Helped with revenue uplift of 10% and savings due to improved operational efficiency.</li>   
                        <li>Experiencing the overall improvement in the running of the enterprise.</li>   
                        <li>The monitoring of many analytical reports had been easier and efficient.</li>  
                   </ul>
                </div>
           </div>
        </div>
    </div>
</div> 
  

<div class="uk-section uk-section-muted uk-padding-remove-top uk-visible@m">
    <div class="uk-container uk-container-large">
        <div class="uk-height-large uk-background-cover uk-light uk-flex" uk-parallax="bgy: -200" style="background-image: url('../../assets/images/townbridge_case_study_5.jpg');"> 
        </div> 
    </div>
</div> 

<div class="uk-section uk-section-muted uk-padding uk-padding-remove-top uk-padding-remove-left uk-padding-remove-right uk-hidden@m">
    <div class="uk-container uk-container-large">
        <div><img src="../../assets/images/townbridge_case_study_5_mob.jpg"></div>
        <!-- uk-visible@m -->
    </div>
</div>

<div class="uk-section uk-section-muted uk-padding-remove-top">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left">
                    <h5 class="qt-color-dark qt-font-bold uk-text-uppercase">Town Bridge Hotels</h5>
                    <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">About the Client</h2>
                </div>
            </div>
            <div> 
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right"> 
                    <p> Comprising two 4-star hotels, one in Kochi and the other one in Wayanad, Town Bridge Hotels are the perfect Business Class Hotels, centrally located connecting to all major attractions and popular tourist spots. Town Bridge Hotels are the hallmark of the pursuit of excellence in hospitality. 
                    <br>
                    <br>
                    For business or leisure, indulge in true comfort in one of our classy rooms and suites, dine in our restaurants that offer a plethora of dining choices, and experience the perfect getaway exclusively offered by Town Bridge.
                    </p>
                </div>
           </div>
        </div>
    </div>
</div>
