 
import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { MetaService } from '../meta.service';

@Component({
  selector: 'app-qcm',
  templateUrl: './qcm.component.html',
  styleUrls: ['./qcm.component.scss']
})
export class QcmComponent implements OnInit {
  title = 'Efficient HR & Attendance Management Software | QCM';
  constructor(
    private titleService: Title, 
    private metaService: MetaService,
    private meta: Meta
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.meta.updateTag(
      { name: 'description', content: "Optimize workforce efficiency with our HR software and Attendance Management Software. A top-tier attendance tracking solution for enhanced HR management." }
    );
    this.metaService.createCanonicalURL();
  }

}
