import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { MetaService } from '../meta.service';
@Component({
  selector: 'app-it-consulting',
  templateUrl: './it-consulting.component.html',
  styleUrls: ['./it-consulting.component.scss']
})
export class ItConsultingComponent implements OnInit {
  title = 'Digital Transformation IT Consulting in India | Qaptive';
  constructor(
    private titleService: Title, 
    private metaService: MetaService,
    private meta: Meta
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.meta.updateTag(
      { name: 'description', content: "Maximize business growth with IT and business consulting services from Qaptive. We specialize in digital transformation in India." }
    );
    this.metaService.createCanonicalURL();
  }

}
