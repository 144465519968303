import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-canteen-privacy',
  templateUrl: './canteen-privacy.component.html',
  styleUrls: ['./canteen-privacy.component.scss']
})
export class CanteenPrivacyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
