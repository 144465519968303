<nav class="uk-navbar-container pk-nav" uk-navbar uk-sticky="sel-target: .uk-navbar-container; cls-active: uk-navbar-sticky">
    <div class="uk-navbar-left">
        <a class="uk-navbar-item uk-logo" href="#"><img src="../../assets/images/logo_qaptive.png"></a>
    </div>
    <div class="uk-navbar-right uk-hidden@m">
        <button class="uk-button uk-button-default uk-padding-remove qt-menu" type="button" uk-toggle="target: #offcanvas-flip"><span uk-icon="icon: menu"></span>
        </button>

    </div>
    <div class="uk-navbar-right uk-visible@m">
        <ul class="uk-navbar-nav"> 
            <li><a [routerLink]="['about']">About Us</a>
                <div class="uk-navbar-dropdown">
                    <ul class="uk-nav uk-navbar-dropdown-nav">
                    <li><a [routerLink]="['about']">More on us</a></li>
                    <li><a [routerLink]="['careers']">Careers</a></li>
                </ul>
                </div>
            </li>
            <li>
                <a href="">Core Services</a>
                <div class="uk-navbar-dropdown">
                    <ul class="uk-nav uk-navbar-dropdown-nav">
                    <li><a [routerLink]="['software-product-development']">Software Product Development
                    </a></li>
                    <li><a [routerLink]="['software-services']">Software Services</a></li>
                    <li><a [routerLink]="['it-consulting']">IT Consulting</a></li>
                    <li><a [routerLink]="['creative-design-services']">Creative Design</a></li>
                    <li><a [routerLink]="['e-commerce']">Ecommerce</a></li>
                </ul>
                </div>
            </li>
            <li>
                <a href="">Solutions</a>
                <div class="uk-navbar-dropdown">
                    <ul class="uk-nav uk-navbar-dropdown-nav">
                        <li><a [routerLink]="['healthcare-management-solutions']">Healthcare Management 
                        </a></li>
                        <li><a [routerLink]="['education-management-solutions']">Education systems 
                        </a></li>
                        <li><a [routerLink]="['fleet-management-solutions']">Fleet Management</a></li>
                        <li><a [routerLink]="['hospitality-management-solutions']">Hospitality solutions
 
                        </a></li>
                        <li><a [routerLink]="['budget-management-solutions']">Budget Management
                        </a></li>
                        
                    </ul>
                </div>
            </li>
            <li>
                <a href="">Case Studies</a>
                <div class="uk-navbar-dropdown">
                    <ul class="uk-nav uk-navbar-dropdown-nav">
                        <li><a [routerLink]="['loqqat-case-study']">School Bus Tracking & Student Ridership
                        </a></li>
                        <li><a [routerLink]="['peekay-case-study']">Healthcare management solution

                        </a></li>
                        <li><a [routerLink]="['loqqat-ride-case-study']">Employee Ridership Solution</a></li>
                        <li><a [routerLink]="['townbridge-case-study']">Web Solution for the hospitality sector
                        </a></li>
                                                                        
                    </ul>
                </div>
            </li>
            <!-- <li><a [routerLink]="['why-qaptive']">Why Qaptive</a></li> -->
            <!-- <li>
                <a href="#">Product</a>
                <div class="uk-navbar-dropdown">
                    <ul class="uk-nav uk-navbar-dropdown-nav"> 
                        <li><a [routerLink]="['software-product-development']">Software Product Development</a></li> 
                    </ul>
                </div>
            </li> -->
            <li>
                <a href="">Services</a>
                <div class="uk-navbar-dropdown uk-navbar-dropdown-width-1">
                    <div class="uk-navbar-dropdown-grid uk-child-width-1-1" uk-grid>
                        <div>
                            <ul class="uk-nav uk-navbar-dropdown-nav">  
                                <!-- <li class="uk-nav-header uk-text-capitalize qt-font-bold uk-margin-remove-top"><a class="qt-color-black" [routerLink]="['software-services']">Software Services</a></li>
                                <li class="uk-nav-divider"></li>
                                <li class="uk-nav-header uk-text-capitalize qt-font-bold uk-margin-remove-top"><span class="qt-color-black">Service Segments</span></li>
                                <li class="uk-nav-divider"></li> -->
                                <li><a [routerLink]="['creative-design']"><span uk-icon="arrow-right"></span>Creative Design</a></li>
                                <li><a [routerLink]="['digital-marketing']"><span uk-icon="arrow-right"></span>Digital Marketing</a></li> 
                                <li><a [routerLink]="['web-design']"><span uk-icon="arrow-right"></span>Web Design </a></li>
                                <li><a [routerLink]="['seo']"><span uk-icon="arrow-right"></span>SEO </a></li>
                                <li><a [routerLink]="['video-animations']"><span uk-icon="arrow-right"></span>Video Animations </a></li>
                            </ul>
                        </div>
                        
                    </div>
                </div>
            </li>
            <li><a [routerLink]="['products']">Products</a>
                <div class="uk-navbar-dropdown uk-navbar-dropdown-width-1 widthdropdown">
                    <div class="uk-navbar-dropdown-grid uk-child-width-1-2" uk-grid>
                        <div>
                            <ul class="uk-nav uk-navbar-dropdown-nav">
                                <li><a (click)="goToPage('quickatt')"><span uk-icon="arrow-right"></span>Quick Attendance</a></li>                                 
                                <li><a (click)="goToPage('loqqat')"><span uk-icon="arrow-right"></span>Loqqat</a></li>
                                <li><a (click)="goToPage('loqqatride')"><span uk-icon="arrow-right"></span>Loqqat Ride</a></li>
                                <li><a (click)="goToPage('lforce')"><span uk-icon="arrow-right"></span>Loqqat Force</a></li> 
                                <li><a (click)="goToPage('ecommerce')"><span uk-icon="arrow-right"></span>Ecommerce</a></li>
                                <li><a (click)="goToPage('quik')"><span uk-icon="arrow-right"></span>Quik Laundry</a></li>
                                <li><a (click)="goToPage('qanteen')"><span uk-icon="arrow-right"></span>Qanteen</a></li>
                                
                            </ul>
                        </div>
                        <div>
                            <ul class="uk-nav uk-navbar-dropdown-nav">                                 
                              
                                <li><a (click)="goToPage('qwallet')"><span uk-icon="arrow-right"></span>Qafe</a></li>
                                <li><a (click)="goToPage('reido')"><span uk-icon="arrow-right"></span>Reido</a></li>
                                <li><a (click)="goToPage('way')"><span uk-icon="arrow-right"></span>Way Finding</a></li>
                                <li><a (click)="goToPage('qone')"><span uk-icon="arrow-right"></span>Q-one</a></li>
                                <li><a (click)="goToPage('qcm')"><span uk-icon="arrow-right"></span>QCM</a></li>
                                <li><a (click)="goToPage('valet')"><span uk-icon="arrow-right"></span>Valet Parking</a></li>
                                <li><a (click)="goToPage('quee')"><span uk-icon="arrow-right"></span>Quee</a></li>
                            </ul>
                        </div>
                        
                    </div>
                </div>
            </li> 
            <!-- <li><a [routerLink]="['products']">Products</a>
                <div class="uk-navbar-dropdown uk-navbar-dropdown-width-1">
                    <div class="uk-navbar-dropdown-grid uk-child-width-1-1" uk-grid>
                        <div>
                            <ul class="uk-nav uk-navbar-dropdown-nav">  
                                <li><a href="products#loqqat"><span uk-icon="arrow-right"></span>Loqqat</a></li>
                                <li><a href="products#loqqatride"><span uk-icon="arrow-right"></span>Loqqat Ride</a></li>
                                <li><a href="products#qwallet"><span uk-icon="arrow-right"></span>Q-Wallet</a></li>
                                <li><a href="products#quickatt"><span uk-icon="arrow-right"></span>Quick Attendance</a></li>
                                <li><a href="products#ecommerce"><span uk-icon="arrow-right"></span>Ecommerce</a></li>
                            </ul>
                        </div>
                        
                    </div>
                </div>
            </li>  -->
            <li><a  href="https://blog.qaptive.co.in/" target="_blank">Blogs</a></li> 
            <li><a [routerLink]="['contact']">Get in touch</a></li> 
            
            <!-- <li>
                <a href="#">Parent</a>
                <div class="uk-navbar-dropdown">
                    <ul class="uk-nav uk-navbar-dropdown-nav">
                        <li class="uk-active"><a href="#">Active</a></li>
                        <li><a href="#">Item</a></li>
                        <li><a href="#">Item</a></li>
                    </ul>
                </div>
            </li> --> 
        </ul>
    </div>
</nav>

<div id="offcanvas-flip" uk-offcanvas="flip: true; overlay: true">
    <div class="uk-offcanvas-bar">

        <button class="uk-offcanvas-close" type="button" uk-close></button>
        
        <div class="uk-padding">
            <ul class="uk-nav uk-nav-default">
               <li class="uk-active"><a style="margin-bottom: 5px;" class="qt-mobile-menu" href="#">Home</a></li> 
               <ul class="qt-font-list" uk-accordion>
                <li>
                        <a class="uk-accordion-title" [routerLink]="['about']">About Us</a>
                        <div class="uk-accordion-content">
                            <li><a [routerLink]="['about']">More on us</a></li>
                            <li><a [routerLink]="['careers']">Careers</a></li>
                        </div>
                    </li>
                </ul>
                <ul class="qt-font-list" uk-accordion>
                    <li>
                        <a class="uk-accordion-title" href="#">Core Services</a>
                        <div class="uk-accordion-content qt-menu-list">
                            <li><a [routerLink]="['software-product-development']">Software Product Development
                            </a></li>
                            <li><a [routerLink]="['software-services']">Software Services</a></li>
                            <li><a [routerLink]="['it-consulting']">IT Consulting</a></li>
                            <li><a [routerLink]="['creative-design-services']">Creative Design</a></li>
                            <li><a [routerLink]="['e-commerce']">Ecommerce</a></li>
                        </div>
                    </li>
                </ul>
                <ul class="qt-font-list" uk-accordion>
                    <li>
                        <a class="uk-accordion-title" href="#">Solutions</a>
                        <div class="uk-accordion-content qt-menu-list">
                            <li><a [routerLink]="['healthcare-management-solutions']">Healthcare Management 
                            </a></li>
                            <li><a [routerLink]="['education-management-solutions']">Education systems 
                            </a></li>
                            <li><a [routerLink]="['fleet-management-solutions']">Fleet Management</a></li>
                            <li><a [routerLink]="['hospitality-management-solutions']">Hospitality solutions
                            </a></li>
                            <li><a [routerLink]="['budget-management-solutions']">Budget Management
                            </a></li>
                           
                        </div>
                    </li>
                </ul>
                <ul class="qt-font-list" uk-accordion>
                    <li>
                        <a class="uk-accordion-title" href="#">Case Studies</a>
                        <div class="uk-accordion-content">
                            <li><a [routerLink]="['loqqat-case-study']">School Bus Tracking & Student Ridership
                            </a></li>
                            <li><a [routerLink]="['peekay-case-study']">Healthcare management solution
                    
                            </a></li>
                            <li><a [routerLink]="['loqqat-ride-case-study']">Employee Ridership Solution</a></li>
                            <li><a [routerLink]="['townbridge-case-study']">Web Solution for the hospitality sector
                            </a></li>
                        </div>
                    </li>
                </ul>
                <ul class="qt-font-list" uk-accordion>
                    <li>
                        <a class="uk-accordion-title" href="#">Services</a>
                        <div class="uk-accordion-content">
                            <!-- <li class="uk-nav-header uk-text-capitalize qt-font-bold uk-margin-remove-top"><a class="k" [routerLink]="['software-services']">Software Services</a></li>
                            <li class="uk-nav-divider"></li>
                            <li class="uk-nav-header uk-text-capitalize qt-font-bold uk-margin-remove-top"><span>Service Segments</span></li>
                            <li class="uk-nav-divider"></li> -->
                            <li><a [routerLink]="['creative-design']"><span uk-icon="arrow-right"></span>Creative Design</a></li>
                            <li><a [routerLink]="['digital-marketing']"><span uk-icon="arrow-right"></span>Digital Marketing</a></li> 
                            <li><a [routerLink]="['web-design']"><span uk-icon="arrow-right"></span>Web Design </a></li>
                            <li><a [routerLink]="['seo']"><span uk-icon="arrow-right"></span>SEO </a></li>
                            <li><a [routerLink]="['video-animations']"><span uk-icon="arrow-right"></span>Video Animations </a></li>
                        </div>
                    </li>
                </ul>
                <ul class="qt-font-list" uk-accordion>
                    <li>
                        <a class="uk-accordion-title" href="#">Products</a>
                        <div class="uk-accordion-content">
                          

                            <li><a (click)="goToPage('quickatt')"><span uk-icon="arrow-right"></span>Quick Attendance</a></li>                                 
                            <li><a (click)="goToPage('loqqat')"><span uk-icon="arrow-right"></span>Loqqat</a></li>
                            <li><a (click)="goToPage('loqqatride')"><span uk-icon="arrow-right"></span>Loqqat Ride</a></li>
                            <li><a (click)="goToPage('lforce')"><span uk-icon="arrow-right"></span>Loqqat Force</a></li> 
                            <li><a (click)="goToPage('ecommerce')"><span uk-icon="arrow-right"></span>Ecommerce</a></li>
                            <li><a (click)="goToPage('quik')"><span uk-icon="arrow-right"></span>Quik Laundry</a></li>
                            <li><a (click)="goToPage('qanteen')"><span uk-icon="arrow-right"></span>Qanteen</a></li>
                            <li><a (click)="goToPage('qwallet')"><span uk-icon="arrow-right"></span>Qafe</a></li>
                                <li><a (click)="goToPage('reido')"><span uk-icon="arrow-right"></span>Reido</a></li>
                                <li><a (click)="goToPage('way')"><span uk-icon="arrow-right"></span>Way Finding</a></li>
                                <li><a (click)="goToPage('qone')"><span uk-icon="arrow-right"></span>Q-one</a></li>
                                <li><a (click)="goToPage('qcm')"><span uk-icon="arrow-right"></span>QCM</a></li>
                                <li><a (click)="goToPage('valet')"><span uk-icon="arrow-right"></span>Valet Parking</a></li>
                                <li><a (click)="goToPage('quee')"><span uk-icon="arrow-right"></span>Quee</a></li>
                        </div>
                    </li>
                </ul>
                <li class="uk-active"><a class="qt-mobile-menu" href="https://blog.qaptive.co.in/" target="_blank">Blogs</a></li> 
                <li class="uk-active"><a class="qt-mobile-menu" [routerLink]="['contact']">Get In Touch</a></li> 
                
            </ul>
        </div>

    </div>
</div>