import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { MetaService } from '../meta.service';
@Component({
  selector: 'app-loqqat-force',
  templateUrl: './loqqat-force.component.html',
  styleUrls: ['./loqqat-force.component.scss']
})
export class LoqqatForceComponent implements OnInit {
  title = 'Efficient Employee Time Management Software | Loqqat Force';
  constructor(
    private titleService: Title, 
    private metaService: MetaService,
    private meta: Meta
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.meta.updateTag(
      { name: 'description', content: "Optimize your workforce with our top-rated employee time & ride management software in India. Keep your employees engaged and productive. Get started today!" }
    );
    this.metaService.createCanonicalURL();
  }


}
 