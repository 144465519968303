<div class="uk-position-relative uk-visible-toggle" tabindex="-1" uk-slideshow="ratio: 8:2">
    <ul class="uk-slideshow-items">
        <li>
            <img src="../../assets/images/creative_design_service_1.jpg" alt="Creative Design" uk-cover>
            <div class="uk-position-bottom uk-position-medium uk-text-left uk-light">
                <div class="uk-container uk-container-large">
                    <h1 class="uk-margin-remove qt-font-bold">Creative Design</h1>
                    <p class="uk-margin-remove qt-color-white uk-visible@m">Let graphic-rich marketing materials create an impact on your target audience</p>
                </div>
            </div>
        </li>
    </ul>
    <div class="uk-light">
        <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slideshow-item="previous"></a>
        <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slideshow-item="next"></a>
    </div>
</div>

<div class="uk-section uk-section-muted">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left">
                    <h5 class="qt-color-dark qt-font-bold uk-text-uppercase">ITERATIVE DESIGN TO IMPROVISE</h5>
                    <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">World-class design by thoroughly and attentively empathizing with the specifications of the user.
                    </h2>
                </div>
            </div>
            <div> 
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right">
                    <p>
                        Qaptive’s design focuses on delivering high-toned <a href="https://www.qaptive.co.in/software-services">software services</a> to the clientele that satisfies the requirements by deploying the leading technologies into play. Creative design is one of the inevitable parts of our software services that design according to fulfill the requirements and goals it was created for. From the initial client briefing to a successful design we take a crucial look in the different phases such as knowing the necessity, analyzing the client needs further creating and developing sketch for the design, conceptual developments, and reviews.
<br>
<br>
Creative design thrives in the areas of marketing and advertising to create various products that are worthy. Our designers utilize a user interface (UI) design that focuses on the look and style of the website. We provide world-class design by thoroughly and attentively empathizing with the specifications of the user.
                    </p>
                </div>
           </div>
        </div>
    </div>
</div>

<div class="uk-section uk-section-muted uk-padding-remove-top uk-visible@m">
    <div class="uk-container uk-container-large">
        <div class="uk-height-large uk-background-cover uk-light uk-flex  uk-visible@m" uk-parallax="bgy: -200" style="background-image: url('../../assets/images/creative_design_service_2.jpg');"> 
        </div> 
    </div>
</div>
<div class="uk-section uk-section-muted uk-padding-remove-top uk-hidden@m"> 
    <div class="uk-container uk-container-large">
        <div><img src="../../assets/images/creative_design_service_2_mob.jpg"></div>
        <!-- uk-visible@m -->
    </div>
</div>

<div class="uk-section uk-section-muted uk-padding-remove-top">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left"> 
                    <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Convert your idea into  profitable business
                    </h2>
                    <ul class="uk-list">
                        <li>Understand the problem to solve, interacting with clients for developing the design.</li> 
                        <li>Analyze the observations.</li> 
                        <li>Generating ideas from the problem.</li> 
                        <li>Identifying the best possible method to solve the problem.</li> 
                        <li>Modeling  it, and creating designs according to the needs and wants of the customer.</li> 
                        <li>Iterative to get the best possible outcome.</li> 
                   </ul> 
                </div>
            </div>
            <div>
                <div class="uk-text-left"> 
                    <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Key Technology Highlights</h2>
                    <ul class="uk-list">
                        <li>
                            <h4 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Animation</h4>
                            <p>Figures are operated to materialize as moving images to illustrate projects or for conveying ideas and concepts.</p>
                        
                        </li>  
                        <li>
                            <h4 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Motion graphics</h4>
                            <p>
                                Segments of animation are integrated together to obtain the illusion of motion, usually in multimedia projects.
                            </p>
                        </li> 

                        <li>
                            <h4 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Augmented reality</h4>
                            <p>
                                Technology that superimposed a system-generated  image on a user’s view of the real world.
                            </p>

                        </li> 

                        <li>
                            <h4 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">3D printing</h4>
                            <p>
                                Three-dimensional printing is a procedure of building three dimensional solid objects from a digital image.
                            </p>
                        </li> 
                        <li>
                            <h4 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Video production</h4>
                            <p>
                                The process of making video content but with images recorded digitally.
                            </p>
                        </li> 
                        <li>
                            <h4 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Website design and maintenance</h4>
                            <p>
                                It involves the process of creating a website according to the user’s  specification and maintenance to stay updated. It consists of different factors such as web page layout, content creation, and graphic design
                            </p>

                        </li>  
                   </ul> 
                </div>
            </div>
        </div>
    </div>
</div>

<div class="uk-section uk-section-muted uk-padding-remove-top uk-visible@m">
    <div class="uk-container uk-container-large">
        <div class="uk-height-large uk-background-cover uk-light uk-flex " uk-parallax="bgy: -200" style="background-image: url('../../assets/images/creative_design_service_3.jpg');"> 
        </div> 
    </div>
</div>
<div class="uk-section uk-section-muted uk-padding uk-padding-remove-top uk-padding-remove-left uk-padding-remove-right uk-hidden@m">
    <div class="uk-container uk-container-large">
        <div><img src="../../assets/images/creative_design_service_3_mob.jpg"></div>
        <!-- uk-visible@m -->
    </div>
</div>

<!-- <div class="uk-section uk-section-muted uk-padding-remove-top">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left"> 
                    <h1 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Qaptive’s provides exceptional quality software services </h1>
                    <p>Consumers across different industries increasingly demand a personalized experience, which led to the making of standardized products. It’s decisive to plan a distinct, multi-phase digital transformation strategy. Our objective is to create top-notch, sophisticated software services for consumers that offer unpredictable performance. At Qaptive, software services are more adaptable to the changing needs or demands of our users or clients.

                        </p>
                </div>
            </div>
            <div>
                <div class="uk-text-left"> 
                    <h1 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Qaptive’s software services</h1>
                    <p>A passionate team incorporates specialists who have vast knowledge and experience in the digital forefront.</p>
                </div>
            </div>
        </div>
    </div>
</div> -->
 
<!-- <div class="uk-section uk-section-muted uk-padding-remove-top uk-padding-remove-bottom">
    <div class="uk-container uk-container-large">
        <div class="uk-height-large uk-background-cover uk-light uk-flex" uk-parallax="bgy: -200" style="background-image: url('../../assets/images/creative_design_service_4.jpg');"> 
        </div> 
    </div>
</div> -->
  
<!-- <div class="uk-section uk-section-muted uk-padding-remove-top">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left">
                    <h5 class="qt-color-dark qt-font-bold uk-text-uppercase">Create top-notch, sophisticated software services</h5>
                    <h1 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">The Qaptive advantage</h1>
                </div>
            </div>
            <div>
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right"> 
                    <p>Build out your innovative implementations-regardless of where you are on your digital journey-with the ladder to automation, our prescriptive approach to helping businesses see the value. Our automated administrative support services include record keeping, accounting, and financial functions. Qaptive helps boost even small businesses by upgrading their communication process.
                        
                    </p>
                </div>
            </div>
        </div>
    </div>
</div> -->

  