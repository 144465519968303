<div class="uk-position-relative uk-visible-toggle uk-curve-bg" tabindex="-1" uk-slideshow="ratio: 8:2">
    <ul class="uk-slideshow-items">
      <li>
        <!-- <img src="../../assets/images/Group-5.png" alt="Canteen Management App for Students" uk-cover> -->
        <div class="uk-position-medium uk-position-medium uk-text-center uk-light">
          <a [routerLink]="['/software-product-development']" class="uk-button qt-btn qt-bg-light qt-color-white qt-btn-hover uk-margin-top" style="background-color: rgba(234, 230, 230, 0.4);">Take your canteen to the next level </a>
          <div class="uk-container uk-container-large">
            <h1 class="uk-margin-top qt-font-bold qt-font-bold qafe qafe-banner-hd">One dashboard for <br> Multiple Canteen</h1>
            <p class="uk-margin-top qt-color-white uk-visible@m qafe-banner-p">Qafe's single admin dashboard lets you effortlessly manage multiple canteens from one central hub
            </p>
          </div>
          <a [routerLink]="['/software-product-development']" class="uk-button  qt-btn qt-bg-light qt-color-white qt-btn-hover uk-margin-top" style="background-color: #EDCB50;">Get a Free Demo</a>
        </div>
      </li>
    </ul>  
  </div>
<div class="uk-position-medium uk-text-center">
<img src="../../assets/images/Screen.png" alt="Canteen Management App for Students" class="uk-image-screen">
</div>   
<!-- <div id="quickatt"></div> -->
<div class="uk-section uk-section-muted qt-bg-white uk-qafe-mg">
 <div class="uk-container uk-container-large">
 <div class="uk-child-width-expand@s uk-text-left" uk-grid>
<div style="max-width: 550px;">
<div class="uk-text-left">   
<h1 class="uk-margin-remove qt-font-bold qafe qafe-bg-black">Qafe</h1>
 <p class="uk-color-brown">a smart canteen app</p>
<h2 class="qt-font-weight-500  uk-margin-remove-top uk-margin-remove-bottom uk-color-brown" style="font-size:30px;">Perfect solution for canteen operations
</h2>
<p>Experience a novel way to manage and supervise the entire operations of a canteen. 
With an easy-to-use UI and smart features, we bring you Qafe.
A state-of-the-art application to manage your canteen services smoothly and efficiently. 
With our meal management application, the administration will be able to conveniently handle the daily operational activities of a canteen.
They can create, edit and publish the Meal Menus in real-time. Users can order their meals even with a smart card.
            These smart cards can be recharged with cash which are then redeemed by the users for making their canteen purchases.   
</p>
             <p>Our solution is perfect for the employee canteen and the school canteen.
               Parents can easily preorder their kid's meals using our app so that if the kid has any specific food allergy, 
           parents can order their food accordingly. </p>
 <a [routerLink]="['/software-product-development']" class="uk-button  qt-btn qt-bg-light qt-color-white qt-btn-hover uk-margin-top" style="background-color: #A875FF;">Get Demo</a>
<div class="">
                        <img src="../../assets/images/qafee-product_hero.png" alt="Qwallet"> 
                        
 </div>
 </div>
</div>
 <div style="max-width:100%"> 
<div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right" style="padding-top:0;">                  
<div class="uk-padding">
<div class="uk-position-relative uk-visible-toggle" tabindex="-1" uk-slider="autoplay: true">
<ul class="uk-slider-items uk-child-width-1-1@m uk-grid-match uk-grid-small uk-padding uk-padding-remove-right uk-padding-remove-left uk-padding-remove-top" uk-grid>
<li class="uk-width-1-1@m">
   <img src="../../assets/images/Slider-00.jpg" alt="qafe">               
</li>
<li class="uk-width-1-1@m">
 <img src="../../assets/images/Slide-01.jpg" alt="qafe">
</li>
<li class="uk-width-1-1@m">
<img src="../../assets/images/Slide-02.jpg" alt="qafe">
</li>
 <li class="uk-width-1-1@m">
<img src="../../assets/images/Slide-03.jpg" alt="qafe">
</li>
<li class="uk-width-1-1@m">
<img src="../../assets/images/Slide-04.jpg" alt="qafe">
</li>
 </ul>
<a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slider-item="previous"></a>
<a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slider-item="next"></a>
</div>
                        
                    </div>
                </div>
           </div>
        </div>
    </div>
</div>

<div class="uk-section uk-section-muted">
<div class="uk-container uk-container-large">
<div class="uk-text-left" uk-grid >
               <div>
                <div class="uk-text-center">
<h2 class="qt-font-weight-500 uk-margin-remove-top uk-margin-remove-bottom uk-color-brown"> Feature-rich Dashboard for <br> Efficient Supervision </h2>
<p>The Admin Dashboard is highly intuitive and offers a plethora of advanced features that can help the administration monitor and supervise their
canteen operations effectively. Data on canteen attendance, meal distribution, meal consumption patterns etc.
can be captured and stored for auditing purposes. With a wide range of reports, the admin team gets increased visibility 
of food distribution and employee consumption patterns, among other things. Overall, the Meal management application elevates 
the performance of canteens by upgrading food distribution and enables the management to have increased visibility of their canteen operations.
</p> 
<h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-color-brown uk-text-center">Features</h2>               
<ul class="uk-child-width-1-3@m uk-grid-match uk-grid-small uk-padding uk-padding-remove-right uk-padding-remove-left uk-padding-remove-top uk-remove-mb" uk-grid>
<li class="uk-width-1-3@m">
<div class="uk-card uk-card-default uk-card-body qt-border-radius-20">
<h3 class="uk-margin-remove qt-font-bold uk-text-left uk-color-brown">
 Menu management
</h3>
 <p class="uk-text-center">
  <img src="../../assets/images/Menu management.jpg">
 </p>
<p class="uk-margin-remove qt-margin-t-10 uk-text-left">Menus can be created under several categories and sub-categories and can be configured based on time slots.
In addition to the versatile categorization options, our menu management system offers the flexibility to tailor offerings based on specific time slots.
Whether it's breakfast, lunch, dinner, or special events, you can effortlessly configure menus to align with the dynamic needs of your establishment.
This feature allows for strategic planning and optimization of resources,ensuring that your customers are consistently presented with the most relevant and enticing culinary selections throughout the day. </p>
</div>
</li>
<li class="uk-width-1-3@m">
 <div class="uk-card uk-card-default uk-card-body qt-border-radius-20">
<h3 class="uk-margin-remove qt-font-bold uk-color-brown uk-text-left">Automated POS 
</h3>
<p class="uk-text-center">
    <img src="../../assets/images/Automated-POS.jpg" alt="Best Software Development Agency Kochi">
</p>
 <p class="uk-margin-remove qt-margin-t-10 uk-text-left">Avoid waiting time at the counters with a POS device that can hasten the process of ordering food. 
Our integrated POS system streamlines the meal distribution process, eliminating long queues and minimizing wait times at the counters.
With intuitive functionality and rapid processing capabilities, our POS device enables customers to place orders swiftly and accurately. 
 Whether it's a bustling lunch rush or a busy event,
 the automated system ensures efficient order management and timely meal delivery.</p>                           
 </div>
</li>  
<li class="uk-width-1-3@m">
    <div class="uk-card uk-card-default uk-card-body qt-border-radius-20">
    <h3 class="uk-margin-remove qt-font-bold uk-color-brown uk-text-left">
     Reduce manual overheads
     </h3>
    <p class="uk-text-center">
        <img src="../../assets/images/Reduce-manual overheads.jpg" alt="Best Software Development Agency Kochi">
    </p>
    <p class="uk-margin-remove qt-margin-t-10 uk-text-left">Meals can be distributed quickly as employees can place orders directly from the application.
    By empowering employees to place orders directly from the application, our system significantly reduces manual overheads associated with meal distribution.
    Gone are the days of handwritten orders and time-consuming communication between staff and kitchen personnel. With just a few clicks on their device,
    employees can seamlessly submit orders,
    eliminating the need for additional administrative tasks and streamlining the entire process.  </p>
    </div>
    </li>          
 </ul>
 <ul class="uk-child-width-1-3@m uk-grid-match uk-grid-small uk-padding uk-padding-remove-right uk-padding-remove-left uk-padding-remove-top uk-remove-mb" uk-grid>

<li class="uk-width-1-3@m">
<div class="uk-card uk-card-default uk-card-body qt-border-radius-20">
<h3 class="uk-margin-remove qt-font-bold uk-color-brown uk-text-left">Optimal distribution of food
</h3>
<p class="uk-text-center">
    <img src="../../assets/images/Optimal-distribution-of-food.jpg" alt="Best Software Development Agency Kochi">
</p>
<p class="uk-margin-remove qt-margin-t-10 uk-text-left">Track meal distribution and cease the wastage of food by allowing for optimised food distribution among the employees.
Our platform facilitates optimal food distribution by providing real-time tracking of meal allocation. By monitoring meal distribution patterns,
 establishments can identify trends and adjust allocations accordingly, ensuring that food resources are utilized efficiently and waste is minimized.
  Through advanced analytics and customizable settings,managers can dynamically allocate meals based on factors such as employee preferences, dietary restrictions, and historical consumption data.</p>                           
</div>
 </li>
 <li class="uk-width-1-3@m">
    <div class="uk-card uk-card-default uk-card-body qt-border-radius-20">
   <h3 class="uk-margin-remove qt-font-bold uk-color-brown uk-text-left">
   Manage canteens at multiple locations
      </h3>
    <p class="uk-text-center">
       <img src="../../assets/images/Manage-canteens-across-multiple-locations.jpg" alt="Best Software Development Agency Kochi">
   </p>
   <p class="uk-margin-remove qt-margin-t-10 uk-text-left">Single login from the admin panel to monitor and supervise the activities of all the canteens under your purview.
   Our comprehensive solution simplifies the management of canteens across multiple locations by offering a centralized admin panel accessible through a single login. 
   From this unified dashboard, administrators can efficiently oversee and supervise the operations of all canteens under their jurisdiction. This streamlined approach enables real-time monitoring of activities, 
   such as inventory management, sales tracking, and employee performance, across various locations. By consolidating administrative tasks and providing actionable insights, 
   our platform empowers administrators to make informed decisions, optimize resources, 
   and ensure consistent quality and service standards across all canteens. </p>
    </div>
    </li>
    
<li class="uk-width-1-3@m">
    <div class="uk-card uk-card-default uk-card-body qt-border-radius-20">
    <h3 class="uk-margin-remove qt-font-bold uk-color-brown uk-text-left">Analyse the consumption pattern
    </h3>
    <p class="uk-text-center">
        <img src="../../assets/images/Analyse-the-consumption-pattern.jpg">
    </p>
    <p class="uk-margin-remove qt-margin-t-10 uk-text-left">Management can analyse the consumption of an individual item which would help them to make appropriate decisions.
    Our platform offers robust analytics capabilities that enable management to delve deep into the consumption patterns of individual items.By leveraging data analytics tools, administrators gain valuable insights into which items are popular among customers and which ones may be underutilized.
    This granular understanding empowers decision-makers to make informed choices regarding menu optimization, pricing strategies, and inventory management. 
    With the ability to identify trends and preferences, management can tailor offerings to better meet customer demand,reduce waste, and maximize profitability.</p>                           
     </div>
     </li>
 </ul>
 <ul class="uk-child-width-1-3@m uk-grid-match uk-grid-small uk-padding uk-padding-remove-right uk-padding-remove-left uk-padding-remove-top uk-remove-mb" uk-grid>
    <li class="uk-width-1-3@m">
        <div class="uk-card uk-card-default uk-card-body qt-border-radius-20">
          <h3 class="uk-margin-remove qt-font-bold uk-color-brown uk-text-left">
         Extensive reports</h3>
         <p class="uk-text-center">
           <img src="../../assets/images/Extensive-reports.jpg" alt="Best Software Development Agency Kochi">
       </p>
         <p class="uk-margin-remove qt-margin-t-10 uk-text-left">Detailed analysis of the food distribution pattern based on days, categories, sub-categories,
        employees etc. Our platform provides comprehensive reporting functionalities,
         offering detailed analysis of food distribution patterns across various parameters such as days, categories, 
         sub-categories, and employees. These extensive reports offer invaluable insights into consumption trends,
        allowing management to identify peak periods, popular menu items, and areas for improvement. By drilling down into specific metrics,
        administrators can make data-driven decisions to optimize food distribution strategies, streamline operations, and enhance customer satisfaction.
        Whether it's adjusting inventory levels, refining menu offerings, or optimizing staffing schedules,
         our reporting capabilities empower management to take targeted actions that drive efficiency and profitability. </p>
        </div>
         </li>
         <li class="uk-width-1-3@m">
            <div class="uk-card uk-card-default uk-card-body qt-border-radius-20">
             <h3 class="uk-margin-remove qt-font-bold uk-color-brown uk-text-left">Pre-order for convenience</h3>
             <p class="uk-text-center">
                <img src="../../assets/images/Pre-order-for-convenience.jpg" alt="Best Software Development Agency Kochi">
            </p>
            <p class="uk-margin-remove qt-margin-t-10 uk-text-left">Employees can place their orders beforehand for a hassle-free canteen experience. 
            Our pre-order feature enhances convenience by allowing employees to place their meal orders in advance,
            ensuring a seamless and stress-free canteen experience.Whether they prefer to plan their meals for the week ahead or simply want to avoid waiting in line during busy periods,
            employees can easily access the pre-order system through our intuitive platform. By specifying their preferences and dietary requirements in advance,
            they can customize their orders to suit their tastes and nutritional needs. This not only saves time but also reduces congestion at the canteen,
            enabling smoother operations and faster service for all.With the ability to pre-order meals at their convenience, employees can enjoy greater flexibility and efficiency in their dining routine.</p>                           
             </div>
                  </li>
                  <li class="uk-width-1-3@m">
                    <div class="uk-card uk-card-default uk-card-body qt-border-radius-20">
                     <h3 class="uk-margin-remove qt-font-bold uk-color-brown uk-text-left">Easy payment Integration</h3>
                     <p class="uk-text-center">
                      <img src="../../assets/images/Easy-payment-Integration.jpg" alt="Best Software Development Agency Kochi">
                  </p>
                    <p class="uk-margin-remove qt-margin-t-10 uk-text-left">Our platform provides three convenient modes of payment: cash, card, and wallet payment options.
                       Whether employees prefer traditional cash transactions, the ease of using their credit or debit card, or the convenience of digital wallets,
                        our system accommodates their preferences seamlessly. Additionally, for users with Apple devices,
                         we offer Apple Wallet integration for an added layer of convenience. With these diverse payment methods available,
                       employees can choose the option that best suits their needs, ensuring a hassle-free and efficient checkout experience at the canteen.</p>                           
                     </div>
                          </li>
                                                            
  </ul>
 
   

                </div>
                <br>


                <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
                    <div> 
                        
                        <!-- <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right" style="padding-top:0;">
                            <div>
                                <h4 class="qt-font-weight-500 uk-margin  uk-margin-remove-bottom">Menu management</h4>
                                <p>Menus can be created under several categories and sub-categories and can be configured on the basis of time slots.   </p>
                                <hr>
                                <h4 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Reduce manual overheads</h4>
                                <p>Meals can be distributed quickly as employees can place the orders directly from the application. </p>
                                <hr>
                                <h4 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Manage canteens across multiple locations                        </h4>
                                <p>Single login from the admin panel to monitor and supervise the activities of all the canteens under your purview.                        </p>
                                <hr>
                                <h4 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Extensive reports   </h4>
                                <p>Detailed analysis of the food distribution pattern based on days, categories, sub-categories, employees etc.                        </p>
                                <hr>   
                            </div>
                        </div> -->
                   </div>
        
                    <div>
                        <!-- <div class="uk-text-left">
                            <h4 class="qt-font-weight-500 uk-margin  uk-margin-remove-bottom">Automated POS for meal distribution</h4>
                            <p>Avoid waiting time at the counters with a POS device that can hasten the process of ordering food. </p>
                            <hr>
                            <h4 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Optimal distribution of food  </h4>
                            <p>Track meal distribution and cease the wastage of food by allowing for optimised food distribution among the employees.      </p>
                            <hr>
                            <h4 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Analyse the consumption pattern                    </h4>
                            <p>Management can analyse the consumption of an individual item which would help them to take appropriate decisions.                    </p>
                            <hr>
                            <h4 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Pre-order for convenience</h4>
                            <p>Employees can place their orders beforehand for a hassle-free canteen experience.
                            </p>
                            <hr>
                        </div> -->
                    </div>
                </div>

                <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom" style="text-align:center;">Industries We Serve</h2>

                <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
                    <div> 
                        <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right" style="padding-top:0;">
                            <div>
                                <h4 class="qt-font-weight-500 uk-margin  uk-margin-remove-bottom" style="text-align: center;">Employee Canteen</h4>
                                <p style="text-align: center;"><img src="../../assets/images/office.png" alt="Canteen Management App for Students"  ></p>
                            </div>
                        </div>
                   </div>
        
                    <div>
                        <div class="uk-text-left">
                            <!-- <h5 class="qt-color-dark qt-font-bold uk-text-uppercase">Q-wallet</h5> -->
                            <h4 class="qt-font-weight-500 uk-margin  uk-margin-remove-bottom" style="text-align: center;">School Canteen</h4>
                            <p style="text-align: center;"><img src="../../assets/images/school.png" alt="Canteen Management App for Students" ></p>
 
                        </div>
                    </div>
                </div>


 
            </div>
            </div>
 

             <div class=" uk-text-left " uk-grid >
                <div>
                 <div class="uk-text-left">
                     <h3 class="qt-font-weight-500 uk-margin-remove-top uk-margin-remove-bottom"> POS
                     </h3>
                     <p><a href="https://loqqat.com/canteen-management-system" target="_blank">Qafe</a> enables the canteen staff to smartly manage their daily operations smartly, offering maximum productivity. The staff will be equipped with a POS device. Tapping the smart card against this POS device will display the respective menu for that user, from which the user can choose their meal of choice. With cash out of the picture, the handling of the day-to-day transactions made by the users becomes a more smooth process.</p>
                     
                 <ul>
                     <li>Cashless payments</li>
                     <li>POS device for quick and seamless transactions </li>
                     <li>Custom food menus with restricted food items will be displayed</li>
                     <li>Smart card enabled for quick access</li>  
                     <li>Multiple payment options</li>
                 </ul>
                 </div>
             </div>
             </div>
           

    </div>
</div>

<app-contactform></app-contactform>