<div class="uk-position-relative uk-visible-toggle" tabindex="-1" uk-slideshow="ratio: 8:2">
    <ul class="uk-slideshow-items">
        <li>
            <img src="../../assets/images/vparking.jpg" alt="Valet Parking App" uk-cover>
            <div class="uk-position-bottom uk-position-medium uk-text-left uk-light">
                <div class="uk-container uk-container-large">
                    <h1 class="uk-margin-remove qt-font-bold">Valet Parking App</h1>
                    <p class="uk-margin-remove qt-color-white uk-visible@m">Switch to a smarter Parking Management System
                    </p>
                </div>
            </div>
        </li>
    </ul>
    <div class="uk-light">
        <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slideshow-item="previous"></a>
        <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slideshow-item="next"></a>
    </div>
</div>
 

<!-- <div id="quickatt"></div> -->
<div class="uk-section uk-section-muted qt-bg-white"  >
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left" uk-grid>
            <div>
                <div class="uk-text-left">
                    
                    <h2 class="qt-font-weight-500  uk-margin-remove-top uk-margin-remove-bottom">Valet Parking Done Right
                    </h2>
                    <p>Valet Parking App is a digital Parking Management solution with an array of advanced features that will make your valet parking services more efficient, enabling you to provide a high-quality guest experience. With the Valet Parking App's digital key management system, you can track the location and status of all vehicles at all times. The app can also keep precise records of all the vehicles parked in the parking lot, along with guest information. With an easy-to-use interface, the application makes the complete process fast and effortless. Also, once the vehicles have been handed over, guests receive an SMS notification. </p>
                     
                    <p>The application is also equipped with a feature to automate the assigning and tracking of valet attendants. It will aid the administrators in managing a large team with greater control and visibility over the process.</p>
                    
                    <p>Retrieving parked vehicles is made easy by our cutting-edge mapping system, which will allow the valet attendants to locate the parked vehicles without causing any delay. Moreover, through the application, the guests are notified on the arrival of the vehicle when it is ready for pickup, thus adding convenience.
                    </p>
               
                </div>
            </div>
            <div> 
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right" style="padding-top: 0;">
                   
                    <div class="uk-padding">
                        <img src="../../assets/images/Valet-Parking.jpg" alt="Valet Parking Solution">
                    </div>
                </div>
           </div>
        </div>
    </div>
</div>
 

 


<div ></div>
<div class="uk-section uk-section-muted">
    <div class="uk-container uk-container-large">
        <h2 class="qt-font-weight-500 uk-margin-remove-top mb-80" >Take full control of your parking operations</h2>
   
    
            <div class="uk-child-width-expand@s uk-text-left " uk-grid style="border-bottom: 3px dotted #d9d9d9;">
                <!-- <div> 
                    <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right" style="padding-top: 0;">
                       
                        <div class="uk-padding" style="padding-top: 0;">
                            <img src="../../assets/images/tab.png">
                        </div>
                    </div>
               </div> -->
    
               <div>
                <div class="uk-text-left">
                     
                    <h3 class="qt-font-weight-500 uk-margin-remove-top uk-margin-remove-bottom"> Admin Dashboard (Web and Tablet)
                    </h3>
                    <p>A comprehensive dashboard for the valet managers to manage parking facilities efficiently. The application offers information on slot utilisation, driver management, overall income, and much more. The system enables improved control in terms of crowd management, vehicle management, vacant slot management, etc. by digitising the entire process and incorporating the different user groups.</p>
                <p><b>Features</b></p>
                <ul>
                    <li>Vehicle slot status</li>
                    <li>Capacity utilisation</li>
                    <li>Notification for vehicle owners</li>
                    <li>Detailed Reports</li>
                </ul>
                
                </div>
            </div>
    
    
            </div>
            <div class="uk-child-width-expand@s uk-text-left  " uk-grid style="border-bottom: 3px dotted #d9d9d9;">
               
               
    
               <div>
                <div class="uk-text-left">
                     
                    <h3 class="qt-font-weight-500   uk-margin-remove-top uk-margin-remove-bottom">Supervisor Mobile Application
                    </h3>
                    <p>The mobile application that the valet supervisors will be provided with allows them to see the latest requests made by owners of vehicles waiting to be parked. The supervisor can then designate an available driver to handle the request. The parking procedure is completed after the vehicle is parked and the slot has been mapped.
                    </p>
                <p><b>Features</b></p>
                <ul>
                    <li>Assign a driver to a request</li>
                    <li>Check the status of a vehicle</li>
                    <li>Pick up or drop off a vehicle</li>
                     
                </ul>
                
                </div>
            </div>

            <!-- <div> 
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right" style="padding-top: 0;">
                   
                    <div class="uk-padding" style="padding-top: 0;">
                        <img src="../../assets/images/tab.png">
                    </div>
                </div>
           </div> -->
    
    
            </div>
            <div class="uk-child-width-expand@s uk-text-left " uk-grid>
                <!-- <div> 
                    <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right" style="padding-top: 0;">
                       
                        <div class="uk-padding" style="padding-top: 0;">
                            <img src="../../assets/images/tab.png">
                        </div>
                    </div>
               </div> -->
    
               <div>
                <div class="uk-text-left">
                     
                    <h3 class="qt-font-weight-500 uk-margin-remove-top uk-margin-remove-bottom"> Driver Mobile Application
                    </h3>
                    <p>A user-friendly mobile app enables the driver to perform the process of parking and retrieving the cars. The vehicle information, along with details on the parking slot, are included with the task that the supervisor assigns to the drivers. The supervisor app and digital signage in the lobby will both update with the new status when the corresponding NFC tag has been tapped.
                    </p>
                <p><b>Features</b></p>
                <ul>
                    <li>Receive pick-up or drop-off requests </li>
                    <li>Accept or reject requests</li>
                    <li>NFC tapping for easy acknowledgement</li>
                </ul>
                
                </div>
            </div>
    
    
            </div>

    </div>
</div>
 
 
 
 
<app-contactform></app-contactform>