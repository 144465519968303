<div class="uk-section ">
    <div class="uk-container uk-container-xsmall">
        <h2 class="qt-font-weight-500 uk-margin uk-margin-remove-top ">
            Quick Enquiry
        </h2>
        <form [formGroup]="contactFormGroup" #contactForm="ngForm" (ngSubmit)="onSubmit(contactForm)"> 
            <div class="uk-child-width-expand@s uk-text-left" uk-grid>
                <div>
                    <div class="uk-text-left">
                        <div class="uk-margin">
                            <mat-form-field>
                                <input required matInput formControlName="name"  class="uk-input" type="text" placeholder="Full Name">
                                <mat-error *ngIf="hasError('name', 'required')">Name is required</mat-error>
                            </mat-form-field> 
                        </div> 
                        <div class="uk-margin">
                            <mat-form-field>
                                <input required matInput formControlName="email" class="uk-input" type="email" placeholder="Email">
                                <!-- <mat-error *ngIf="hasError('email', 'required')">Email is required</mat-error> -->
                                <mat-error *ngIf="contactFormGroup.get('email').hasError('email')">Please enter a valid email address</mat-error>
                            </mat-form-field> 
                        </div> 
                        <div class="uk-margin">    
                            <mat-form-field>
                                <input required matInput formControlName="phone" maxlength="15" minlength="10" class="uk-input" type="text" placeholder="Phone">
                                <mat-error *ngIf="hasError('phone', 'required')">Phone is required</mat-error>
                            </mat-form-field> 
                        </div> 
                        <div class="uk-margin">    
                            <mat-form-field>
                                <input required matInput formControlName="position" maxlength="50"   class="uk-input" type="text" placeholder="Required Service">
                                <mat-error *ngIf="hasError('phone', 'required')">Required Service is required</mat-error>
                            </mat-form-field> 
                        </div>
                        <!-- <div class="uk-margin dd_contact">
                            <mat-form-field style="padding-left: 0;" appearance="fill" >
                                <mat-label style="padding-left: 0;">Select Required Service</mat-label>
                                <select style="padding-left: 0;" matNativeControl required formControlName="position">                            
                                    <option value="Software Product Development">Software Product Development</option>
                                        <option value="Mobile App Development">Mobile App Development</option>
                                        <option value="Web Development">Web Development</option>
                                        <option value="Creative Design">Creative Design</option>
                                        <option value="Digital Marketing">Digital Marketing</option>
                                        <option value="SEO">SEO </option>
                                        <option value="Video Animations">Video Animations </option>
                                </select>
                            </mat-form-field>
                        </div>  -->
                        <div class="uk-margin">
                            <mat-form-field class="example-full-width">
                                <mat-label>Leave a comment</mat-label>
                                <textarea required matInput formControlName="comments" placeholder="Ex. It makes me feel..."></textarea>
                              </mat-form-field>

                        </div> 
                        <div class="uk-margin">
                            <button class="uk-button uk-button-secondary qt-btn qt-bg-dark qt-color-white  uk-margin-large" style="margin: 30px 0;" type="submit" [disabled]="!contactFormGroup.valid">Submit</button>
                        </div> 
                    </div>
                </div> 
                
            </div>
        </form>
    </div>
</div>