import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, FormGroupDirective, Validators, NgForm } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ErrorStateMatcher } from '@angular/material/core';
import { SubscribeService } from '../shared/service/subscribe.service';
import { Router } from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public newsletterFormGroup: FormGroup;
  constructor(private http: HttpClient, private subscribeservice: SubscribeService, private router: Router, private _formBuilder: FormBuilder, private _snackBar: MatSnackBar) { }
  onSubmit(form) {    
    // this.subscribeservice.subscribeSend(form.value).subscribe((data: any) => {
    //   if (data.code == 200) {
    //     this.subscribeservice.subscribenotification(form.value).subscribe((data: any) => { 
    //       //console.log(data);                
    //       //this.router.navigate(['/careers']);
    //       this.openSnackBar('Subscribed successfully', 'Dismiss'); 
    //       this.newsletterFormGroup = new FormGroup({      
    //         email: new FormControl('', [Validators.required, Validators.email, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]),     
    //       });        
    //   });         
    //   } else {        
    //     this.openSnackBar('Please try after some time', 'Dismiss');        
    //   }
    // });
    console.log('sucess')
    return

  }
  openSnackBar(message, action) {
    this._snackBar.open(message, action, { duration: 5000 });
  }
  ngOnInit(): void {
    this.newsletterFormGroup = new FormGroup({      
      email: new FormControl('', [Validators.required, Validators.email, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]),     
    });
  }

}
