<div class="uk-position-relative uk-visible-toggle" tabindex="-1" uk-slideshow="ratio: 8:2">
    <ul class="uk-slideshow-items">
        <li>
            <img src="../../assets/images/software_dev_1.jpg" alt="Software Development Services" uk-cover>
            <div class="uk-position-bottom uk-position-medium uk-text-left uk-light">
                <div class="uk-container uk-container-large">
                    <h1 class="uk-margin-remove qt-font-bold">Software Product Development</h1>
                    <p class="uk-margin-remove qt-color-white uk-visible@m">When we say best-in-class software products we mean it</p>
                </div>
            </div>
        </li>
    </ul>
    <div class="uk-light">
        <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slideshow-item="previous"></a>
        <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slideshow-item="next"></a>
    </div>
</div>

<div class="uk-section uk-section-muted">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left">
                    <h5 class="qt-color-dark qt-font-bold uk-text-uppercase"> SOLID PRODUCT ROADMAP</h5>
                    <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">We make sure that the software products we rollout are powered by innovation
                    </h2>
                </div>
            </div>
            <div>
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right"> 
                    <p>Our Software products are not just engineering excellence but backed with a solid product roadmap to ensure that all short-term and long term objectives are planned from the day the product vision is formulated. We craft software products with unique business objectives and rollout them to the customer audience to use them in their specific niches.
 
                        <br>
                        <br>
                        We work with start-ups and business organizations to convert their idea into product vision and from there through design, engineering, prototyping, developing, branding, and till rollout to customers. Though all the software products are for Web, Mobile, and Cloud channels, we make sure that the software products we rollout are powered by innovation.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="uk-section uk-section-muted uk-padding-remove-top uk-padding-remove-bottom uk-visible@m">
    <div class="uk-container uk-container-large">
        <div class="uk-height-large uk-background-cover uk-light uk-flex" uk-parallax="bgy: -200" style="background-image: url('../../assets/images/software_dev_2.jpg');"> 
        </div> 
    </div>
</div>

<div class="uk-section uk-section-muted uk-padding uk-padding-remove-top uk-padding-remove-left uk-padding-remove-right uk-hidden@m">
    <div class="uk-container uk-container-large">
        <div><img src="../../assets/images/software_dev_2_mob.jpg"></div>
        <!-- uk-visible@m -->
    </div>
</div>

<div class="uk-section uk-section-muted">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left">
                    <h5 class="qt-color-dark qt-font-bold uk-text-uppercase">EMPOWERING YOUR BUSINESS</h5>
                    <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Product Vision</h2>
                </div>
            </div>
            <div>
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right"> 
                    <p>To understand the value that your product will deliver to your customers, and how it will finally enable your organization to win in the industry will be initially identified by our collaboration. We will empower you with user personas to evaluate the goals, behaviors, and pain points of the intended customers.
                    </p>
                </div> 
            </div>
        </div>
    </div>
</div>

<div class="uk-section uk-section-muted uk-padding-remove-top uk-padding-remove-bottom uk-visible@m">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-center" uk-grid>
            <div>
                <div class="uk-height-large uk-background-cover uk-light uk-flex" uk-parallax="bgy: -200" style="background-image: url('../../assets/images/software_dev_3.jpg');">
                </div>
            </div>
            <div>
                <div class="uk-height-large uk-background-cover uk-light uk-flex" uk-parallax="bgy: -200" style="background-image: url('../../assets/images/software_dev_4.jpg');">
                </div>
            </div>
        </div>
    </div>
</div>
<div class="uk-section uk-section-muted uk-padding-remove-top uk-padding-remove-bottom uk-hidden@m">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-center" uk-grid>
            <div>
                <img src="../../assets/images/software_dev_3_mob.jpg">
            </div>
            <div>
                <img src="../../assets/images/software_dev_4_mob.jpg">
            </div>
        </div>
    </div>
</div>


<div class="uk-section uk-section-muted">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left">
                    <h5 class="qt-color-dark qt-font-bold uk-text-uppercase"> STRATEGIC ROADMAP</h5>
                    <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Product Management</h2>
                </div>
            </div>
            <div>
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right"> 
                    <p>Product management must be significant in articulating the vision, setting the strategic roadmap, and identifying/prioritizing which customer pain points to unfold; Substantially, product management must have an in-depth understanding of the market, and keen insights into how and what will differentiate our software from that of the opponents; and will define the ‘why’, ‘what’, and ‘when’ for everything to do with the software.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="uk-section uk-section-muted uk-padding-remove-top uk-visible@m">
    <div class="uk-container uk-container-large">
        <div class="uk-height-large uk-background-cover uk-light uk-flex" uk-parallax="bgy: -200" style="background-image: url('../../assets/images/software_dev_5.jpg');"> 
        </div> 
    </div>
</div>

<div class="uk-section uk-section-muted uk-padding uk-padding-remove-top uk-padding-remove-left uk-padding-remove-right uk-hidden@m">
    <div class="uk-container uk-container-large">
        <div><img src="../../assets/images/software_dev_5_mob.jpg"></div>
        <!-- uk-visible@m -->
    </div>
</div>

<div class="uk-section uk-section-muted uk-padding-remove-top">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left"> 
                    <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Product Architecture</h2>
                <p>
                    Qaptive ensures the overall technology architecture, as well as the design and technology direction for the software. We will define the technology and engineering excellence of the software and will strive to be at the forefront of technological innovation at all times.
                </p>
                </div>
            </div>
            <div>
                <div class="uk-text-left"> 
                    <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Product Support & Maintenance</h2>
                    <p>
                        We also have separate teams for support and maintenance to ensure that all live customers are adequately supported, with appropriate resolution and feedback mechanisms.
                    </p> 
                </div>
            </div>
        </div>
    </div>
</div>



<div class="uk-section uk-section-muted uk-padding-remove-top  uk-padding-remove-bottom uk-visible@m">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-center" uk-grid>
            <div>
                <div class="uk-height-large uk-background-cover uk-light uk-flex" uk-parallax="bgy: -200" style="background-image: url('../../assets/images/software_dev_6.jpg');">
                </div>
            </div>
            <div>
                <div class="uk-height-large uk-background-cover uk-light uk-flex" uk-parallax="bgy: -200" style="background-image: url('../../assets/images/software_dev_7.jpg');">
                </div>
            </div>
        </div>
    </div>
</div>
<div class="uk-section uk-section-muted uk-padding-remove-top  uk-padding-remove-bottom uk-hidden@m">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-center" uk-grid>
            <div>
                <img src="../../assets/images/software_dev_6_mob.jpg">
            </div>
            <div>
                <img src="../../assets/images/software_dev_7_mob.jpg">
            </div>
        </div>
    </div>
</div>




<div class="uk-section uk-section-muted">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left">
                    <h5 class="qt-color-dark qt-font-bold uk-text-uppercase"> ITERATIVE PROCESS</h5>
                    <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Product Design and Development</h2>
                </div>
            </div>
            <div>
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right"> 
                    <p>
                        At <a href="https://www.qaptive.co.in/ ">Qaptive</a>, we do design thinking which is an iterative process in which we seek to understand the user, challenge assumptions, and redefine problems in an attempt to identify alternative strategies.
                        <br> <br>
                        Our product development approach is to keep the process simple with the maximum quality. We follow the TETO principle (Test Early, Test Often) to ensure that the developed product is refined through fire.

                    </p>
                </div>
            </div>
        </div>
    </div>
</div>



<div class="uk-section uk-section-muted uk-padding-remove-top uk-visible@m">
    <div class="uk-container uk-container-large">
        <div class="uk-height-large uk-background-cover uk-light uk-flex" uk-parallax="bgy: -200" style="background-image: url('../../assets/images/software_dev_8.jpg');"> 
        </div> 
    </div>
</div>

<div class="uk-section uk-section-muted uk-padding uk-padding-remove-top uk-padding-remove-left uk-padding-remove-right uk-hidden@m">
    <div class="uk-container uk-container-large">
        <div><img src="../../assets/images/software_dev_8_mob.jpg"></div>
        <!-- uk-visible@m -->
    </div>
</div>


 
<div class="uk-section uk-section-muted uk-padding-remove-top">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left"> 
                    <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Innovation</h2>
                <p>
                    Innovation is the buzzword in the software industry now. Pedigree, past success, and deep pockets are not anymore a guarantee for current relevance.
                    <br><br>
                    Irrespective of whether you are a startup, an emerging or established player, if you don’t have the ability for ceaseless innovation, you are vowed to become a dinosaur in no time. While the right Go-To-Market strategy and disciplined execution from a business front are essential, the potential to consistently excel in technology innovation is undoubtedly the biggest differentiator for triumphant product companies.

                    
                </p>
                </div>
            </div>
            <div>
                <div class="uk-text-left"> 
                    <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Rationalisation </h2>
                    <p>
                        It is important to identify and incorporate core unique features to make sure that the product is not lost in the crowd of similar products.
                        <br>
                        <br>
                        We help you to evaluate how well applications meet the needs of the organization, which means developing a comprehensive requirements profile that accurately captures those needs. Existing or potential new applications are measured against this frame of reference.
                    </p> 
                </div>
            </div>
        </div>
    </div>
</div>




<div class="uk-section uk-section-muted uk-padding-remove-top uk-visible@m">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-center" uk-grid>
            <div>
                <div class="uk-height-large uk-background-cover uk-light uk-flex" uk-parallax="bgy: -200" style="background-image: url('../../assets/images/software_dev_9.jpg');">
                </div>
            </div>
            <div>
                <div class="uk-height-large uk-background-cover uk-light uk-flex" uk-parallax="bgy: -200" style="background-image: url('../../assets/images/software_dev_10.jpg');">
                </div>
            </div>
        </div>
    </div>
</div>
<div class="uk-section uk-section-muted uk-padding-remove-top uk-hidden@m">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-center" uk-grid>
            <div>
                <img src="../../assets/images/software_dev_9_mob.jpg">
            </div>
            <div>
                <img src="../../assets/images/software_dev_10_mob.jpg">
            </div>
        </div>
    </div>
</div>


 