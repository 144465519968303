<div class="uk-position-relative uk-visible-toggle" tabindex="-1" uk-slideshow="ratio: 8:2">
    <ul class="uk-slideshow-items">
        <li>
            <img src="../../assets/images/seo_1.jpg" alt="seo" uk-cover>
            <div class="uk-position-bottom uk-position-medium uk-text-left uk-light">
                <div class="uk-container uk-container-large">
                    <h1 class="uk-margin-remove qt-font-bold">Search Engine Optimization</h1>
                    <p class="uk-margin-remove qt-color-white uk-visible@m">Leverage the power to be on the first page, increase visibility</p>
                </div>
            </div>
        </li>
    </ul>
    <div class="uk-light">
        <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slideshow-item="previous"></a>
        <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slideshow-item="next"></a>
    </div>
</div>

<div class="uk-section uk-section-muted">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left">
                    <h5 class="qt-color-dark qt-font-bold uk-text-uppercase">Strong SEO foundation</h5>
                    <h1 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">SEO Strategies for Holistic Growth</h1>
                </div>
            </div>
            <div> 
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right">
                    <p>
                        Having a website at the top of the results in the search engine means that thousands or even millions of eyes are peering at your website. We provide a comprehensive search engine optimization portfolio that helps the business enterprises to leverage the power to be on the first page, bring quality improvements, increase visibility, and achieve profitable goals by bringing targeted traffic to their websites. Our online business strategy is our search engine optimization strategy.
 <br>
 <br>
                        The right search engine optimization is built on a strong SEO foundation, which is done at the initial development stage of the website. Each and every website is built with SEO in mind. We provide additional content marketing search engine optimization. We also offer a complete search engine optimization program to further improve your visibility in the search engine.
                    </p>
                </div>
           </div>
        </div>
    </div>
</div>

<div class="uk-section uk-section-muted uk-padding-remove-top uk-visible@m">
    <div class="uk-container uk-container-large">
        <div class="uk-height-large uk-background-cover uk-light uk-flex" uk-parallax="bgy: -200" style="background-image: url('../../assets/images/seo_2.jpg');"> 
        </div> 
    </div>
</div>
 
<div class="uk-section uk-section-muted uk-padding uk-padding-remove-top uk-padding-remove-left uk-padding-remove-right uk-hidden@m">
    <div class="uk-container uk-container-large">
        <div><img src="../../assets/images/seo_2_mob.jpg"></div>
        <!-- uk-visible@m -->
    </div>
</div>
    
<div class="uk-section uk-section-muted uk-padding-remove-top">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left"> 
                    <h1 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Content Strategy</h1>
                    <p>Well-optimized content that is relevant and useful to reach a greater audience. Content that prioritizes the current marketing metrics.
                    </p>
                </div>
            </div>
            <div>
                <div class="uk-text-left"> 
                    <h1 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">On-page Search Engine Optimization</h1>
                    <p>
                        We help customize each page of your website for search engine optimization. We choose the keywords that have commercial value to foster your businesses.
                    </p> 
                </div>
            </div>
        </div>
    </div>
</div>

 
<div class="uk-section uk-section-muted uk-padding-remove-top">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left"> 
                    <h1 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">SEO Consultancy</h1>
                    <p>We help business enterprises, large as well as small business entities to develop SEO strategies for holistic growth. We offer a growth-driven, audience-led search engine optimization for the businesses.
                    </p>
                </div>
            </div>
            <div>
                <div class="uk-text-left"> 
                    <h1 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Keyword & Market Research</h1>
                    <p>
                        Keyword identification is done in parallel with the relevant market metrics thus enhancing realistic projections in search engines and facilitate higher rankings. Categorized keywords research that further prioritize content based on the popularity of categories of keywords.

                    </p> 
                </div>
            </div>
        </div>
    </div>
</div>

 
 
<div class="uk-section uk-section-muted uk-padding-remove-top">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left"> 
                    <h1 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Advanced Technical Audits</h1>
                    <p>By providing technical SEO audits, we analyze the website and internal architecture to ensure the maximum visibility of your website.
                    </p>
                </div>
            </div>
            <div>
               
            </div>
        </div>
    </div>
</div>

<div class="uk-section uk-section-muted uk-padding-remove-top uk-visible@m">
    <div class="uk-container uk-container-large">
        <div class="uk-height-large uk-background-cover uk-light uk-flex" uk-parallax="bgy: -200" style="background-image: url('../../assets/images/seo_3.jpg');"> 
        </div> 
    </div>
</div>

<div class="uk-section uk-section-muted uk-padding uk-padding-remove-top uk-padding-remove-left uk-padding-remove-right uk-hidden@m">
    <div class="uk-container uk-container-large">
        <div><img src="../../assets/images/seo_3_mob.jpg"></div>
        <!-- uk-visible@m -->
    </div>
</div>
 
<div class="uk-section uk-section-muted uk-padding-remove-top">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left"> 
                    <h1 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">SEO Factors that Influence the Visibility of Websites</h1>
                    <ul class="uk-list ">
                        <li>Keyword and Meta Tags</li>
                        <li>Technical SEO</li>
                        <li>On-page SEO</li>
                        <li>Off-page SEO</li>
                        <li>Website Analysis</li>
                        <li>Target audience & their interests</li>
                    </ul>
                </div>
            </div>
            <div>
                <div class="uk-text-left"> 
                    <h1 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Technical SEO Strategy</h1>
                    <ul class="uk-list ">
                        <li>Canonical tags</li> 
                        <li>Open graph tags</li> 
                        <li>URL structure</li> 
                        <li>Schema tags</li> 
                        <li>Knowledge graph</li> 
                        <li>Sitemaps</li> 
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
    
 
<div class="uk-section uk-section-muted uk-padding-remove-top uk-padding-remove-bottom uk-visible@m">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-center" uk-grid>
            <div>
                <div class="uk-height-large uk-background-cover uk-light uk-flex" uk-parallax="bgy: -200" style="background-image: url('../../assets/images/seo_4.jpg');">
                </div>
            </div>
            <div>
                <div class="uk-height-large uk-background-cover uk-light uk-flex" uk-parallax="bgy: -200" style="background-image: url('../../assets/images/seo_5.jpg');">
                </div>
            </div>
        </div>
    </div>
</div>
<div class="uk-section uk-section-muted uk-padding-remove-top uk-padding-remove-bottom uk-hidden@m">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-center" uk-grid>
            <div>
                <img src="../../assets/images/seo_4_mob.jpg">
            </div>
            <div>
                <img src="../../assets/images/seo_5_mob.jpg">
            </div>
        </div>
    </div>
</div>


 

<div class="uk-section uk-section-muted">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left">
                    <h5 class="qt-color-dark qt-font-bold uk-text-uppercase">Why Our SEO Services</h5>
                    <h1 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Growth-driven, audience-led search engine optimization for the businesses</h1>
                </div>
            </div>
            <div>
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right"> 
                    <p>Following our SEO services right takes competitive insight and very specific recommendations, beyond any SEO basics rulebook. Our services begin with defining targeted audiences and taking it all the way to some fairly comprehensive competitive research, search traffic projections, different content strategies, and specific goals and prioritization.
                    </p>
                    <h5 class="qt-color-dark qt-font-bold uk-text-uppercase">Off-Page SEO</h5>
                    <ul>
                        <li>Profile Listing & Local Listing</li>
                        <li>Guest Blogging</li>
                        <li>Social Bookmarking</li>
                        <li>Blog Commenting</li>
                        <li>Business Directory Listing</li>
                        <li>Press Release Submission</li>
                        <li>Infographic Submission</li>
                        <li>Web 2.0 Submission</li>
                            
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
