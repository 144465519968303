import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { MetaService } from '../meta.service';
@Component({
  selector: 'app-healthcare-management-solutions',
  templateUrl: './healthcare-management-solutions.component.html',
  styleUrls: ['./healthcare-management-solutions.component.scss']
})
export class HealthcareManagementSolutionsComponent implements OnInit {
  title = 'Top Hospital Management Software Solutions in India | Qaptive';
  constructor(
    private titleService: Title, 
    private metaService: MetaService,
    private meta: Meta
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.meta.updateTag(
      { name: 'description', content: "Get the best hospital management solutions in India with Qaptive. We offer customizable healthcare management services to meet your unique needs." }
    );
    this.metaService.createCanonicalURL();
  }

}
