<div class="uk-position-relative uk-visible-toggle" tabindex="-1" uk-slideshow="ratio: 8:2">
    <ul class="uk-slideshow-items">
        <li>
            <img src="../../assets/images/home_about.jpg" alt="" uk-cover>
            <div class="uk-position-bottom uk-position-medium uk-text-left uk-light">
                <div class="uk-container uk-container-large">
                    <h1 class="uk-margin-remove qt-font-bold">About Us</h1>
                    <p class="uk-margin-remove qt-color-white uk-visible@m">Technology partner for software innovation and global businesses</p>
                </div>
            </div>
        </li>
    </ul>
    <div class="uk-light">
        <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slideshow-item="previous"></a>
        <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slideshow-item="next"></a>
    </div>
</div>

<div class="uk-section uk-section-muted">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left"> 
                    <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">We are Qaptive
                    </h2>
                        <p>We at Qaptive Technologies provide the Best-in-class software for both mobile and
                            enterprises. Every product released, comes out after rigorous testing and passing
                            stringent quality standards that stand on par with international firms. Our software
                            products stand tall in the crowd of products available in the market as we primarily
                            focus on the following:
                        </p>
                        <ul class="uk-list"> 
                            <li>Belief in true innovation</li>  
                            <li>Detailed customer experience</li>  
                            <li>Purposeful design</li>  
                            <li>Agility in execution</li>  
                            <li>Fluidity in planning</li>  
                            <li>Zero compromise on quality</li>  
                      </ul> 
                      <p>Having the edge in any segment is what creates scope for astronomical growth. Our
                        biggest expertise lies in forging cutting edge solutions that give firms a disruptive
                        edge in their competing markets. We believe that our products can help save
                        valuable time, money and resources.</p>
                        <img src="../../assets/images/office-main.jpeg">
                    </div>
            </div>
            <div>
                <div class="uk-padding">
                    <h2 class="qt-font-weight-500">Our Vision & Mission</h2> 
                     <p>Qaptive Technologies is your Technology Partner for software innovation and
                        market-leading solutions. Starting with our vision of accelerating the evolution
                        of industries through intelligent digital transformation to attain optimal
                        productivity and foster collective agile innovation, our services cover the key
                        aspects of successful product development; business goals; quality; user
                        experience and technology innovation.
                        <br>
                        <br>
                        To understand the value that your product will deliver to your customers, and
                        how it will finally enable your organization to win in the industry will be initially
                        identified by our collaboration. We will empower you with user personas to
                        evaluate the goals, behaviors and pain points of the intended customers.</p>
                        <h5 class="qt-color-dark qt-font-bold">Values</h5>
                        <ul class="uk-list">
                            <li>Customer focus</li>  
                            <li>Respect for each other</li>   
                            <li>Passion towards work</li>   
                            <li>Integrity</li>   
                            <li>Collaborative work</li>   
                            <li>Continuous development</li>   
                      </ul> 
                      <h2 class="qt-font-weight-500">Growth Trend</h2> 
                      <p>Innovation is the buzzword in the software industry now. Pedigree, past
                        success, and deep pockets are no longer a guarantee for current relevance.
                        Irrespective of whether you are a startup, an emerging or established player, if
                        you don’t have the ability for continuous innovation, you are guaranteed to
                        become a dinosaur in no time. While the right Go-To-Market strategy and
                        disciplined execution from a business front are vital, the ability to consistently
                        excel in technology innovation is undisputedly the biggest differentiator for
                        successful product companies.
                        <br>
                        <br>
                        It is important to identify and incorporate core unique features to make sure
                        that the product is not lost in the crowd of similar products. We help you to
                        evaluate how well applications meet the needs of the organization, which
                        means developing a comprehensive requirements profile that accurately
                        captures those needs. Existing or potential new applications are measured
                        against this frame of reference.
                        <br>
                        <br>
                        Consumers across different industries increasingly demand a personalized
                        experience, which led to the making of standardized products. It’s important to
                        outline a definite, multi-phase digital transformation strategy.
                        Our objective is to create top-notch, sophisticated <a href="https://www.qaptive.co.in/ ">software services</a> for
                        consumers that offer unpredictable performance. At Qaptive, software
                        services are more adaptable to the changing needs or demands of our users or
                        clients.</p>
                      
                </div>
            </div>
        </div>
    </div>
</div>


<div class="uk-section uk-section-muted uk-padding-remove-top uk-padding-remove-bottom">
    <div class="uk-text-center">
        <h5 class="qt-color-dark qt-font-bold">MEET THE TEAM</h5>
        <h2 class="big-text qt-font-weight-500 uk-margin-large uk-margin-remove-top qt-color-black">A Passionate team for creating<br> innovative solutions</h2>
    </div>
   
    <div class="uk-position-relative uk-visible-toggle uk-light" tabindex="-1" uk-slider="center: true">
        <ul class="uk-slider-items uk-grid">
            <li class="uk-width-2-5">
                <div class="uk-panel">
                    <img src="../../assets/images/office-1.jpg" alt=""> 
                </div>
            </li>
            <li class="uk-width-2-5">
                <div class="uk-panel">
                    <img src="../../assets/images/office-2.jpg" alt=""> 
                </div>
            </li>
            <li class="uk-width-2-5">
                <div class="uk-panel">
                    <img src="../../assets/images/office-3.jpg" alt=""> 
                </div>
            </li>
            <li class="uk-width-2-5">
                <div class="uk-panel">
                    <img src="../../assets/images/office-4.jpg" alt=""> 
                </div>
            </li>
           
            
        </ul>
    
        <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slider-item="previous"></a>
        <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slider-item="next"></a>
    
    </div>
</div>

<div class="uk-section uk-section-muted">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left">
                    <h5 class="qt-color-dark qt-font-bold uk-text-uppercase">Better together</h5>
                    <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Accelerating the evolution of industries through intelligent digital transformation to attain optimal productivity and foster collective agile innovation
                    </h2>
                        <p>Build out your innovative implementations regardless of where you are on your digital journey-with the ladder to automation, our prescriptive approach to helping businesses see the value.

                            </p>
                </div>
            </div>
            <div>
                <div class="uk-padding">
                    <h2 class="qt-font-weight-500">We will define the technology and engineering excellence of the software and will strive to be at the forefront of technological innovation at all times.
                    </h2>
                    <div class="uk-padding-small"></div>
                    <ul class="uk-margin-remove-top" uk-accordion>
                        <li class="uk-open">
                            <a class="uk-accordion-title qt-font-bold" href="#">Software Product Development</a>
                            <div class="uk-accordion-content">
                                <p>Our <a href="https://www.qaptive.co.in/software-product-development ">product development</a> approach is to keep the process simple with the maximum quality. We follow the; TETO principle (Test Early, Test Often) to ensure that the developed product is refined through fire.
                                </p>
                            </div>
                        </li>
                        <li>
                            <a class="uk-accordion-title qt-font-bold" href="#">Software Services</a>
                            <div class="uk-accordion-content">
                                <p>Building digital products that operate in web environments. It can be a simple website or a complex web application that includes dozens of services locked in a sophisticated architecture.
                                </p>
                            </div>
                        </li>
                        <li>
                            <a class="uk-accordion-title qt-font-bold" href="#">Strategy and IT Consulting</a>
                            <div class="uk-accordion-content">
                                <p>Our core, we take a high-level view of an organization's problems and make recommendations. We study and analyze the flow of a business and identify areas that can be improved. We provide customized new software according to customer insights.
                                </p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>



<div class="uk-container uk-container-large">
    <div class="uk-margin uk-padding-large uk-text-center uk-padding-remove-bottom">
        <h3 class="qt-color-dark qt-font-bold">Awards & Recognition</h3>
        <p></p>
        <div class="uk-margin">
            <br>
        </div>
    </div>
    <!-- <div class="uk-card uk-grid-collapse uk-padding-large uk-child-width-1-2@s  uk-padding-remove-bottom uk-padding-remove-top" uk-grid>
        <div class="uk-flex-first@s uk-card-media-right uk-cover-container uk-text-left uk-inline">
            <div class="uk-card-body uk-padding-large uk-text-center">
                <!-- <h3 class="qt-color-dark qt-font-bold">AWARDS & HONORS</h3>  -->

            <!-- <ul class="uk-list uk-list-bullet">
                <li><h4 class="qt-font-bold">Edutech certification</h4></li>
                <li><h4 class="qt-font-bold">CIO Insider certification</h4></li>
                <li><h4 class="qt-font-bold">Export promotion council certification</h4></li> 
                <li><h4 class="qt-font-bold">Featured in CIO Insider magazine</h4></li>  
            </ul>             -->
            <!-- </div>
            
        </div> -->
        <!-- <div>
            <div class="uk-card-body uk-padding-large">
                <p class="qt-color-dark">Step to explore the wealth of career opportunities and take your career to the next level.</p>
                 
            </div>
        </div> 
        
    </div> -->
    <div class="uk-margin uk-padding-large uk-padding-remove-top uk-margin-remove-top">
        <div class="uk-child-width-1-4@m" uk-grid uk-lightbox="animation: slide">
            <div>
                <a class="uk-inline" href="../../assets/images/cer-1.jpg" data-caption="Edutech Certification">
                    <img src="../../assets/images/cer-1.jpg" alt="">
                </a>
                <p>Delegate to the worldwide expos of EduTech India</p>
            </div>
            <div>
                <a class="uk-inline" href="../../assets/images/cer-2.jpg" data-caption="Export Promotion Council Certification">
                    <img src="../../assets/images/cer-2.jpg" alt="">
                </a>
                <p>Member of Export Promotion Council for EOUs and SEZs</p>
            </div>
            <div>
                <a class="uk-inline" href="../../assets/images/cer-3.jpg" data-caption="CIO Insider Certification">
                    <img src="../../assets/images/cer-3.jpg" alt="">
                </a>
                <p>Most recommended fleet management software solution provider-2019</p>
            </div>
            <div>
                <a class="uk-inline" href="../../assets/images/cer-4.jpg" data-caption="Featured in CIO Insider magazine">
                    <img src="../../assets/images/cer-4_thumb.jpg" alt="">
                </a>
                <p>Qaptive featured in CIO Insider’s, Modern tools for managing business operations, in 2019
                </p>
            </div>
        </div>             
    </div>
</div>