<div class="uk-position-relative uk-visible-toggle" tabindex="-1" uk-slideshow="ratio: 8:2">
    <ul class="uk-slideshow-items">
        <li>
            <img src="../../assets/images/creative_1.jpg" alt="" uk-cover>
            <div class="uk-position-bottom uk-position-medium uk-text-left uk-light">
                <div class="uk-container uk-container-large">
                    <h1 class="uk-margin-remove qt-font-bold">Creative Design</h1>
                    <p class="uk-margin-remove qt-color-white uk-visible@m">Deeper user insight understanding</p>
                </div>
            </div>
        </li>
    </ul>
    <div class="uk-light">
        <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slideshow-item="previous"></a>
        <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slideshow-item="next"></a>
    </div>
</div>

<div class="uk-section uk-section-muted">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left">
                    <h5 class="qt-color-dark qt-font-bold uk-text-uppercase">Combining creativity, innovation and modish graphic designing tools</h5>
                    <h1 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Portray your brand's voice</h1>
                    <p>We deliver best-in-class creative design services, combining creativity, innovation, and modish graphic designing tools and thus to create unique, subtle as well as bolder graphics that can portray your brand's voice in the most appropriate manner. Our objective is to build eye-catching, appealing designs that fulfill our client’s requirements as well as their target audience and market.
                    </p>
                </div>
            </div>
            <div> 
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right">
                    <h4 class="qt-font-bold">Our creative design expertise encompasses</h4>
                    <ul class="uk-list">
                        <li>Well-thought-out interface</li>
                        <li>Deeper user insight understanding</li>
                        <li>Spectacular design</li>
                        <li>An amalgamation of different mobile solutions</li>
                    </ul>
                    <p class="uk-padding uk-padding-remove-left uk-padding-remove-right uk-margin-remove-top uk-margin-remove-bottom">We offer superior quality graphic design services to individuals as well as business enterprises. Our creative designing services provide online support for various verticals such as logos, website, posters, catalogs, newsletters, online advertisements, custom illustrations across the globe. We ensure that we deliver value-added finest services, thereby helping the business enterprise capitalize on growth endeavors.
                    </p>
                </div>
                <div class="uk-margin">
                    <div class="uk-child-width-expand@s" uk-grid>

                        <div>
                            <h4 class="qt-font-bold">We Create</h4>
                            <ul class="uk-list">
                                <li>Logo Design</li>
                                <li>Video Animations</li>
                                <li>3D Visualization</li>
                            </ul>
                        </div>
                    
                        <div> 
                            <h4>&nbsp;</h4>
                            <ul class="uk-list">
                                <li>App Design</li> 
                                <li>Web Design</li>
                                <li>Branding</li> 
                            </ul>
                        </div>
                    
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="uk-section uk-section-muted uk-padding-remove-top uk-padding-remove-bottom  uk-visible@m">
    <div class="uk-container uk-container-large">
        <div class="uk-height-large uk-background-cover uk-light uk-flex uk-visible@m" uk-parallax="bgy: -200" style="background-image: url('../../assets/images/creative_2.jpg');"> 
        </div> 
    </div>
</div> 
<div class="uk-section uk-section-muted uk-padding-remove-top uk-hidden@m">
    <div class="uk-container uk-container-large ">
        <div><img src="../../assets/images/creative_2_mob.jpg"></div>
    </div>
</div>

<div class="uk-section uk-section-muted">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left">
                    <h5 class="qt-color-dark qt-font-bold uk-text-uppercase"> Resourceful and  reliable intuitive design</h5>
                    <h1 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Why Our Graphic Design</h1>
                </div>
            </div>
            <div>
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right"> 
                    <p>Our clarity, quality, and design style of graphic design domain offer you inexpensive web design but indeed a search-friendly design to make your site climb higher in the search engine result page (SERP). Resourceful, reliable graphic design. We develop from scratch and follow standardized procedures to create optimal solutions that are perfectly aligned to suit your specific business requirements.
                        <br>
                        <br>
                        We ensure that we address the unique needs and requirements of each client and project the deployment of appropriate strategies. Our creative design pays enormous attention to the smallest features in graphic design because they are the firmest bricks in the construction of effective design.
                    </p>
                    <h5 class="qt-color-dark qt-font-bold uk-text-uppercase"> Types of our work</h5>


                    <ol class="numbered">
                        <li> 
                            <p class="uk-margin-remove qt-margin-t-10">Fixed price business models work best for small and medium-sized enterprises.
                            </p>
                        </li> 
                        <li> 
                            <p class="uk-margin-remove qt-margin-t-10">It suits medium, small and large, long-term projects.
                            </p>
                        </li> 
                        <li> 
                            <p class="uk-margin-remove qt-margin-t-10"> A well-dedicated team to facilitate the smart business solution.
                            </p>
                        </li>  
                      </ol>

 
                </div> 
            </div>
        </div>
    </div>
</div>
   