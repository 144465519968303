<div class="uk-position-relative uk-visible-toggle" tabindex="-1" uk-slideshow="ratio: 8:2">
    <ul class="uk-slideshow-items">
        <li>
            <img src="../../assets/images/educational_management_1.jpg" alt="Education Management" uk-cover>
            <div class="uk-position-bottom uk-position-medium uk-text-left uk-light">
                <div class="uk-container uk-container-large">
                    <h1 class="uk-margin-remove qt-font-bold">Education Management Solutions</h1>
                    <p class="uk-margin-remove qt-color-white uk-visible@m">Simplify and Unify Your Workflows</p>
                </div>
            </div>
        </li>
    </ul>
    <div class="uk-light">
        <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slideshow-item="previous"></a>
        <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slideshow-item="next"></a>
    </div>
</div>

<div class="uk-section uk-section-muted">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left">
                    <h5 class="qt-color-dark qt-font-bold uk-text-uppercase">Smarter Simulation. Better Outcomes.</h5>
                    <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Easing the administrative hassles
                    </h2>
                </div>
            </div>
            <div> 
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right">
                    <p> Qaptive's education management system is a unique solution for educational institutions to make administrative and control processes easy, enhance efficiency and productivity.
                        <br>
                        <br>
                        This solution helps to manage the functions such as <a href="https://www.qaptive.co.in/qcm">attendance management system</a>, library management, hostel management, virtual classroom, printing services, online fee payments, e-learning process, content management system.
                        <br>
                        <br>
                        It also includes the processing, analyzing, and reporting of educational institutions including teachers, students, staff and payroll library management systems etc. Our education management ERP aims at easing the administrative hassles using advanced technologies such as online payment gateway, smart cards, biometrics, mobile apps, email notifications etc. Our solution is agile so that it can be tailored and furnished according to the differing needs of the institution.

                        
                    </p>
                </div>
           </div>
        </div>
    </div>
</div>

<div class="uk-section uk-section-muted uk-padding-remove-top uk-visible@m">
    <div class="uk-container uk-container-large">
        <div class="uk-height-large uk-background-cover uk-light uk-flex" uk-parallax="bgy: -200" style="background-image: url('../../assets/images/educational_management_2.jpg');"> 
        </div> 
    </div>
</div>
<div class="uk-section uk-section-muted uk-padding uk-padding-remove-top uk-padding-remove-left uk-padding-remove-right uk-hidden@m">
    <div class="uk-container uk-container-large">
        <div><img src="../../assets/images/educational_management_2_mob.jpg"></div>
        <!-- uk-visible@m -->
    </div>
</div>
   

<div class="uk-section uk-section-muted uk-padding-remove-top uk-padding uk-padding-remove-left uk-padding-remove-right">
    <div class="uk-container uk-container-large">
        <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Benefits</h2>
    </div>
</div>
   
<div class="uk-section uk-section-muted uk-padding-remove-top">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left"> 
                    <h4 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">For parents and guardians</h4>
                    <ul class="uk-list">
                        <li>Parents can access to online report cards</li> 
                        <li>Live chat with the teachers</li> 
                        <li>Keep track of their kid's attendance and assignments</li> 
                        <li>Helps in monitoring your child's performance</li> 
                   </ul> 
                </div>
            </div>
            <div>
                <div class="uk-text-left"> 
                    <h4 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">For students</h4>
                    <ul class="uk-list">
                        <li>Online availability of study materials</li> 
                        <li>Update the student information through online procedure, this reduces the paperwork to a greater extent</li> 
                        <li>Online access to the class schedules</li> 
                        <li>A calendar that shows the upcoming events and exams.</li> 
                        <li>Notifies about the leave online</li> 
                   </ul> 
                </div>
            </div>
            <div>
                <div class="uk-text-left"> 
                    <h4 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">For management</h4>
                    <ul class="uk-list">
                        <li>Online database for student details</li> 
                        <li>Online database for teachers details</li> 
                        <li>Review and feedback of teachers</li> 
                        <li>Keep an online track of the fees and payments received or due</li> 
                        <li>Online records of a library management</li> 
                        <li>Online database for hostel students</li> 
                   </ul> 
                </div>
            </div>
            <div>
                <div class="uk-text-left"> 
                    <h4 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">For alumni</h4>
                    <ul class="uk-list">
                        <li>Helps to maintain a connection with the previous students in the educational institution</li>  
                   </ul> 
                </div>
            </div>
        </div>
    </div>
</div>

<div class="uk-section uk-section-muted uk-padding-remove-top uk-padding-remove-bottom uk-visible@m">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-center" uk-grid>
            <div>
                <div class="uk-height-large uk-background-cover uk-light uk-flex" uk-parallax="bgy: -200" style="background-image: url('../../assets/images/educational_management_3.jpg');">
                </div>
            </div>
            <div>
                <div class="uk-height-large uk-background-cover uk-light uk-flex" uk-parallax="bgy: -200" style="background-image: url('../../assets/images/educational_management_4.jpg');">
                </div>
            </div>
        </div>
    </div>
</div>


<div class="uk-section uk-section-muted uk-padding-remove-top uk-padding-remove-bottom uk-hidden@m">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-center" uk-grid>
            <div>
                <div class="uk-container uk-container-large">
                    <div><img src="../../assets/images/educational_management_3_mob.jpg"></div>
                    <!-- uk-visible@m -->
                </div>
            </div>
            <div>
                <div class="uk-container uk-container-large">
                    <div><img src="../../assets/images/educational_management_4_mob.jpg"></div>
                    <!-- uk-visible@m -->
                </div>
            </div>
        </div>
    </div>
</div>

 


<div class="uk-section uk-section-muted">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left">
                    <h5 class="qt-color-dark qt-font-bold uk-text-uppercase">True end-to-end integration</h5>
                    <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Services</h2>
                </div>
            </div>
            <div> 
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right">
                    <h4 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">User-interface</h4>
                    <p>The user-friendly interface helps educational institutions to easily manage the different functionalities and operations efficiently without any technical help.
                    </p>
                    <h4 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Institutes management system</h4>
                    <p>Eminent cloud-based solution that helps student enrolment, course management, class schedules, library, fee payments, teacher payroll, multiple branch operations.
                    </p>
                    <h4 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Learning management system</h4>
                    <p>It provides e-learning courses with videos, online tests, report cards, notifications, study materials, communication of teachers, partners, and franchise management.
                    </p>
                    <h4 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Virtual classroom</h4>
                    <p>We offer an effective virtual classroom that can be accessed from anywhere at any time. The students can view the previous video recordings for further references. This solution helps to include all the participants and creates an environment similar to the classroom.
                    </p>
                    <h4 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Data security</h4>
                    <p>A cloud-based service that saves the institution's data on the cloud. Using this solution data can be retrieved easily and it restricts unauthorized access.
                    </p>
                    <h4 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">SMS and Email Integration</h4>
                    <p>This solution sends SMS and emails to parents, guardians about student's leave, exams, events, report cards and payments.
                    </p>
                </div>
           </div>
        </div>
    </div>
</div>

<div class="uk-section uk-section-muted uk-padding-remove-top uk-visible@m">
    <div class="uk-container uk-container-large">
        <div class="uk-height-large uk-background-cover uk-light uk-flex" uk-parallax="bgy: -200" style="background-image: url('../../assets/images/educational_management_5.jpg');"> 
        </div> 
    </div>
</div> 
 
<div class="uk-section uk-section-muted uk-padding uk-padding-remove-top uk-padding-remove-left uk-padding-remove-right uk-hidden@m">
    <div class="uk-container uk-container-large">
        <div><img src="../../assets/images/educational_management_5_mob.jpg"></div>
        <!-- uk-visible@m -->
    </div>
</div>

<div class="uk-section uk-section-muted uk-padding  uk-padding-remove-top uk-padding-remove-left uk-padding-remove-right">
    <div class="uk-container uk-container-large">
        <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Why Our Education Management Solution?</h2>
    </div>
</div>
   

<div class="uk-section uk-section-muted uk-padding-remove-top">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left"> 
                     <ul class="uk-list ">
                        <li>Keep parents and students in the connectivity through SMS, emails and mobile apps.</li>  
                        <li>Advanced features that enable you to do the regular fee payments, easily and effortlessly.</li>  
                        <li>Helps to know about any further changes in the schedule of classes or exams.</li>  
                        <li>Track students, by facilitating the attendance system.</li>  
                   </ul> 
                </div>
            </div>
            <div>
                <div class="uk-text-left"> 
                      <ul class="uk-list">
                        <li>Elegant website presence.</li>  
                        <li>A hostel management system that tracks and records the details of students.</li>  
                        <li>Online admissions for better student enrolment.</li>  
                   </ul> 
                </div>
            </div>
            <div>
                <div class="uk-text-left"> 
                     <ul class="uk-list ">
                        <li>Empower teachers through e-learning platforms.</li>  
                        <li>Ensures high quality with easy to use interface but effective at the same time.</li>  
                        <li>Flexible, according to the need of the customer and creates custom reports dynamically.</li>  
                   </ul> 
                </div>
            </div> 
        </div>
    </div>
</div>