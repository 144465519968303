<div class="uk-position-relative uk-visible-toggle" tabindex="-1" uk-slideshow="ratio: 8:2">
    <ul class="uk-slideshow-items">
        <li>
            <img src="../../assets/images/video_animation_1.jpg" alt="video animations" uk-cover>
            <div class="uk-position-bottom uk-position-medium uk-text-left uk-light">
                <div class="uk-container uk-container-large">
                    <h1 class="uk-margin-remove qt-font-bold">Video Animations</h1>
                    <p class="uk-margin-remove qt-color-white uk-visible@m">Let’s create great stories!</p>
                </div>
            </div>
        </li>
    </ul>
    <div class="uk-light">
        <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slideshow-item="previous"></a>
        <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slideshow-item="next"></a>
    </div>
</div>

<div class="uk-section uk-section-muted">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left">
                    <h5 class="qt-color-dark qt-font-bold uk-text-uppercase">Delightful video animation services</h5>
                    <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Every business enterprise deserves to be a great success story
                    </h2>
                </div>
            </div>
            <div> 
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right">
                    <p>
                        Powerful tools that envision and outline the exact nature of the film, animated feature, advertisement, or production. For all your marketing communication needs, we handcraft every animated business video so that you can tell great stories.
<br>
<br>
<a href="https://www.qaptive.co.in/ ">Qaptive</a> provides powerful video animation services that help business enterprises differentiate their content and take their marketing and social media presence to the next level.

Advanced technique and tools are used, whether you need to explain complex topics about products or services, educate customers or employees, or create lush animated characters.
<br>
<br>
We offer full package video animation services, that accommodate all budgets, small as well as large business enterprises, and make sure that your business ends up with a delightful animated video that’s compatible with your video content needs.


                    </p>
                </div>
           </div>
        </div>
    </div>
</div>

<div class="uk-section uk-section-muted uk-padding-remove-top uk-visible@m">
    <div class="uk-container uk-container-large">
        <div class="uk-height-large uk-background-cover uk-light uk-flex" uk-parallax="bgy: -200" style="background-image: url('../../assets/images/seo_2.jpg');"> 
        </div> 
    </div>
</div>
<div class="uk-section uk-section-muted uk-padding uk-padding-remove-top uk-padding-remove-left uk-padding-remove-right uk-hidden@m">
    <div class="uk-container uk-container-large">
        <div><img src="../../assets/images/seo_2_mob.jpg"></div>
        <!-- uk-visible@m -->
    </div>
</div>
    
<div class="uk-section uk-section-muted uk-padding-remove-top">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left"> 
                    <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Video Animation for Social Media Videos</h2>
                    <p>Our video animations in social media are used to enhance brand awareness by educating and entertaining the audience. Empower the audience and increase the shareability. We ensure more brand awareness and referral traffic to your website by sharing.
                    </p>
                </div>
            </div>
            <div>
                <div class="uk-text-left"> 
                    <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Commercial Animated Video</h2>
                    <p>
                        Our commercial videos are effectively used to create brand recall on social media. Extensively used by b2c brands to improve the referral traffic of the website.

                    </p> 
                </div>
            </div>
        </div>
    </div>
</div>

 
<div class="uk-section uk-section-muted uk-padding-remove-top">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left"> 
                    <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Explainer Animated Video</h2>
                    <p>Our explainer videos are used to explain services and products. We create a story, that perfectly matches the target customer persona.</p>
                </div>
            </div>
            <div>
                <div class="uk-text-left"> 
                    <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Animation for Corporate Communication</h2>
                    <p>
                        Our corporate videos are used by companies to communicate with prospective customers about their stories, vision, and values.

                    </p> 
                </div>
            </div>
        </div>
    </div>
</div>

 
 
<div class="uk-section uk-section-muted uk-padding-remove-top uk-visible@m">
    <div class="uk-container uk-container-large">
        <div class="uk-height-large uk-background-cover uk-light uk-flex" uk-parallax="bgy: -200" style="background-image: url('../../assets/images/seo_3.jpg');"> 
        </div> 
    </div>
</div>
<div class="uk-section uk-section-muted uk-padding uk-padding-remove-top uk-padding-remove-left uk-padding-remove-right uk-hidden@m">
    <div class="uk-container uk-container-large">
        <div><img src="../../assets/images/seo_3_mob.jpg"></div>
        <!-- uk-visible@m -->
    </div>
</div>
 
<div class="uk-section uk-section-muted">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left">
                    <h5 class="qt-color-dark qt-font-bold uk-text-uppercase">Why choose our video animation services</h5>
                    <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">A vertically integrated team of passionate storytellers</h2>
                </div>
            </div>
            <div> 
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right">
                    <ul class="uk-list">
                        <li>Streamlined collaboration process with most experienced vertically integrated team of passionate storytellers.
                        </li> 
                        <li>A dedicated team of artists and strategists execute the plan together with you.
                        </li> 
                        <li>Top-up your customer experience with transparency in each step.</li>  
                    </ul>
                </div>
           </div>
        </div>
    </div>
</div>
