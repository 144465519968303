import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { MetaService } from '../meta.service';

@Component({
  selector: 'app-seo',
  templateUrl: './seo.component.html',
  styleUrls: ['./seo.component.scss']
})
export class SeoComponent implements OnInit {
  title = 'Top SEO Services to Enhance Your Online Presence';
  constructor(
    private titleService: Title, 
    private metaService: MetaService,
    private meta: Meta
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.meta.updateTag(
      { name: 'description', content: "Looking for reliable SEO services in India? Our SEO experts provide customized solutions to boost your online presence & drive more traffic to your website. " }
    );
    this.metaService.createCanonicalURL();
  }

}
