<div class="uk-position-relative uk-visible-toggle" tabindex="-1" uk-slideshow="ratio: 8:2">
    <ul class="uk-slideshow-items">
        <li>
            <img src="../../assets/images/loqqat_force_banner.jpg" alt="Employee Management Software" uk-cover>
            <div class="uk-position-bottom uk-position-medium uk-text-left uk-light">
                <div class="uk-container uk-container-large">
                    <h1 class="uk-margin-remove qt-font-bold">Loqqat Force</h1>
                    <p class="uk-margin-remove qt-color-white uk-visible@m">Attendance management for the workforce on the move</p>
                </div>
            </div>
        </li>
    </ul>
    <div class="uk-light">
        <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slideshow-item="previous"></a>
        <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slideshow-item="next"></a>
    </div>
</div>

<div class="uk-section uk-section-muted">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left">
                    <!-- <h5 class="qt-color-dark qt-font-bold uk-text-uppercase">Standalone app</h5> -->
                    <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Solution overview</h2>
                    <p>    Moving the workforce from site to site and assigning them to different projects will help to quickly deliver projects. LoqqatForce is an innovative solution to optimally capture the work duration of the workforce. 
                        LoqqatForce is an easy to use solution to measure the exact hours spend on travel and at each site by workforce. The exact work hours will be then pushed to the compensation systems and project accounting applications.</p>
                </div>
            </div>
            <div> 
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right">
                    <p> 
                     
                        The solution driven by a simple and robust technology that helps the project managers to track the time spent by employees at worksites and the commute that needs to be monitored. This can be further segregated based on the skill category, project, site etc. It eliminates the need to manually mark the attendance - converting and exporting them to an application which further consumes additional effort and possibilities of human error. The information can be accessed by the management realtime, giving them much needed information on the go. The information can be analysed at various levels to take key decisions and to plug in any unwanted resource wastage. 
                    </p>
                </div>
           </div>
        </div>
    </div>
</div>

<div class="uk-section uk-section-muted uk-padding-remove-top uk-visible@m">
    <div class="uk-container uk-container-large">
        <div class="uk-height-large uk-background-cover uk-light uk-flex" uk-parallax="bgy: -200" style="background-image: url('../../assets/images/loqqat_force_2.jpg');"> 
        </div> 
    </div>
</div>
<div class="uk-section uk-section-muted uk-padding uk-padding-remove-top uk-padding-remove-left uk-padding-remove-right uk-hidden@m">
    <div class="uk-container uk-container-large">
        <div><img src="../../assets/images/loqqat_force_2.jpg"></div>
        <!-- uk-visible@m -->
    </div>
</div>
  

<div class="uk-section uk-section-muted uk-padding-remove-top uk-padding-remove-bottom uk-hidden@m">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-center" uk-grid>
            <div>
                 <img src="../../assets/images/loqqat_force_3.jpg"> 
            </div>
            <div>
                <img src="../../assets/images/loqqat_force_4.jpg"> 
            </div>
        </div>
    </div>
</div>


<div class="uk-section uk-section-muted uk-padding-remove-top">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left">
                    <!-- <h5 class="qt-color-dark qt-font-bold uk-text-uppercase">Increase the potential of your business to great heights</h5> -->
                    <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Powerful reporting tool</h2>
                    <p class="filler-color"> Workforce time tracker - Driven by technology </p>
                    <!-- <p> Fleet management system for different aspects of business
                    </p> -->
                </div>
            </div>
            <div> 
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right">
                    <p> Reports are easily customizable with few clicks and downloaded in PDF or excel formats. The reports can also be scheduled so that it will be generated and sent to the required contacts at a prescheduled time on a predefined pattern whether daily, weekly or monthly. This eliminates the need for manual intervention and delayed reports for the decision makers. Should the data be used for payroll processing, the data will be pushed to third party accounting or compensation applications as well.  
 
                    </p>
                   
                </div>
           </div>
        </div>
    </div>
</div>

<div class="uk-section uk-section-muted uk-padding-remove-top uk-visible@m">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-center" uk-grid>
            <div>
                <div class="uk-height-large uk-background-cover uk-light uk-flex"   style="background-image: url('../../assets/images/loqqat_force_3.jpg');">
                </div>
            </div>
            <div>
                <div class="uk-height-large uk-background-cover uk-light uk-flex"  style="background-image: url('../../assets/images/loqqat_force_4.jpg');">
                </div>
            </div>
        </div>
    </div>
</div>


<div class="uk-section uk-section-muted uk-padding-remove-top">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left"> 
                    <!-- <h5 class="qt-color-dark qt-font-bold uk-text-uppercase">Smart way to commute, to your work</h5> -->
                    <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Technology 
                    </h2>
                    <p>
                        The attendance is captured through a smart card or fingerprint at various points of entry by an Android device making it easy to deploy at worksites and vehicles. A simple tap which takes less than second to read is good to go. Several use cases can be added on to this platform like food distribution, asset or device assigning etc. which makes it scalable to meet the growing requirements. 
                    </p>
                </div>
            </div>
            <div> 
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right"> 
                    
                    <h4 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Features</h4>
                    <ul class="uk-list">
                        <li>Project cost calculation</li>   
                        <li>Project time calculation</li>   
                        <li>Break time tracking</li>   
                        <li>Customizable reports</li>   
                        <li>Project/skill category wise break up</li>   
                        <li>Monitor work delays & early leaving</li>   
                        <li>Real time location tracking</li>   
                        <li>Minimal deployment time & zero initial cost</li>   
                   </ul> 
                   <p>LOQQAT Force is an ideal companion for the accounting and the operations team to track the work hours in the best way possible. The solution comes with a perfect blend of technology, hardware and functionality to meet the current and future requirements making it a perfect choice for both mid size and large size organizations.  </p>
                </div>
           </div>
        </div>
    </div>
</div>
 
<app-contactform></app-contactform>