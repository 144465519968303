<div class="uk-position-relative uk-visible-toggle" tabindex="-1" uk-slideshow="ratio: 8:2">
    <ul class="uk-slideshow-items">
        <li>
            <img src="../../assets/images/digita_marketing_1.jpg" alt="Digital Marketing" uk-cover>
            <div class="uk-position-bottom uk-position-medium uk-text-left uk-dark">
                <div class="uk-container uk-container-large">
                    <h1 class="uk-margin-remove qt-font-bold">Digital Marketing</h1>
                    <p class="uk-margin-remove qt-color-dark uk-visible@m">Take new relic, stand out from the crowd</p>
                </div>
            </div>
        </li>
    </ul>
    <div class="uk-light">
        <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slideshow-item="previous"></a>
        <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slideshow-item="next"></a>
    </div>
</div>

<div class="uk-section uk-section-muted">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left">
                    <h5 class="qt-color-dark qt-font-bold uk-text-uppercase">Digital Marketing Done Right</h5>
                    <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Strategies and tools that have the most potential to engage people</h2>
                   
                    <ul class="uk-list">
                        <li>In-depth market analysis</li>
                        <li>Business-oriented development</li>
                        <li>Highly competitive product</li>
                        <li>Scalable architecture</li>
                    </ul>
                </div>
            </div>
            <div> 
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right">
                    <p>The way business enterprise technology needs to approach marketing themselves is evolving and it is inevitable to leverage versatile tactics that will produce real results. Our digital marketing solution offers the prospect of enhancing customer interaction through different sources such as instant messaging systems, social networking sites, and mobile apps. We help to accelerate the investment in marketing initiatives, small as well as large business enterprises and organizations, for strategies and tools that have the most potential to engage people.
                    </p>
                    <h4 class="qt-font-bold">Key digital marketing strategies that an enterprise can use to transform the market themselves</h4>
                    <ul class="uk-list">
                        <li>Producing video content to market your solution</li>
                        <li>Fascinate people with a good, easy to understand story first</li>
                        <li>Take new relic, stand out from the crowd</li>
                        <li>Useful content about your solution, product demos, webinars about industry trends and best practices</li>
                        <li>Produce animated infographics</li>
                        <li>An animated explainer of services or solutions you offer.</li>
                        <li>Partnering with influencers to up the credibility and social media reach</li>
                        <li>Engage in a consistent program of high-value and quality content that will foster long-term trust between your target buyers and your brand.</li>
 
                    </ul>
                </div>
              
            </div>
        </div>
    </div>
</div>

<div class="uk-section uk-section-muted uk-padding-remove-top uk-visible@m">
    <div class="uk-container uk-container-large">
        <div class="uk-height-large uk-background-cover uk-light uk-flex" uk-parallax="bgy: -200" style="background-image: url('../../assets/images/digita_marketing_2.jpg');"> 
        </div> 
    </div>
</div>
<div class="uk-section uk-section-muted uk-padding uk-padding-remove-top uk-padding-remove-left uk-padding-remove-right uk-hidden@m">
    <div class="uk-container uk-container-large">
        <div><img src="../../assets/images/digita_marketing_2_mob.jpg"></div>
        <!-- uk-visible@m -->
    </div>
</div>
   

<div class="uk-section-muted">
    <div class="uk-container uk-container-large">
        <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom uk-text-center">Strategies and tools that have the most potential to engage people</h2>
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left">
                    <h4 class="qt-color-dark qt-font-bold uk-text-uppercase">SEO </h4>
                     <p>
                        Search engine optimization (SEO) is the process of improving the visibility of a website in organic search engine results by analysing and modifying it using <a href="https://www.qaptive.co.in/seo">SEO</a> tactics. Our team of experts will research keywords for your website, optimize your content, and ensure that your website is technically sound to improve your visibility on search engines.
                     </p>
                </div>
            </div>
            <div>
                <div class="uk-text-left">
                    <h4 class="qt-color-dark qt-font-bold uk-text-uppercase">SMM </h4>
                     <p>
                        Social media marketing (SMM) is the process of promoting a product or service with the help of social media channels. A meticulous social media marketing plan will help your SEO efforts and promote your company to a larger audience. Our team will work with you to craft a social media marketing strategy that meets your company's goals.
                     </p>
                </div>
            </div>
            <div>
                <div class="uk-text-left">
                    <h4 class="qt-color-dark qt-font-bold uk-text-uppercase">SEM </h4>
                     <p>
                        Search engine marketing (SEM) is an alternative to organically earning clicks to a website by using search engine advertising. We provide a variety of SEM services, including Google AdWords and Facebook Ads. Our SEM experts will work in tandem with you to understand your goals and create a marketing strategy that works best for you.
                     </p>
                </div>
            </div>
             
        </div>
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left">
                    <h4 class="qt-color-dark qt-font-bold uk-text-uppercase">Logo Designing </h4>
                     <p>
                        It is imperative to have a logo for your brand that stands out among the competition. Our creative design experts can help you bring your vision to life through custom logos that capture your brand's personality. Let us help you create a visual identity that resonates with your audience and sets you apart in today's competitive market.
                     </p>
                </div>
            </div>
            <div>
                <div class="uk-text-left">
                    <h4 class="qt-color-dark qt-font-bold uk-text-uppercase">Content Marketing </h4>
                     <p>
                        We provide expert content marketing services that attract audiences and drive conversions.  We'll assist you with developing an engaging message that connects with your audience and sets you apart, whether it be for blogs, social media or video scripts. Let us assist you in taking your message to the intended audience through effective content marketing.
                     </p>
                </div>
            </div>
            <div>
                <div class="uk-text-left">
                    <h4 class="qt-color-dark qt-font-bold uk-text-uppercase">Email Marketing </h4>
                     <p>
                        Don't let your message get lost in the midst of all the noise. With our assistance, you will be able to use email to engage with your audience more effectively. Using a mix of personalized messaging, targeted campaigns, and expert strategy, we'll help you unlock the power of email marketing and take your business to the next level.
                     </p>
                </div>
            </div>
             
        </div>
        <br>
        <br>
    </div>
</div>
