import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { MetaService } from '../meta.service';

@Component({
  selector: 'app-software-services',
  templateUrl: './software-services.component.html',
  styleUrls: ['./software-services.component.scss']
})
export class SoftwareServicesComponent implements OnInit {
  title = 'Leading Software Services Provider | Qaptive Technologies | India';
  constructor(
    private titleService: Title, 
    private metaService: MetaService,
    private meta: Meta
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.meta.updateTag(
      { name: 'description', content: "Partner with Qaptive Technologies for cutting-edge software services that drive business growth. We offer expert solutions and unmatched customer support." }
    );
    this.metaService.createCanonicalURL();
  }

}
