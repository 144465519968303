import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { MetaService } from '../meta.service';
@Component({
  selector: 'app-quee',
  templateUrl: './quee.component.html',
  styleUrls: ['./quee.component.scss']
})
export class QueeComponent implements OnInit {
  title = 'Effortless Meeting Scheduling Software | Simplify Your Calendar';
  constructor(
    private titleService: Title, 
    private metaService: MetaService,
    private meta: Meta
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.meta.updateTag(
      { name: 'description', content: "Simplify your meeting scheduling process with our automatic meeting scheduling software. Save time, eliminate back-and-forth emails, and effortlessly coordinate meetings with ease." }
    );
    this.metaService.createCanonicalURL();
  }


}


 