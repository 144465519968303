import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { MetaService } from '../meta.service';
@Component({
  selector: 'app-education-management-solutions',
  templateUrl: './education-management-solutions.component.html',
  styleUrls: ['./education-management-solutions.component.scss']
})
export class EducationManagementSolutionsComponent implements OnInit {
  title = 'Edtech & Education Management Software Solutions | Qaptive';
  constructor(
    private titleService: Title, 
    private metaService: MetaService,
    private meta: Meta
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.meta.updateTag(
      { name: 'description', content: "Get complete control of your educational institution with our education management solutions. Our software offers unparalleled insights into student performance & progress." }
    );
    this.metaService.createCanonicalURL();
  }

}
