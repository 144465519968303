<div class="uk-position-relative uk-visible-toggle" tabindex="-1" uk-slideshow="ratio: 8:2">
    <ul class="uk-slideshow-items">
        <li>
            <div class="uk-overlay-primary uk-position-cover uk-visible@m qt-z-index-overlay-mobile"></div>
            <img src="../../assets/images/careers.jpg" alt="Careers" uk-cover>
            <div class="uk-position-bottom uk-position-medium uk-text-left uk-light qt-z-index-text-mobile">
                <div class="uk-container uk-container-large">
                    <h1 class="uk-margin-remove qt-font-bold">Careers</h1>
                    <p class="uk-margin-remove qt-color-white uk-visible@m">Do what you love</p>
                </div>
            </div>
        </li>
    </ul>
    <div class="uk-light">
        <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slideshow-item="previous"></a>
        <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slideshow-item="next"></a>
    </div>
</div>

 

<div class="uk-section uk-section-muted">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left">
                    <h5 class="qt-color-dark qt-font-bold uk-text-uppercase">Learn.Explore.Innovate</h5>
                    <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Explore an energetic, fast-paced work environment and get to work on the latest digital technologies.
                    </h2>
                </div>
            </div>
            <div> 
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right">
                    <p> Bring your insight, imagination and healthy disregard for the impossible.  Start your career at <a href="https://www.qaptive.co.in/ ">Qaptive</a>: Discover our entry options for pupils, students, graduates and professionals.  



                    </p>
                    <h4 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Join us in creating the technology to connect the world.

                    </h4>
                    <ul class="uk-list uk-list-divider">
                        <li>We offer a variety of  internships and full-time opportunities designed to empower you.</li> 
                        <li> Imagine the impact you can have and jumpstart your career. </li>  
                   </ul> 
                </div>

                

                
           </div>
        </div>
    </div>
</div>
 

<div class="uk-section-muted">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large mb-85" uk-grid>
            
            <div> 
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right">
                    <div class="uk-position-relative uk-visible-toggle uk-light" tabindex="-1" uk-slider>

                        <ul class="uk-slider-items uk-grid">
                            <li class="uk-width-4-5">
                                <div class="uk-panel">
                                    <img src="../../assets/images/office-1.jpg" width="1800" height="1200" alt="">
                                </div>
                            </li>
                            <li class="uk-width-4-5">
                                <div class="uk-panel">
                                    <img src="../../assets/images/office-3.jpg" width="1800" height="1200" alt="">
                                </div>
                            </li>
                            <li class="uk-width-4-5">
                                <div class="uk-panel">
                                    <img src="../../assets/images/office-7.jpg" width="1800" height="1200" alt="">
                                </div>
                            </li>

                            <li class="uk-width-4-5">
                                <div class="uk-panel">
                                    <img src="../../assets/images/office-5.jpg" width="1800" height="1200" alt="">
                                </div>
                            </li>

                            
                            <li class="uk-width-4-5">
                                <div class="uk-panel">
                                    <img src="../../assets/images/office-2.jpg" width="1800" height="1200" alt="">
                                </div>
                            </li>

                           

                            <li class="uk-width-4-5">
                                <div class="uk-panel">
                                    <img src="../../assets/images/office-6.jpg" width="1800" height="1200" alt="">
                                </div>
                            </li>

                            <li class="uk-width-4-5">
                                <div class="uk-panel">
                                    <img src="../../assets/images/office-4.jpg" width="1800" height="1200" alt="">
                                </div>
                            </li>
                             
                        </ul>
                    
                        <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slider-item="previous"></a>
                        <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slider-item="next"></a>
                    
                    </div>
                    
                </div>

                

                
           </div>

           <div>
            <div class="uk-text-left">
                <!-- <h5 class="qt-color-dark qt-font-bold uk-text-uppercase">Learn.Explore.Innovate</h5> -->
                <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom careerfont">We love our work and are very serious about it, but hey, we also know how to let loose and have a good time. You know it’s all about building lasting relationships, because let's face it, work is just better when you've got awesome pals by your side. 
                </h2>
                <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom careerfont" style="color: #94003b;margin-bottom: 30px !important;"> Become a part of our journey!</h2>
              
               <a href="https://careers.qaptive.co.in/"> <button class="careers-btn">Apply Now</button></a>
            </div>
        </div>


        </div>
    </div>
</div>
 



<!-- <div class="uk-section-muted">
    <div class="uk-container uk-container-large">
        <h2 _ngcontent-pni-c105="" class="qt-font-weight-500 uk-margin uk-margin-remove-top uk-text-center"> Apply for your position </h2>
        <br>
        <div class="uk-text-center uk-card uk-card-default uk-card-body" >
            <p class="uk-text-center"><img src="../../assets/images/closed.png" style="width: 300px;"></p>  
<h3 class="uk-text-center" style="margin-top: 0;"><b>We have no openings at this time</b></h3>
<p class="uk-text-center">Please revisit our website for future updates.</p>
            </div>

 
    </div>
    <br> <br> <br>
</div> -->


<!-- <div class="uk-section uk-section-muted qt-bg-dark">
    <div class="uk-container uk-container-small">
        <ul uk-accordion="multiple: true">
            <li class="uk-open">
                <a class="uk-accordion-title" href="#">
                    <span class="uk-heading-small qt-heading-small">IOS Developer</span>
                </a>
                <div class="uk-accordion-content">
                    <p>Do you consider yourself to be a creative individual who is willing to step outside of the box in an effort to raise the bar in mobile technology development?
                        Then you might be the right team member we are looking for!
                        <br>
                        <br>
                        Our ideal candidate should be able to actively contribute to the development of fast, secure, and reliable apps, work well under tight deadlines, and have an eye for detail.
                    </p>
                    <h5 class="qt-color-light qt-font-bold uk-text-uppercase">iOS Developer Duties and Responsibilities:</h5> 
                    <ul class="uk-list uk-list-bullet">
                        <li>Design and build advanced app features for the iOS platform</li>
                        <li>Independently craft project solutions by applying solid Object-Oriented-Design principles</li>
                        <li>Working in a team of talented iOS engineers developing amazing native apps</li> 
                        <li>Work closely with product management & UX to execute an idea from concept to delivery using excellent software design, coding, & processes</li>  
                        <li>Continuously discover, evaluate, and implement new technologies to maximize development efficiency</li>  
                    </ul>
                    <h5 class="qt-color-light qt-font-bold uk-text-uppercase">iOS Developer Requirements:</h5> 
                    <ul class="uk-list uk-list-bullet">
                        <li>3+ years of experience in Native iOS development using Swift and Objective C</li>
                        <li>Strong computer fundamentals and OO programming concepts</li>
                        <li>Understanding of Apple’s design principles and interface guidelines</li>  
                        <li>Good knowledge on AutoLayout, offline storage, threading, memory management and performance tuning</li>  
                        <li>Experience working with iOS frameworks and APIs such as Cocoa Touch, Foundation, MapKit, etc.</li> 
                        <li>Familiarity with native build tools (Expo, React-native cli, gradle)</li>  
                        <li>Familiarity with RESTful APIs to connect iOS applications to back-end services</li>  
                        <li>Familiarity with cloud message APIs and Push notifications</li>  
                        <li>Knowledge on Swift UI is a plus</li>  
                        <li>Solid understanding of mobile landscape, iOS architectures, trends, popular Third-party Libraries & APIs used in iOS Development</li>   
                    </ul>
                    <p>
                       <strong>Immediate joiners will be given preference, no remote working options available.</strong>

                        <br>
                        <br>
                        Please include Job title as a Subject of mail while sending resumes. Kindly mail your updated profile to the HR Manager along with the current, expected CTC and notice period details.
                        <br>
                        <br>
                        <strong>Job Type: Full-time</strong>
                    </p>
                    <div class="qt-badge-light">
                        <span class="uk-badge">Swift</span>
                        <span class="uk-badge">Swift UI</span>
                        <span class="uk-badge">Cocoa Touch</span>
                        <span class="uk-badge">GIT</span>
                        <span class="uk-badge">MapKit</span> 
                        <span class="uk-badge">UI / UX</span> 
                    </div> 
                </div>
                <hr class="qt-ht-dark">
            </li>
            <li>
                <a class="uk-accordion-title" href="#">
                    <span class="uk-heading-small qt-heading-small">Software Testing Intern</span>
                </a>
                <div class="uk-accordion-content">
                    <p>We expect Software Testing Interns to be significantly more than manual testers - you write scripts to automate testing and create tools so developers can test their own code. As a Test Intern, you can collaborate with a team of developers, identify weak spots, and constantly design better and creative ways to detect issues.
                    </p>
                    <h5 class="qt-color-light qt-font-bold uk-text-uppercase">Job Responsibilities</h5> 
                    <ul class="uk-list uk-list-bullet">
                        <li>Improving test coverage, faster releases and ensure production health</li>
                        <li>Work closely with development teams to improve test coverage and quality</li>
                        <li>Hands-on ability to automate repeated tasks and build test coverage through existing or new infrastructure</li> 
                        <li>Write moderately complex code/scripts to test systems, implementing test harnesses and infrastructure as necessary</li>  
                        <li>Continuously discover, evaluate, and implement new technologies to maximize development efficiency</li>  

  


                    </ul>
                    <h5 class="qt-color-light qt-font-bold uk-text-uppercase">Minimum qualifications:</h5> 
                    <ul class="uk-list uk-list-bullet">
                        <li>1-2 years of relevant work experience, including development and/or test automation experience or equivalent training or certified in the software testing.</li>
                        <li>Coding experience in Java or Python</li>
                        <li>Extensive knowledge of Linux environments</li>  
                        <li>Sound knowledge of test methodologies, writing test plans, creating test cases, and debugging</li>   
                    </ul>
                    <p>
                       <strong>CANDIDATES FROM ERNAKULAM/KOCHI only preferred.</strong>

                        <br>
                        <br>
                        Please include Job title as a Subject of mail while sending resumes. Kindly mail your updated profile to HR Manager along with the current, expected CTC and notice period details.
                    </p>
                    <div class="qt-badge-light">
                        <span class="uk-badge">Manual Testing</span>
                        <span class="uk-badge">Selenium</span>
                        <span class="uk-badge">Automation Testing</span>
                        <span class="uk-badge">GIT</span> 
                    </div> 
                </div>
                <hr class="qt-ht-dark">
            </li>
             
            
        </ul>
    </div>
</div> -->


 


<div id="modal-center" class="uk-flex-top" uk-modal>
    <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">

        <button class="uk-modal-close-default" type="button" uk-close></button>

       <h3 class="uk-text-center">Enter Your Contact Number or Email</h3>
       <input type="text" class="contact-form" placeholder="Email or Contact No">
       <p class="uk-text-center"><button class="contact-btn">SUBMIT</button></p>

    </div>
</div>