import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { MetaService } from '../meta.service';
@Component({
  selector: 'app-loqqat-ride-case-study',
  templateUrl: './loqqat-ride-case-study.component.html',
  styleUrls: ['./loqqat-ride-case-study.component.scss']
})
export class LoqqatRideCaseStudyComponent implements OnInit {
  title = 'Employee Transport Management Software Solutions | Qaptive';
  constructor(
    private titleService: Title, 
    private metaService: MetaService,
    private meta: Meta
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.meta.updateTag(
      { name: 'description', content: "Our employee transportation solution offers smooth commutation for the employees by facilitating instant notification, real-time tracking  etc" }
    );
    this.metaService.createCanonicalURL();
  }

}
