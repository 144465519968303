

<div class="qanteen">
<div class="uk-container uk-container-large">
 <a [routerLink]="['/employee-meal-program']" >   
<img src="../../assets/images/q-logo.png"> 
</a>
</div>
</div>

<div class="pr">
    <div class="uk-container uk-container-large">
    <div class="p-card">
        <h1 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom uk-text-center">Privacy and Policy </h1>
        <div class="underline"></div>
        <p class="uk-text-justify">Qanteen respects the privacy of our users. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our mobile application (the “Employee Application”).   Please read this Privacy Policy carefully.  IF YOU DO NOT AGREE WITH THE TERMS OF THIS PRIVACY POLICY, PLEASE DO NOT ACCESS THE APPLICATION. 
        </p>
        <p class="uk-text-justify">We reserve the right to make changes to this Privacy Policy at any time and for any reason.  We will alert you about any changes by updating the “Last updated” date of this Privacy Policy.  You are encouraged to periodically review this Privacy Policy to stay informed of updates. You will be deemed to have been made aware of, will be subject to, and will be deemed to have accepted the changes in any revised Privacy Policy by your continued use of the Application after the date such revised Privacy Policy is posted.</p>
        <p class="uk-text-justify">This Privacy Policy does not apply to the third-party online/mobile store from which you install the Application, which may also collect and use data about you.  We are not responsible for any of the data collected by any such third party. 
        </p>
        <h3>COLLECTION OF YOUR INFORMATION</h3>
        <p class="uk-text-justify">We may collect information about you in a variety of ways.  The information we may collect via the Application depends on the content and materials you use, and includes:  </p>
        <p class="uk-text-justify"><b>Personal Data </b></p>
        <p class="uk-text-justify">For a better experience, while using our Service, we may require you to provide us with certain personally identifiable information (such as your name and email address). The information that we request will be retained by us and used as described in this privacy policy.</p>
    
        <p class="uk-text-justify"><b>Log Data  </b></p>
        <p class="uk-text-justify">Whenever you use our Service, in case of an error in the app we collect data and information (through third party products) on your phone called Log Data. This Log Data may include information such as your device Internet Protocol (“IP”) address, device name, operating system version, the configuration of the app when utilizing our Service, the time and date of your use of the Service, and other statistics.</p>
        <p class="uk-text-justify"><b>Security </b></p>
        <p class="uk-text-justify">We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.</p>
        <p class="uk-text-justify"><b>Geo-Location Information        </b></p>
        <p class="uk-text-justify">We may request access or permission to and track location-based information from your mobile device, either continuously or while you are using the Application, to provide location-based services. If you wish to change our access or permissions, you may do so in your device’s settings.        </p>
        <p class="uk-text-justify"><b>Mobile Device Access </b></p>
        <p class="uk-text-justify">We may request access or permission to certain features from your mobile device, including your mobile device’s [bluetooth, calendar, camera, contacts, microphone, reminders, sensors, SMS messages, social media accounts, storage,] and other features. If you wish to change our access or permissions, you may do so in your device’s settings.</p>
        <p class="uk-text-justify"><b>Mobile Device Data </b></p>
        <p class="uk-text-justify">Device information such as your mobile device ID number, model, and manufacturer, version of your operating system, phone number, country, location, and any other data you choose to provide.</p>
        <p class="uk-text-justify"><b>Links to Other Sites</b></p>
        <p class="uk-text-justify">This Service does not contain links to other sites.</p>
        <br>
        <h3>COLLECTION OF YOUR INFORMATION</h3>
        <p class="uk-text-justify">Having accurate information about you permits us to provide you with a smooth, efficient, and customized experience.  Specifically, we may use information collected about you via the Application to: </p>
        <ul style="list-style-type: decimal;">
            <li>Assist law enforcement and respond to subpoena.</li>
            <li>Compile anonymous statistical data and analysis for use internally or with third parties.</li>
            <li>Create and manage your account.            </li>
            <li>Increase the efficiency and operation of the Application.            </li>
            <li>Monitor and analyze usage and trends to improve your experience with the Application.</li>
            <li>Notify you of updates to the Application.</li>
            <li> Perform other business activities as needed.
            </li>
        </ul>
        <br>
        <h3>DISCLOSURE OF YOUR INFORMATION</h3>
        <p class="uk-text-justify">We may share information we have collected about you in certain situations. Your information may be disclosed as follows: </p>
        <p class="uk-text-justify"><b>By Law or to Protect Rights  </b></p>
        <p class="uk-text-justify">If we believe the release of information about you is necessary to respond to legal process, to investigate or remedy potential violations of our policies, or to protect the rights, property, and safety of others, we may share your information as permitted or required by any applicable law, rule, or regulation.  This includes exchanging information with other entities for fraud protection and credit risk reduction.
        </p>
        <p class="uk-text-justify"><b>Business Partners         </b></p>
        <p class="uk-text-justify">We may share your information with our business partners to offer you certain products, services or promotions. </p>
        <br>
        <h3>DISCLOSURE OF YOUR INFORMATION</h3>
        <p class="uk-text-justify">We use administrative, technical, and physical security measures to help protect your personal information.  While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse.  Any information disclosed online is vulnerable to interception and misuse by unauthorized parties.  Therefore, we cannot guarantee complete security if you provide personal information.
        </p>
    </div>
    </div>
</div>