import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from '../app/home/home.component'; 
import { AboutComponent } from '../app/about/about.component'; 
import { ClinicalmanagementComponent } from '../app/clinicalmanagement/clinicalmanagement.component'; 
import { SoftwareProductDevelopmentComponent } from '../app/software-product-development/software-product-development.component'; 
import { SoftwareServicesComponent } from '../app/software-services/software-services.component'; 
import { CreativeDesignComponent } from '../app/creative-design/creative-design.component'; 
import { DigitalMarketingComponent } from '../app/digital-marketing/digital-marketing.component'; 
import { WebDesignComponent } from '../app/web-design/web-design.component'; 
import { SeoComponent } from '../app/seo/seo.component'; 
import { CloudManagementComponent } from '../app/cloud-management/cloud-management.component'; 
import { ItConsultingComponent } from '../app/it-consulting/it-consulting.component'; 
import { CreativeDesignServicesComponent } from '../app/creative-design-services/creative-design-services.component'; 
import { HealthcareManagementSolutionsComponent } from '../app/healthcare-management-solutions/healthcare-management-solutions.component'; 
import { EducationManagementSolutionsComponent } from '../app/education-management-solutions/education-management-solutions.component'; 
import { FleetManagementSolutionsComponent } from '../app/fleet-management-solutions/fleet-management-solutions.component'; 
import { HospitalityManagementSolutionsComponent } from '../app/hospitality-management-solutions/hospitality-management-solutions.component'; 
import { BudgetManagementSolutionsComponent } from '../app/budget-management-solutions/budget-management-solutions.component'; 
import { LoqqatCaseStudyComponent } from '../app/loqqat-case-study/loqqat-case-study.component'; 
import { PeekayCaseStudyComponent } from '../app/peekay-case-study/peekay-case-study.component'; 
import { LoqqatRideCaseStudyComponent } from '../app/loqqat-ride-case-study/loqqat-ride-case-study.component'; 
import { TownbridgeCaseStudyComponent } from '../app/townbridge-case-study/townbridge-case-study.component'; 
import { VideoAnimationsComponent } from '../app/video-animations/video-animations.component'; 
import { CareersComponent } from '../app/careers/careers.component'; 
import { ProductsComponent } from '../app/products/products.component'; 
import { ContactComponent } from '../app/contact/contact.component'; 
import { WhyQaptiveComponent } from './why-qaptive/why-qaptive.component';
import { LoqqatForceComponent } from './loqqat-force/loqqat-force.component';
import { MealsComponent } from './meals/meals.component';
import { CanteenPrivacyComponent } from './canteen-privacy/canteen-privacy.component';
import { ValetComponent} from './valet/valet.component';
import { QcmComponent} from './qcm/qcm.component';
import { QoneComponent} from './qone/qone.component';
import { WayfindingComponent} from './wayfinding/wayfinding.component';
import { GpsComponent} from './gps/gps.component';
import { QwalletComponent} from './qwallet/qwallet.component';
import { QuickAttendenceComponent } from './quick-attendence/quick-attendence.component';
import { ECommerceComponent } from './e-commerce/e-commerce.component';
import { QuikLaundryComponent } from './quik-laundry/quik-laundry.component';
import { CareerFormComponent } from './career-form/career-form.component';
import { ProfileComponent } from './profile/profile.component';
import { ContactformComponent } from './contactform/contactform.component';
import { QueeComponent } from './quee/quee.component';
import { QdineComponent } from './qdine/qdine.component';

const routes: Routes = [
    {path: '',
    component: HomeComponent, 
    pathMatch: 'full',
  },
  {
    path: 'home',
    component: HomeComponent, 
    pathMatch: 'full',
  },
  {
    path: 'employee-meal-program',
    redirectTo: '/qafe',
    pathMatch: 'full',
  },
  {
    path: 'c-privacy',
    component: CanteenPrivacyComponent, 
    pathMatch: 'full',
  },
  {
    path: 'about',
    component: AboutComponent, 
    pathMatch: 'full',
  },
  {
    path: 'clinicalmanagement',
    component: ClinicalmanagementComponent, 
    pathMatch: 'full',
  },
  {
    path: 'software-product-development',
    component: SoftwareProductDevelopmentComponent, 
    pathMatch: 'full',
  },
  {
    path: 'software-services',
    component: SoftwareServicesComponent, 
    pathMatch: 'full',
  },
  {
    path: 'creative-design',
    component: CreativeDesignComponent, 
    pathMatch: 'full',
  },
  {
    path: 'digital-marketing',
    component: DigitalMarketingComponent, 
    pathMatch: 'full',
  },
  {
    path: 'web-design',
    component: WebDesignComponent, 
    pathMatch: 'full',
  },
  {
    path: 'seo',
    component: SeoComponent, 
    pathMatch: 'full',
  },
  {
    path: 'cloud-management',
    component: CloudManagementComponent, 
    pathMatch: 'full',
  },
  {
    path: 'it-consulting',
    component: ItConsultingComponent, 
    pathMatch: 'full',
  },
  {
    path: 'creative-design-services',
    component: CreativeDesignServicesComponent, 
    pathMatch: 'full',
  },
  {
    path: 'healthcare-management-solutions',
    component: HealthcareManagementSolutionsComponent, 
    pathMatch: 'full',
  },
  {
    path: 'education-management-solutions',
    component: EducationManagementSolutionsComponent, 
    pathMatch: 'full',
  },
  {
    path: 'fleet-management-solutions',
    component: FleetManagementSolutionsComponent, 
    pathMatch: 'full',
  },
  {
    path: 'hospitality-management-solutions',
    component: HospitalityManagementSolutionsComponent, 
    pathMatch: 'full',
  },
  {
    path: 'budget-management-solutions',
    component: BudgetManagementSolutionsComponent, 
    pathMatch: 'full',
  },
  {
    path: 'loqqat-case-study',
    component: LoqqatCaseStudyComponent, 
    pathMatch: 'full',
  },
  {
    path: 'peekay-case-study',
    component: PeekayCaseStudyComponent, 
    pathMatch: 'full',
  },
  {
    path: 'loqqat-ride-case-study',
    component: LoqqatRideCaseStudyComponent, 
    pathMatch: 'full',
  },
  {
    path: 'townbridge-case-study',
    component: TownbridgeCaseStudyComponent, 
    pathMatch: 'full',
  },
  {
    path: 'video-animations',
    component: VideoAnimationsComponent, 
    pathMatch: 'full',
  },
  {
    path: 'careers',
    component: CareersComponent, 
    pathMatch: 'full',
  },
  {
    path: 'products',
    component: ProductsComponent, 
    pathMatch: 'full',
  },
  {
    path: 'contact',
    component: ContactComponent, 
    pathMatch: 'full',
  },
  {
    path: 'why-qaptive',
    component: WhyQaptiveComponent, 
    pathMatch: 'full',
  },
  {
    path: 'loqqat-force',
    component: LoqqatForceComponent, 
    pathMatch: 'full',
  },
  {
    path: 'valet-parking',
    component: ValetComponent, 
    pathMatch: 'full',
  },
  {
    path: 'qcm',
    component: QcmComponent, 
    pathMatch: 'full',
  },
  {
    path: 'q-one',
    component: QoneComponent, 
    pathMatch: 'full',
  },
  {
    path: 'wayfinding',
    component: WayfindingComponent , 
    pathMatch: 'full',
  },
  {
    path: 'loqqat-gps',
    component: GpsComponent , 
    pathMatch: 'full',
  },
  {
    path: 'qafe',
    component: QwalletComponent , 
    pathMatch: 'full',
  },
  {
    path: 'q-wallet',
    component: QwalletComponent , 
    pathMatch: 'full',
  },
  {
    path: 'quick-attendance',
    component: QuickAttendenceComponent , 
    pathMatch: 'full',
  },
  {
    path: 'e-commerce',
    component: ECommerceComponent , 
    pathMatch: 'full',
  },
  {
    path: 'quik-laundry',
    component: QuikLaundryComponent , 
    pathMatch: 'full',
  },
  {
    path: 'career-form',
    component: CareerFormComponent , 
    pathMatch: 'full',
  },
  {
    path: 'profile',
    component: ProfileComponent , 
    pathMatch: 'full',
  },
  {
    path: 'cform',
    component: ContactformComponent , 
    pathMatch: 'full',
  },
  {
    path: 'quee',
    component: QueeComponent , 
    pathMatch: 'full',
  },
  {
    path: 'qdine',
    component: QdineComponent , 
    pathMatch: 'full',
  },
  { path: '**', redirectTo: '/' }
];


@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes,{      
      scrollPositionRestoration: 'top',
      useHash: true
   })
  ],
  exports: [RouterModule]
})
 
export class AppRoutingModule { }

export const routingComponent = [
  HomeComponent, 
  AboutComponent, 
  SoftwareProductDevelopmentComponent, 
  SoftwareServicesComponent, 
  CreativeDesignComponent, 
  DigitalMarketingComponent,
  WebDesignComponent,
  SeoComponent,
  CloudManagementComponent,
  ItConsultingComponent,
  CreativeDesignServicesComponent,
  HealthcareManagementSolutionsComponent,
  EducationManagementSolutionsComponent,
  FleetManagementSolutionsComponent,
  HospitalityManagementSolutionsComponent,
  BudgetManagementSolutionsComponent,
  LoqqatCaseStudyComponent,
  PeekayCaseStudyComponent,
  LoqqatRideCaseStudyComponent,
  TownbridgeCaseStudyComponent,
  VideoAnimationsComponent,
  CareersComponent,
  ProductsComponent,
  ContactComponent,
  WhyQaptiveComponent,
  LoqqatForceComponent,
  ValetComponent,
  QcmComponent,
  QoneComponent,
  WayfindingComponent,
  GpsComponent,
  QwalletComponent,
  QuickAttendenceComponent,
  ECommerceComponent,
  QuikLaundryComponent,
  CareerFormComponent,
  ProfileComponent,
  ContactformComponent,
  QueeComponent,
  QdineComponent
] 