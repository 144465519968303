<div class="uk-position-relative uk-visible-toggle" tabindex="-1" uk-slideshow="ratio: 8:2">
    <ul class="uk-slideshow-items">
        <li>
            <img src="../../assets/images/quee-bg.jpg" alt="Attendance Management Software" uk-cover>
            <div class="uk-position-bottom uk-position-medium uk-text-left uk-light">
                <div class="uk-container uk-container-large">
                    <h1 class="uk-margin-remove qt-font-bold">Quee</h1>
                    <p class="uk-margin-remove qt-color-white uk-visible@m">Your personal assistant for managing appointments
                    </p>
                </div>
            </div>
        </li>
    </ul>
    <div class="uk-light">
        <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slideshow-item="previous"></a>
        <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slideshow-item="next"></a>
    </div>
</div>
 

<!-- <div id="quickatt"></div> -->
<div class="uk-section uk-section-muted qt-bg-white"  >
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left" uk-grid>
            <div>
                <div class="uk-text-left">
                    
                    <h2 class="qt-font-weight-500  uk-margin-remove-top uk-margin-remove-bottom">Take control of your calendar with Quee
                    </h2>
                    <p>Quee is a user-friendly and efficient solution to streamline the appointment scheduling process for the Host and the attendees. The solution will allow the attendees to book, reschedule, and cancel appointments through a responsive web interface, based on the availability and preferences of the Host.
                    </p>
                     
                    <p>The solution will give Host more control and visibility over his or her calendar and help to optimize the use of time and resources. With Quee, you can save time, reduce stress, and improve your productivity and communication.
                    </p>
                    
                </div>
            </div>
            <div> 
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right" style="padding-top: 0;">
                   
                    <div class="uk-padding">
                        <img src="../../assets/images/Quee-img.jpg" alt="HR Management Solutions">
                    </div>
                </div>
           </div>
        </div>
    </div>
</div>
 

 


<div ></div>
<div class="uk-section uk-section-muted">
    <div class="uk-container uk-container-large">
  
   
    
            <div class=" uk-text-left " uk-grid>
               <div>
                <div class="uk-text-left">
                    <h3 class="qt-font-weight-500 uk-margin-remove-top uk-margin-remove-bottom"> Benefits of the App:
                    </h3>
                    <!-- <p><b>Features of the Web Module</b></p> -->
                    
                <ul>
                    <li>Reduce the hassle of coordinating and confirming appointments with attendees who want to meet the Host.</li>

                        <li>Increase the convenience and satisfaction of the attendees by allowing them to book, reschedule, and cancel appointments online at any time</li>
                        
                            <li>Optimize the use of time and resources by preventing double-booking, overbooking, no-shows, and last-minute changes</li>
                        
                                <li> Enhance the communication and collaboration between the host and the attendees through a notes and tasks feature</li>
                        
                                    <li>Improve the visibility and control over the host’s calendar by allowing him or her to set availability and preferences for different types of appointments
                        </li>
                </ul>
                </div>
            </div>
            </div>

            <div class=" uk-text-left " uk-grid>
                <div>
                 <div class="uk-text-left">
                     <h3 class="qt-font-weight-500 uk-margin-remove-top uk-margin-remove-bottom"> Features:
                     </h3>
                     
                 <ul>
                     <li>Set up work and off hours with flexibility </li>
                        <li>Create different types of meetings </li>
                            <li>Booking page that lets attendees see the host’s availability and book appointments. </li>
                                <li>Reschedule and cancel appointments online with a cancellation policy </li>
                                    <li>Generate reports and analytics on appointment data, such as number of appointments by a particular attendee. </li>
                 </ul>
                 </div>
             </div>
     
     
             </div>
           

    </div>
</div>
 
 
 
 
 