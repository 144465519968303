<div class="uk-position-relative uk-visible-toggle" tabindex="-1" uk-slideshow="ratio: 8:2">
    <ul class="uk-slideshow-items">
        <li>
            <img src="../../assets/images/qcm-bnr.jpg" alt="Attendance Management Software" uk-cover>
            <div class="uk-position-bottom uk-position-medium uk-text-left uk-light">
                <div class="uk-container uk-container-large">
                    <h1 class="uk-margin-remove qt-font-bold">QCM</h1>
                    <p class="uk-margin-remove qt-color-white uk-visible@m" style="font-size: 1.2rem;">Harness Efficiency: HR Software and Top-Tier Employee Attendance Management Solutions
                    </p>
                </div>
            </div>
        </li>
    </ul>
    <div class="uk-light">
        <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slideshow-item="previous"></a>
        <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slideshow-item="next"></a>
    </div>
</div>
 

<!-- <div id="quickatt"></div> -->
<div class="uk-section uk-section-muted qt-bg-white"  >
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left" uk-grid>
            <div>
                <div class="uk-text-left">
                    
                    <h1 class="qt-font-weight-500  uk-margin-remove-top uk-margin-remove-bottom">The HR Management solution designed for success
                    </h1>
                    <p>QCM is an HR Management solution with a comprehensive suite of tools for HR professionals. It includes a web and mobile application to make it incredibly easy to manage and streamline HR processes. This solution comes with tools to manage employee data, reduce administrative overheads, and elevate the overall efficiency of HR management.
                    </p>
                     
                    <p>The components of QCM have been designed to be easy to use and customizable so that they can be tailored to meet the requirements of your organisation. The goal is to ensure that QCM is adopted and integrated seamlessly with existing systems in order to achieve a seamless transition.
                    </p>
                    
                </div>
            </div>
            <div> 
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right" style="padding-top: 0;">
                   
                    <div class="uk-padding">
                        <img src="../../assets/images/qcm2.jpg" alt="HR Management Solutions">
                    </div>
                </div>
           </div>
        </div>
    </div>
</div>
 

 


<div ></div>
<div class="uk-section uk-section-muted">
    <div class="uk-container uk-container-large">
  
   
    
            <div class=" uk-text-left " uk-grid>
               <div>
                <div class="uk-text-left">
                    <h3 class="qt-font-weight-500 uk-margin-remove-top uk-margin-remove-bottom"> Experience the power of efficient HR management with QCM
                    </h3>
                    <p><b>Features of the Web Module</b></p>
                    
                <ul>
                    <li>Employee Database Management: Easily sort and manage employee data like personal details, leave information, department and designation, etc.</li>
                    <li>Performance Analysis: Conduct precise performance analysis by setting goals and tracking your team’s performance</li>
                    <li>Reports and Analytics: Make informed decisions by gaining valuable insights with the help of accurate and comprehensive reports</li>
                    <li>Leave Management: Track and manage leave requests, approvals, and balances without manual intervention
                    </li>
                    <li>Attendance Management: Track the working hours of both on-site and off-site employees precisely and make payroll calculation easier</li>
                    <li>Supports multiple organisations: Monitor and optimize the HR processes of all your organisations from one place</li>
                </ul>
                </div>
            </div>
            </div>

            <div class=" uk-text-left " uk-grid>
                <div>
                 <div class="uk-text-left">
                     <h3 class="qt-font-weight-500 uk-margin-remove-top uk-margin-remove-bottom"> Ensure transparency and build trust among employees
                     </h3>
                     <p><b>Features of the Mobile Application</b></p>
                     
                 <ul>
                     <li>Employee self-service: Employees can view their personal information, project details, leave balances, etc., minimising the load on the HR department
                    </li>
                     <li>Attendance and Leaves: Employees can punch in and out, request leaves, and track their working hours</li>
                     <li>Reports and Analytics: Make informed decisions by gaining valuable insights with the help of accurate and comprehensive reports</li>
                     <li>Performance feedback: Feedback can be received and provided by the employees about their performance goals and objectives, improving employee-manager communication.
                     </li>
 
                 </ul>
                 </div>
             </div>
     
     
             </div>
           

    </div>
</div>
 
 
 
<app-contactform></app-contactform>