 

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, FormGroupDirective, Validators, NgForm } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ErrorStateMatcher } from '@angular/material/core';
import { CareersService } from '../shared/service/careers.service';
import { Router } from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import { environment } from '../../environments/environment';
import { formatDate} from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);

    return (invalidCtrl || invalidParent);
  }
}

@Component({
  selector: 'app-career-form',
  templateUrl: './career-form.component.html',
  styleUrls: ['./career-form.component.scss']
})
export class CareerFormComponent implements OnInit {
  title = 'Careers | Top Software Company in India';
  now = new Date();
  nowFormatted: string;
  public careerFormGroup: FormGroup;
  name: string;
  email: string;
  phone: string;
  position: string;
  experience: string;
  resume: any;
  constructor(private http: HttpClient, private careersservice: CareersService, private router: Router, private _formBuilder: FormBuilder, private _snackBar: MatSnackBar,
    private titleService: Title, 
    private metaService: Meta,
    private meta: Meta
    ) { 
    this.nowFormatted = formatDate(this.now, 'dd-MM-yyyy', 'en-US');
  }
  
  get f(){
    return this.careerFormGroup.controls;
  }

  hideloader() {
    document.getElementById('loading').style.display = 'none';
    document.getElementById('submit-button').style.display = 'block';
  }
  onloader() {
    document.getElementById('submit-button').style.display = 'none';
    document.getElementById('loading').style.display = 'block';
  }
     
  onFileChange(event) {
  
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.careerFormGroup.patchValue({
        fileSource: file
      });
    }
  }
     
  onSubmit(form){  
    this.onloader()       
    const formData = new FormData();
    formData.append('name', form.value.name); 
    formData.append('email', form.value.email); 
    formData.append('phone', form.value.phone); 
    formData.append('position', form.value.position); 
    formData.append('experience', form.value.experience); 
    formData.append('resume', this.careerFormGroup.get('fileSource').value);   
    this.http.post(environment.apiPhpUrl + '/career_record', formData)
      .subscribe((data: any) => { 
        //console.log(res);
        // form.value['file_name'] = res['data'];
        // form.value['apiPhpUrl'] = environment.apiPhpUrl;       
        // this.careersservice.sendCareerdetails(form.value).subscribe((data: any) => { 
        //     //console.log(data);                
        //     //this.router.navigate(['/careers']);
        //     this.hideloader(); 
        //     document.getElementById('afterLoadingText').style.display = 'block'; 
        //     setTimeout(()=>{                           // <<<---using ()=> syntax
        //       document.getElementById('afterLoadingText').style.display = 'none'; 
        //     }, 3000);
        //     this.openSnackBar('Applied successfully', 'Dismiss');
        //     this.careerFormGroup = new FormGroup({
        //       name: new FormControl('', [Validators.required]),
        //       email: new FormControl('', [Validators.required, Validators.email, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]),
        //       // phone: new FormControl('', [Validators.required, Validators.pattern(MOBILE_PATTERN)]),
        //       phone: new FormControl('', [Validators.required]),
        //       position: new FormControl('', [Validators.required]),
        //       experience: new FormControl('', [Validators.required]),
        //       file: new FormControl('', [Validators.required]),
        //       fileSource: new FormControl()
        //     });        
        // });

        if(data.status_code == 200){

          this.hideloader(); 
          document.getElementById('afterLoadingText').style.display = 'block'; 
          setTimeout(()=>{                           
            document.getElementById('afterLoadingText').style.display = 'none'; 
          }, 4000);
          this.openSnackBar('Applied successfully', 'Dismiss');
          this.careerFormGroup = new FormGroup({
            name: new FormControl('', [Validators.required]),
            email: new FormControl('', [Validators.required, Validators.email, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]),
            // phone: new FormControl('', [Validators.required, Validators.pattern(MOBILE_PATTERN)]),
            phone: new FormControl('', [Validators.required]),
            position: new FormControl('', [Validators.required]),
            experience: new FormControl('', [Validators.required]),
            file: new FormControl('', [Validators.required]),
            fileSource: new FormControl()
          }); 

        } else {

          this.hideloader(); 
          document.getElementById('afterLoadingTextError').style.display = 'block'; 
          setTimeout(()=>{                           
            document.getElementById('afterLoadingTextError').style.display = 'none'; 
          }, 4000);
          this.openSnackBar('Sorry, Please do after sometime', 'Dismiss');
        }
       
      })
  }
  
  openSnackBar(message, action) {
    this._snackBar.open(message, action, { duration: 5000 });
  }

  

  ngOnInit(): void {
    this.hideloader();
    this.careerFormGroup = new FormGroup({
      name: new FormControl('', [Validators.required]),
      // email: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]),
      phone: new FormControl('', [Validators.required]),
      position: new FormControl('', [Validators.required]),
      experience: new FormControl('', [Validators.required]),
      file: new FormControl('', [Validators.required]),
      fileSource: new FormControl()
    });
    this.titleService.setTitle(this.title);
    this.meta.updateTag(
      { name: 'description', content: "We are one of the best IT companies in Kochi, Kerala. We have multiple career opportunities in our organization. Contact us for more details." }
    );
  }

  public hasError = (controlName: string, errorName: string) =>{
    return this.careerFormGroup.controls[controlName].hasError(errorName);
  }

}
