import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { MetaService } from '../meta.service';
@Component({
  selector: 'app-loqqat-case-study',
  templateUrl: './loqqat-case-study.component.html',
  styleUrls: ['./loqqat-case-study.component.scss']
})
export class LoqqatCaseStudyComponent implements OnInit {
  title = 'School Bus Tracking Software Solutions in India | Qaptive';
  constructor(
    private titleService: Title, 
    private metaService: MetaService,
    private meta: Meta
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.meta.updateTag(
      { name: 'description', content: "Our innovative mobile application for school bus tracking offers precise real-time monitoring, student attendance management, and instant notifications. " }
    );
    this.metaService.createCanonicalURL();
  }

}
