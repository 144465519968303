<div class="uk-position-relative uk-visible-toggle" tabindex="-1" uk-slideshow="ratio: 8:2">
    <ul class="uk-slideshow-items">
        <li>
            <img src="../../assets/images/peekay_case_study_1.jpg" alt="Healthcare Management Solutions" uk-cover>
            <div class="uk-position-bottom uk-position-medium uk-text-left uk-light">
                <div class="uk-container uk-container-large">
                    <h1 class="uk-margin-remove qt-font-bold">Empowering Healthcare Management Solution</h1>
                    <p class="uk-margin-remove qt-color-white uk-visible@m">Healthcare</p>
                </div>
            </div>
        </li>
    </ul>
    <div class="uk-light">
        <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slideshow-item="previous"></a>
        <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slideshow-item="next"></a>
    </div>
</div>

<div class="uk-section uk-section-muted">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left">
                    <h5 class="qt-color-dark qt-font-bold uk-text-uppercase">Empower quality healthcare and ensure cost-effective outcomes</h5>
                    <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Business Problem</h2>
                </div>
            </div>
            <div> 
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right">
                    <p> Due to the rocketing needs of the healthcare industry, traditional clinic management are not sufficient. Connected devices that leverage PEEKAY eyecare's management through planning, facilitation, care coordination, evaluation, and services to meet an individual's and family's comprehensive health needs. With the world of increased leading-edge technology comes a new way of thinking, monitoring, and recording the data using smart devices. As our technology changes, it is critical to be able to handle the increasing amount of data, health care assessments, and manage it in real-time. Our goal was to empower quality healthcare and ensure cost-effective outcomes.
                    </p>
                </div>
           </div>
        </div>
    </div>
</div>

<div class="uk-section uk-section-muted uk-padding-remove-top uk-visible@m">
    <div class="uk-container uk-container-large">
        <div class="uk-height-large uk-background-cover uk-light uk-flex" uk-parallax="bgy: -200" style="background-image: url('../../assets/images/peekay_case_study_2.jpg');"> 
        </div> 
    </div>
</div>

<div class="uk-section uk-section-muted uk-padding uk-padding-remove-top uk-padding-remove-left uk-padding-remove-right uk-hidden@m">
    <div class="uk-container uk-container-large">
        <div><img src="../../assets/images/peekay_case_study_2_mob.jpg"></div>
        <!-- uk-visible@m -->
    </div>
</div>

<div class="uk-section uk-section-muted uk-padding-remove-top">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left">
                    <h5 class="qt-color-dark qt-font-bold uk-text-uppercase">End-to-End integration of healthcare solution</h5>
                    <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Solution</h2>
                </div>
            </div>
            <div> 
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right">
                    <p> End-to-End <a href="https://www.qaptive.co.in/healthcare-management-solutions ">healthcare solution</a> to integrating processes, people, and equipment to gain higher patient satisfaction and boosts treatment. Our solutions facilitate patient portal, appointment scheduling, outpatient management, e-prescribing, enterprise content management, and medical billing, automated invoice generation, automated reminders to patients. Leveraging PEEKAY eyecare content helps assure that health care workers and patients are following, updated, guidance to support accurate clinical interventions.
                    </p>
                </div>
           </div>
        </div>
    </div>
</div> 
 

<div class="uk-section uk-section-muted uk-padding-remove-top uk-padding-remove-bottom uk-visible@m">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-center" uk-grid>
            <div>
                <div class="uk-height-large uk-background-cover uk-light uk-flex" uk-parallax="bgy: -200" style="background-image: url('../../assets/images/peekay_case_study_3.jpg');">
                </div>
            </div>
            <div>
                <div class="uk-height-large uk-background-cover uk-light uk-flex" uk-parallax="bgy: -200" style="background-image: url('../../assets/images/peekay_case_study_4.jpg');">
                </div>
            </div>
        </div>
    </div>
</div>
   
<div class="uk-section uk-section-muted uk-padding-remove-top uk-padding-remove-bottom uk-hidden@m">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-center" uk-grid>
            <div>
                <img src="../../assets/images/peekay_case_study_3_mob.jpg">
            </div>
            <div>
                <img src="../../assets/images/peekay_case_study_4_mob.jpg">
            </div>
        </div>
    </div>
</div>
   

 
<div class="uk-section uk-section-muted uk-padding uk-padding-remove-left uk-padding-remove-right">
    <div class="uk-container uk-container-large">
        <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Outcomes</h2>
    </div>
</div>

    
<div class="uk-section uk-section-muted uk-padding-remove-top">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left"> 
                    <h4 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Improved oversight through reporting capabilities</h4>
                    <p>The reporting features of the website provided unique clientele insights into daily reviewer activity, inpatient admission review summaries, it further increased their cost savings.
                    </p>
                </div>
            </div>
            <div>
                <div class="uk-text-left"> 
                    <h4 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Improves overall compliance</h4>
                    <p>It accelerated the functioning of various medical departments within a healthcare organization. It helped in the easy functioning of healthcare departments under a unified system.
                    </p>
                </div>
            </div>
            <div>
                <div class="uk-text-left"> 
                    <h4 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Enhanced collaboration</h4>
                    <p>This solution significantly increased collaboration between hospital physicians and the health plan to find areas of agreement for inpatient stays and in peer-to-peer discussions.
                    </p>
                </div>
            </div>
            <div>
                <div class="uk-text-left"> 
                    <h4 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Increases quality and volume of hospital reviews</h4>
                    <p>This solution reported their concurrent review of healthcare workers and was able to achieve an increase in completed work volume. Concurrent reviews were completed with improved accuracy and overall efficiency. It also helped in raising the productivity of health care workers.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
 

<div class="uk-section uk-section-muted uk-padding-remove-top uk-visible@m">
    <div class="uk-container uk-container-large">
        <div class="uk-height-large uk-background-cover uk-light uk-flex" uk-parallax="bgy: -200" style="background-image: url('../../assets/images/peekay_case_study_5.jpg');"> 
        </div> 
    </div>
</div> 


<div class="uk-section uk-section-muted uk-padding uk-padding-remove-top uk-padding-remove-left uk-padding-remove-right uk-hidden@m">
    <div class="uk-container uk-container-large">
        <div><img src="../../assets/images/peekay_case_study_5_mob.jpg"></div>
        <!-- uk-visible@m -->
    </div>
</div>

<div class="uk-section uk-section-muted uk-padding-remove-top">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left">
                    <h5 class="qt-color-dark qt-font-bold uk-text-uppercase">Peekay Eyecare hospital</h5>
                    <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">About the Client</h2>
                </div>
            </div>
            <div> 
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right"> 
                    <p>The PEEKAY eyecare is one of the best eye care hospital situated in Thalassery,  Kerala. Peekay, specialized in eye care, works with employees, partners, physicians, and other health carers to promote wellness; develop reliable, affordable health plans, and support patients with clear, thoughtful guidance.
                    </p>
                </div>
           </div>
        </div>
    </div>
</div>
