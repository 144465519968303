import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { MetaService } from '../meta.service';
@Component({
  selector: 'app-fleet-management-solutions',
  templateUrl: './fleet-management-solutions.component.html',
  styleUrls: ['./fleet-management-solutions.component.scss']
})
export class FleetManagementSolutionsComponent implements OnInit {
  title = 'Real-Time Fleet Management System in India | Qaptive';
  constructor(
    private titleService: Title, 
    private metaService: MetaService,
    private meta: Meta
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.meta.updateTag(
      { name: 'description', content: "Optimize your fleet operations with our fleet management system in India. Streamline your fleet management with advanced fleet management software." }
    );
    this.metaService.createCanonicalURL();
  }

}
