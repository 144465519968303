 
 

<div class="uk-section uk-section-muted">
    <div class="uk-container uk-container-large profile-bg">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
             <div class="pro1">
                <div>
                <img src="../../assets/images/loqqat_ride_case_study_5_mob.jpg" width="200px">
                </div>
                <div>
                    <h2>Sangeetha Hariharan</h2>
                    <p><span uk-icon="mail"></span> <small> sangeetha123@gmail.com</small></p>
                    <p><span uk-icon="receiver"></span> <small> 871 4444 1110</small></p>
                    <p><span uk-icon="calendar"></span> <small> August 30, 1992</small></p>
                    <p><span uk-icon="home"></span> <small> 2445 bassel Street, Hammond, LA 704010</small></p>
                    <button>Edit</button>
                </div>
             </div>
            </div>
            <div> 
            <p class="uk-text-right"><span style="font-weight: 100">Total Year of Experience:</span> 5 Years  </p>
            <p class="uk-text-right"><span style="font-weight: 100">Current Salary:</span> 25000/-  </p>
            <p class="uk-text-right"><span style="font-weight: 100">Expected Salary:</span>  35000/- </p>
            <p class="uk-text-right"><span style="font-weight: 100">Notice Period:</span>  2 months  </p>

                

                
           </div>
        </div>

        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            
            <div class="profile-table"> 
             <table style="width: 100%;">
                <tr class="tbl-head">
                    <th>Applied Position </th>
                    <th>Status</th>
                    <th>Last Update</th>
                    <th>Action</th>
                </tr>
                <tr>
                    <td>Software Tester</td>
                    <td class="uk-text-primary">Screening</td>
                    <td>May 32, 2023</td>
                    <td><a class="action-btn" href="#modal-center" uk-toggle>Software Tester</a></td>
                </tr>
                <tr>
                    <td>Software Engineer</td>
                    <td class="uk-text-danger">Rijected</td>
                    <td>March 05, 2023</td>
                    <td><a class="action-btn" href="#modal-center" uk-toggle>Know More Details</a></td>
                </tr>
             </table>   
           </div>
        </div>
    </div>
</div>
 

 
 

<div id="modal-center" class="uk-flex-top" uk-modal>
    <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical pro-pop">

        <button class="uk-modal-close-default" type="button" uk-close></button>

        <p><b>Applied position:</b> Software Engineer</p>
        <p><b>Applied Date:</b> Feb 20, 2023</p>
<hr>

        <table>
            <tr class="tbl-head">
                <th>Date</th>
                <th>Stage</th>
                <th>Status</th>
            </tr>
            <tr>
                <td>Feb 20, 2023</td>
                <td>Application Received</td>
                <td class="uk-text-success">Verified</td>
            </tr>
            <tr>
                <td>Feb 23, 2023</td>
                <td>Screening</td>
                <td class="uk-text-success">Pass</td>
            </tr>
            <tr>
                <td>Feb 27, 2023</td>
                <td>Technica Round</td>
                <td class="uk-text-success">Pass</td>
            </tr>
            <tr>
                <td>March 05, 2023</td>
                <td>Machine Test</td>
                <td class="uk-text-danger">Fail</td>
            </tr>
        </table>

    </div>
</div>