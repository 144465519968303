<div class="uk-position-relative uk-visible-toggle" tabindex="-1" uk-slideshow="ratio: 8:2">
    <ul class="uk-slideshow-items">
        <li>
            <img src="../../assets/images/loqqat_case_study_1.jpg" alt="Loqqat" uk-cover>
            <div class="uk-position-bottom uk-position-medium uk-text-left uk-light">
                <div class="uk-container uk-container-large">
                    <h1 class="uk-margin-remove qt-font-bold">School Bus Tracking and Student Ridership</h1>
                    <p class="uk-margin-remove qt-color-white uk-visible@m">Education</p>
                </div>
            </div>
        </li>
    </ul>
    <div class="uk-light">
        <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slideshow-item="previous"></a>
        <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slideshow-item="next"></a>
    </div>
</div>

<div class="uk-section uk-section-muted">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left">
                    <h5 class="qt-color-dark qt-font-bold uk-text-uppercase">To enhance the school transport system to the next level</h5>
                    <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Business Problem</h2>
                </div>
            </div>
            <div> 
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right">
                    <p> The brief received was the schools wanted to ensure that the vehicles of any educational institution are scheduled, managed and further, any changes had to be monitored in real-time that is accessible to parents as well as management. Our objective was to allow companies to improve the efficiency of transportation, productivity, and thereby minimize the risks associated with vehicle investment and transportation in business.

 <br>
 <br>
                        The typical school bus management system is not sufficient, as the parents are not informed about the daily commute of children to schools. The advanced solution for school bus tracking system that helps to enhance the school transport system to the next level by providing precise location and accurate time of each bus to both parents and management at the same time.
                        
                    </p>
                </div>
           </div>
        </div>
    </div>
</div>
 
<div class="uk-section uk-section-muted uk-padding-remove-top uk-visible@m">
    <div class="uk-container uk-container-large">
        <div class="uk-height-large uk-background-cover uk-light uk-flex" uk-parallax="bgy: -200" style="background-image: url('../../assets/images/loqqat_case_study_2.jpg');"> 
        </div> 
    </div>
</div> 

<div class="uk-section uk-section-muted uk-padding uk-padding-remove-top uk-padding-remove-left uk-padding-remove-right uk-hidden@m">
    <div class="uk-container uk-container-large">
        <div><img src="../../assets/images/loqqat_case_study_2_mob.jpg"></div>
        <!-- uk-visible@m -->
    </div>
</div>

<div class="uk-section uk-section-muted uk-padding-remove-top">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left">
                    <h5 class="qt-color-dark qt-font-bold uk-text-uppercase">Safety-focused school bus tracking solution</h5>
                    <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Solution</h2>
                </div>
            </div>
            <div> 
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right">
                    <p> Our ideal solution provides a safety-focused school bus tracking solution. This school <a href="https://www.qaptive.co.in/fleet-management-solutions">fleet management solution</a> uses enterprise Google map API services for the precision of location and accuracy of time. Our solution is capable of intelligent route management, automated trip generations, eminent Adhoc trip management, live monitoring exclusively for parents, instant notification, emergency management, a mobile application for both Android and iOS, and best in class user experience.

                    </p>
                </div>
           </div>
        </div>
    </div>
</div>  

<div class="uk-section uk-section-muted uk-padding-remove-top uk-padding-remove-bottom uk-visible@m">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-center" uk-grid>
            <div>
                <div class="uk-height-large uk-background-cover uk-light uk-flex" uk-parallax="bgy: -200" style="background-image: url('../../assets/images/loqqat_case_study_3.jpg');">
                </div>
            </div>
            <div>
                <div class="uk-height-large uk-background-cover uk-light uk-flex" uk-parallax="bgy: -200" style="background-image: url('../../assets/images/loqqat_case_study_4.jpg');">
                </div>
            </div>
        </div>
    </div>
</div>
 

<div class="uk-section uk-section-muted uk-padding-remove-top uk-padding-remove-bottom uk-hidden@m">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-center" uk-grid>
            <div>
                <img src="../../assets/images/loqqat_case_study_3_mob.jpg">
            </div>
            <div>
                <img src="../../assets/images/loqqat_case_study_4_mob.jpg">
            </div>
        </div>
    </div>
</div>

 
 
<div class="uk-section uk-section-muted uk-padding uk-padding-remove-left uk-padding-remove-right">
    <div class="uk-container uk-container-large">
        <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Outcomes</h2>
    </div>
</div>
    
<div class="uk-section uk-section-muted uk-padding-remove-top">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left"> 
                    <h4 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Productivity</h4>
                    <p>Our solution provides ultra-accurate GPS positioning from the moment the bus starts moving. It increased the productivity of the organization and students. It enabled students to have a stress-free commute to schools.
                    </p>
                </div>
            </div>
            <div>
                <div class="uk-text-left"> 
                    <h4 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Fuel management</h4>
                    <p>It manages fuel consumption within the fleet. This helps to reduce the cost of fuel. All the fuel information such as reports on consumption, purchase, and transactions are available using this solution. Start saving with us.
                    </p>
                </div>
            </div>
            <div>
                <div class="uk-text-left"> 
                    <h4 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Geofencing</h4>
                    <p>It provides different notifications on the vehicle's whereabouts: time of vehicle departure when it leaves pre-defined boundaries or operates during off-duty hours etc.
                    </p>
                </div>
            </div>
            <div>
                <div class="uk-text-left"> 
                    <h4 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Increased student safety</h4>
                    <p>Our solution helped to increase student safety by providing a communication channel between the transport team and the parents.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
 
<div class="uk-section uk-section-muted uk-padding-remove-top uk-visible@m">
    <div class="uk-container uk-container-large">
        <div class="uk-height-large uk-background-cover uk-light uk-flex" uk-parallax="bgy: -200" style="background-image: url('../../assets/images/loqqat_case_study_5.jpg');"> 
        </div> 
    </div>
</div> 
<div class="uk-section uk-section-muted uk-padding uk-padding-remove-top uk-padding-remove-left uk-padding-remove-right uk-hidden@m">
    <div class="uk-container uk-container-large">
        <div><img src="../../assets/images/loqqat_case_study_5_mob.jpg"></div>
        <!-- uk-visible@m -->
    </div>
</div>

<div class="uk-section uk-section-muted uk-padding-remove-top">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left">
                    <h5 class="qt-color-dark qt-font-bold uk-text-uppercase">Web-based school fleet management software
                    </h5>
                    <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">About the Client</h2>
                </div>
            </div>
            <div> 
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right"> 
                    <p>Our client is one of the leading schools in Doha, Qatar since 2004 providing the best value premium education. They have created a new chapter in their educational philosophy with the initiation of the new teaching methodology to improve the learning experience for students. 
                    </p>
                </div>
           </div>
        </div>
    </div>
</div>
