<div class="uk-position-relative uk-visible-toggle" tabindex="-1" uk-slideshow="ratio: 8:2">
    <ul class="uk-slideshow-items">
        <li>
            <img src="../../assets/images/fleet_management_1.jpg" alt="Fleet Management" uk-cover>
            <div class="uk-position-bottom uk-position-medium uk-text-left uk-light">
                <div class="uk-container uk-container-large">
                    <h1 class="uk-margin-remove qt-font-bold">Products</h1>
                    <p class="uk-margin-remove qt-color-white uk-visible@m">It all starts with an idea and a desire to <br>create human-shaped experiences for our customers.
                    </p>
                </div>
            </div>
        </li>
    </ul>
    <div class="uk-light">
        <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slideshow-item="previous"></a>
        <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slideshow-item="next"></a>
    </div>
</div>
 

<!-- <div id="quickatt"></div> -->
<!-- <div class="uk-section uk-section-muted qt-bg-white" id="quickatt">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left">
                    <h5 class="qt-color-dark qt-font-bold uk-text-uppercase">Comprehensive Classroom attendance management tool
                    </h5>
                    <h1 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Quick Attendance
                    </h1>
                    <p>It is important for educational institutions to optimize the procedures involved in daily activities to improve existing standards consistently. In this context, Qaptive Technologies is introducing technology tools for helping the educational institutions to consistently raise the bar in terms of efficiency and innovation. “Quick Attendance” by Qaptive is a powerful technology product for educational institutions to increase efficiency in the attendance marking procedures.
                    </p>
                    <ul class="uk-list">
                        <li>Attendance capturing with smart cards.
                        </li> 
                        <li>Cost effective devices for classrooms.</li> 
                        <li>Powerful mobile apps for teachers.</li>  
                        <li>Comprehensive web application of Quick Attendance will provide all options for administrators to configure and control the attendance management. Apart from it, the senior leadership team will be able to review the statistics and trends of attendance through the dashboard and reports.
                        </li>  
                    </ul>
                    <a class="uk-button uk-button-secondary qt-btn qt-bg-dark qt-color-white qt-btn-hover uk-margin" target="_blank" href="https://www.loqqat.com/top-school-bus-gps-tracking-systems">Know More</a>
                </div>
            </div>
            <div> 
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right">
                   
                    <div class="uk-padding">
                        <img src="../../assets/images/quick_attendance.png">
                    </div>
                </div>
           </div>
        </div>
    </div>
</div> -->
 
<div id="quickatt" #qwallet></div>
<div class="uk-section uk-section-muted">
    <div class="uk-container uk-container-large">
        <!-- <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div> 
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right">
                    <div>
                        <img src="../../assets/images/q-wallet.png">
                    </div>
                </div>
           </div>

            <div>
                <div class="uk-text-left">
                     
                    <h1 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Q-wallet</h1>
                    <h4 class="qt-font-weight-500 uk-margin  uk-margin-remove-bottom">Easy To Customize</h4>
                    <p>Using our click-to-pay and scan-to-pay payment system, customers experience fast and easy shopping from any device.</p>
                    <hr>
                    <h4 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Awesome Design</h4>
                    <p>Wallet UI design is user friendly, quick process, fast transaction cryptocurrencies and adjust the fee value.</p>
                    <hr>
                    <h4 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Easy To Use</h4>
                    <p>Start accepting cryptocurrencies instantly with easy to use payment buttons, embeddable forms, checkout pages and custom integrations.</p>
                    <hr>
                    <h4 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">About Q - Wallet</h4>
                    <p>Using our click-to-pay and scan-to-pay payment system, customers experience fast and easy shopping from any device. Our responsive checkout system perfectly fits to any screen from smartphones and supports all major languages to accommodate global customers.</p>
                    <hr>
                </div>
            </div>
        </div> -->
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div> 
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right">
                   
                    <div class="uk-padding">
                        <img src="../../assets/images/quick_attendance.png">
                    </div>
                </div>
           </div>
            <div>
                <div class="uk-text-left">
                    <h5 class="qt-color-dark qt-font-bold uk-text-uppercase">Comprehensive Classroom attendance management tool
                    </h5>
                    <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Quick Attendance
                    </h2>
                    <p>It is important for educational institutions to optimize the procedures involved in daily activities to consistently improve existing standards. In this context, Qaptive Technologies has introduced tools for helping educational institutions to consistently raise the bar in terms of efficiency and innovation. Quick Attendance by Qaptive is a powerful technology product for educational institutions to increase efficiency in campus attendance marking procedures.
                    </p>
                    <p>The web application of Quick Attendance will provide all options for administrators to configure and control campus attendance management. Apart from that, the senior leadership team will be able to review the statistics and trends of attendance through the dashboard and reports.
                    </p>
                    <!-- <ul class="uk-list">
                        <li>Attendance capturing with smart cards.
                        </li> 
                        <li>Cost effective devices for classrooms.</li> 
                        <li>Powerful mobile apps for teachers.</li>  
                        <li>Comprehensive web application of Quick Attendance will provide all options for administrators to configure and control the attendance management. Apart from it, the senior leadership team will be able to review the statistics and trends of attendance through the dashboard and reports.
                        </li>  
                    </ul> -->
                    <a class="uk-button uk-button-secondary qt-btn qt-bg-dark qt-color-white qt-btn-hover uk-margin" target="_blank" href="https://www.loqqat.com/campus-attendance-system">Know More</a>
                </div>
            </div>
          
        </div>
    </div>
</div>
 
 
 
<!-- <div id="loqqat"></div> -->
<div class="uk-section uk-section-muted qt-bg-white" id="loqqat">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left">
                    <h5 class="qt-color-dark qt-font-bold uk-text-uppercase">School Bus Tracking</h5>
                    <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Loqqat</h2>
                    <p>Loqqat is an advanced and easy to use platform developed with great expertise for live tracking school buses during their commute. It provides the school transport team as well as parents the real-time visibility of students during the school bus transit.
                        <br>
                        <br>
                        It greatly helps to easily organize and manage the tedious process of school transport management and tracking. By using a smooth and smart hi-tech management system and an advanced web interface to control and access the whole school fleet, the complete process gets so simple. It also gives the school transport team an edge to design optimal routes, auto-generate trips and assign them to buses in the predefined time window.                        
                        </p>
                        <a class="uk-button uk-button-secondary qt-btn qt-bg-dark qt-color-white qt-btn-hover uk-margin" target="_blank" href="https://www.loqqat.com/">Know More</a>
                </div>
            </div>
            <div> 
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right">
                    <div>
                        <img src="../../assets/images/loqqat_bg.jpg">
                    </div>
                </div>
           </div>
        </div>
    </div>
</div>


 
<!-- <div id="loqqatride"></div> -->
<div class="uk-section uk-section-muted " id="loqqatride">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div> 
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right">
                    <div>
                        <img src="../../assets/images/banner_ride_1.png">
                    </div>
                </div>
           </div>

            <div>
                <div class="uk-text-left">
                    <h5 class="qt-color-dark qt-font-bold uk-text-uppercase">Easy And Smart
                        Commute To Work</h5>
                    <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Loqqat Ride</h2>
                    <p>Implementing methodical employee transport management technology can help any organization to establish smooth commutation for its human resources. Being an efficient bus tracking solution, Loqqat Ride offers various dimensions of features to ensure predictable and specific transport service for employees. 
                        <br>
                        <br>
                        Commuting to the workplace is one of the least favourite and challenging parts of a working day for the majority of employees. Loqqat Ride transforms this hectic transit to a plain sailing and seamless experience to its users.
                    </p>
                    <a class="uk-button uk-button-secondary qt-btn qt-bg-dark qt-color-white qt-btn-hover uk-margin" target="_blank" href="https://www.loqqatride.com/">Know More</a>
                </div>
            </div>
        </div>
    </div>
</div>

 
<!-- <div id="loqqat"></div> -->
<div class="uk-section uk-section-muted qt-bg-white" id="lforce">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left">
                    <h5 class="qt-color-dark qt-font-bold uk-text-uppercase">Attendance management for the workforce on the move</h5>
                    <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Loqqat Force</h2>
                    <p>Moving the workforce from site to site and assigning them to different projects in a day will help to quickly deliver projects. Loqqat Force is an innovative solution to optimally capture the work duration of the moving workforce. 
                        <br>
                        <br>
                        LoqqatForce is an easy to use technology solution to measure the exact hours spent on travel and at each site by the workforce. The exact work hours will be then pushed to the compensation systems and project accounting applications. 
                        <br>
                        <br>
                        LoqqatForce is an NFC enabled solution which captures unmanipulatable attendance as the employee boards the vehicle and when gets dropped at site. Drivers are equipped with easy to use mobile applications which capture the in-time and out-time of the workforce at each site as well as the duration of travel. 
                        <br>
                        <br>
                        It is important to measure and calculate the exact work hours of the workforce at each site and LoqqatForce does that heavy lifting by technology for project owners. 
                                              
                        </p>
                        <a _ngcontent-tyv-c106=""  [routerLink]="['/loqqat-force']" class="uk-button uk-button-secondary qt-btn qt-bg-dark qt-color-white qt-btn-hover uk-margin">Know More</a>
                </div>
            </div>
            <div> 
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right">
                    <div>
                        <img src="../../assets/images/banner_force_1.png">
                    </div>
                </div>
           </div>
        </div>
    </div>
</div>

<!-- <div id="ecommerce"></div> -->
<div class="uk-section uk-section-muted" id="ecommerce">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div> 
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right">
                    <div>
                        <img src="../../assets/images/ecom_bg_1.png">
                    </div>
                </div>
           </div>
            <div>
                <div class="uk-text-left">
                    <h5 class="qt-color-dark qt-font-bold uk-text-uppercase">Agile and Scalable Ecommerce Platform</h5>
                    <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">E-commerce</h2>
                    <p>Qaptive specialises in providing a new e-commerce experience that is not limited to just the buying and selling of goods or services using the internet. We believe that e-commerce cannot be defined as just an online store; it is a comprehensive ecosystem of various units that can streamline the way businesses function. Our solutions are superior in that they help firms integrate multiple platforms in their e-commerce strategy.
                        <br>
                        <br>
                        The e-commerce solutions Qaptive delivers are equipped with industry-leading features that can help you stay ahead of the curve. A few of these features include Shopping Cart, Mobile Applications, Security, Social Media Integration, Powerful Search, Up-Selling and Cross-Selling, Integrated Shipping, Deals and Rewards, Gift Certificates and Wishlists, as well as Online Chat.
                        <br>
                        
                        
                    </p>
                    <a _ngcontent-tyv-c106=""  [routerLink]="['/e-commerce']" class="uk-button uk-button-secondary qt-btn qt-bg-dark qt-color-white qt-btn-hover uk-margin">Know More</a>
                     
                </div>
            </div>
            
        </div>
    </div>
</div>
 

 
<!-- <div id="loqqat"></div> -->
<div class="uk-section uk-section-muted qt-bg-white" id="quik">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left">
                    <h5 class="qt-color-dark qt-font-bold uk-text-uppercase">Laundry management made seamless and efficient</h5>
                    <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Quik Laundry</h2>
                    <p>Quik Laundry is an intelligent tracking and managing solution for laundry services
                        that aims to make the whole laundry process more streamlined and hassle-free.
                        Hospitals, hotels, service apartments, guest homes, residential schools and other
                        businesses may utilise this platform to automate and fine-tune their laundry
                        management systems, ensuring quick deliveries and a systematic and effective
                        laundry service.
                        <br>
                        <br>
                        This platform makes it very easy for laundry managers or operation heads to
supervise and handle their laundry services in a way that increases its overall
efficiency and productivity. Thus, it increases the visibility of the entire process
right from picking up the laundry to delivering it. It also looks after the needs of
the guests to ensure them a very comfortable and smooth laundry service.            
                        </p>
                        
                        
                        <a  [routerLink]="['/quik-laundry']" class="uk-button uk-button-secondary qt-btn qt-bg-dark qt-color-white qt-btn-hover uk-margin">Know More</a>
                </div>
            </div>
            <div> 
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right">
                    <div>
                        <img src="../../assets/images/laundry.jpg">
                    </div>
                </div>
           </div>
        </div>
 
 
    </div>
</div>

<div class="uk-section uk-section-muted" id="qanteen">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div> 
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right">
                    <div>
                        <img src="../../assets/images/melas.png">
                    </div>
                </div>
           </div>

            <div>
                <div class="uk-text-left">
                    <h5 class="qt-color-dark qt-font-bold uk-text-uppercase"> </h5>
                    <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Qanteen</h2>
                    <p>A state-of-the-art application to manage your canteen services smoothly and efficiently. With our meal management application, the administration will be able to conveniently handle the daily operational activities of a canteen. They will be able to create, edit and publish the Meal Menus in real time. These menus can be curated on the basis of time slots using several parameters like meal type (Breakfast, Lunch, Dinner), Beverages and many other customizable parameters.
<br>
<br>
<p>       The employees can select their desired dishes through a POS device installed at a counter or a self-service kiosk. The input generated will be instantly relayed to the management, and the canteen staff can then serve the employee with the food order. This drastically reduces the time that is required for ordering food and streamlines the whole process of food distribution. A limit can also be set for the amount of food that an employee can order. This will curtail any food wastage and will provide for a more optimal distribution of food. The application consists of two components- Admin Dashboard and Mobile App.</p>           
                        
                    
                    <a _ngcontent-tyv-c106=""  [routerLink]="['/employee-meal-program']" class="uk-button uk-button-secondary qt-btn qt-bg-dark qt-color-white qt-btn-hover uk-margin">Know More</a>
                </div>
            </div>
           
        </div>
    </div>
</div>
 

<!-- <div class="uk-section uk-section-muted qt-bg-white" id="loqqaforce">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
           
            <div> 
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right">
                    <div>
                        <img src="../../assets/images/gps.png">
                    </div>
                </div>
           </div>

           <div>
            <div class="uk-text-left">
                <h5 class="qt-color-dark qt-font-bold uk-text-uppercase">Attendance management for the workforce on the move</h5>
                <h1 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Loqqat GPS</h1>
                <p>Moving the workforce from site to site and assigning them to different projects in a day will help to quickly deliver projects. Loqqat Force is an innovative solution to optimally capture the work duration of the moving workforce. 
                    <br>
                    <br>
                    LoqqatForce is an easy to use technology solution to measure the exact hours spent on travel and at each site by the workforce. The exact work hours will be then pushed to the compensation systems and project accounting applications. 
                    <br>
                    <br>
                    LoqqatForce is an NFC enabled solution which captures unmanipulatable attendance as the employee boards the vehicle and when gets dropped at site. Drivers are equipped with easy to use mobile applications which capture the in-time and out-time of the workforce at each site as well as the duration of travel. 
                    <br>
                    <br>
                    It is important to measure and calculate the exact work hours of the workforce at each site and LoqqatForce does that heavy lifting by technology for project owners. 
                                          
                    </p>
            </div>
        </div>

        </div>
    </div>
</div> -->

<div class="uk-section" id="qwallet">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left">
                    <h5 class="qt-color-dark qt-font-bold uk-text-uppercase">A smart Canteen App for school students</h5>
                    <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom"> Qafe </h2>
                    <p>Experience a novel way to manage and supervise the entire operations of a school canteen. With an easy-to-use UI and smart features, we bring you Qafe.
<br>
<br>
<p>      A state-of-the-art application to manage your canteen services smoothly and efficiently. With our meal management application, the administration will be able to conveniently handle the daily operational activities of a canteen. They will be able to create, edit and publish the Meal Menus in real time. Students can simply use their smart cards to order and pay for their food without using any cash. These smart cards can be recharged with cash which are then redeemed by the students for making their canteen purchases.
</p>           
                        
                    
                    <a _ngcontent-tyv-c106=""  [routerLink]="['/qafe']" class="uk-button uk-button-secondary qt-btn qt-bg-dark qt-color-white qt-btn-hover uk-margin">Know More</a>
                </div>
            </div>
            <div> 
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right">
                    <div>
                        <img src="../../assets/images/QAFE3.png">
                    </div>
                </div>
           </div>
        </div>
    </div>
</div>
 

<div class="uk-section  uk-section-muted" id="reido">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
           
            <div> 
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right">
                    <div>
                        <img src="../../assets/images/reido.png">
                    </div>
                </div>
           </div>

           <div>
            <div class="uk-text-left">
                <h5 class="qt-color-dark qt-font-bold uk-text-uppercase">Elevate your shuttle services to the next level</h5>
                <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Reido</h2>
                <p>Reido is a user-friendly and robust application that makes
                    managing your passenger transport services easy and effective. It has a
                    cutting-edge dashboard with features that will multiply the efficiency of your
                    transport operations. Reido is a comprehensive employee shuttle management
                    solution that will enhance the productivity and effectiveness of your shuttle
                    services with features like fleet optimization, live tracking, ad hoc trips,
                    geofencing, material movement, and many more.
                    <br>
                    <br>
                    It provides you with end-to-end support for your
                    transportation requirements, including your passenger’s mobility needs and your
                    organisation’s material movements. This is achieved through an integrated
                    network of components, which are the Admin Panel (Web), Central Team (Web,
                    Android), Driver App (Android) and the Passenger App (Android, iOS).
                    <br>
                    <br>
                                       
                    </p>
                    <a _ngcontent-tyv-c106=""  href="https://site.reido.qaptive.in/" class="uk-button uk-button-secondary qt-btn qt-bg-dark qt-color-white qt-btn-hover uk-margin">Know More</a>
                    <!-- <a class="uk-button uk-button-secondary qt-btn qt-bg-dark qt-color-white qt-btn-hover uk-margin" target="_blank" href="https://www.loqqat.com/">Know More</a> -->
            </div>
        </div>

        </div>
    </div>
</div>

<div class="uk-section  " id="way">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left">
                    <h5 class="qt-color-dark qt-font-bold uk-text-uppercase"> The Ultimate Wayfinding Application for Digital Navigation</h5>
                    <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Way Finding</h2>
                    <p>The Wayfinder application is a digital navigation tool that offers directional guidance to visitors or guests from various kiosks positioned across a property. It is a reliable mobile and web platform that aims to elevate the level of convenience for guests and visitors at a property.
<br>
<br>
<p>       As a cloud-based solution, it can be scaled up depending on operational needs and is a must-have for large properties such as malls, hospitals, and amusement parks. By assisting guests with the issues they encounter when navigating a property and by informing them about recent updates there, we intend to improve the experience of both new and returning visitors with our application. The management may also get in touch with the visitors to receive their insightful feedback, which will help the establishments uphold their commitments to the visitors.</p>           
                        
                    
                    <a _ngcontent-tyv-c106=""  [routerLink]="['/wayfinding']" class="uk-button uk-button-secondary qt-btn qt-bg-dark qt-color-white qt-btn-hover uk-margin">Know More</a>
                </div>
            </div>
            <div> 
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right">
                    <div>
                        <img src="../../assets/images/way.jpg">
                    </div>
                </div>
           </div>
        </div>
    </div>
</div>


<div class="uk-section uk-section-muted  " id="qone">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
           
            <div> 
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right">
                    <div>
                        <img src="../../assets/images/property.png">
                    </div>
                </div>
           </div>

           <div>
            <div class="uk-text-left">
                <h5 class="qt-color-dark qt-font-bold uk-text-uppercase">Your all-in-one property management solution</h5>
                <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Q-One</h2>
                <p>Q-One is your perfect companion when it comes to managing properties across multiple locations. It has been meticulously designed to overcome a variety of problems that hinder efficient property management.
                    <br>
                    <br>
                    Bring in automation, transparency, and world-class practices with the best-in-class apartment management software. We bring in A-grade security to ensure round-the-clock supervision of all your properties. In the hands of the residents, Q-One also becomes a handy tool for them to make their community living experience more fun and convenient.
                    <br>
                    <br>
                
                    </p>
                    <a _ngcontent-tyv-c106=""  [routerLink]="['/q-one']" class="uk-button uk-button-secondary qt-btn qt-bg-dark qt-color-white qt-btn-hover uk-margin">Know More</a>
            </div>
        </div>

        </div>
    </div>
</div>



<div class="uk-section  " id="qcm">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left">
                    <h5 class="qt-color-dark qt-font-bold uk-text-uppercase">The HR Management solution designed for success </h5>
                    <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">QCM</h2>
                    <p>QCM is an HR Management solution with a comprehensive suite of tools for HR professionals. It includes a web and mobile application to make it incredibly easy to manage and streamline HR processes. This solution comes with tools to manage employee data, reduce administrative overheads, and elevate the overall efficiency of HR management.

<br>
<br>
<p>       The components of QCM have been designed to be easy to use and customizable so that they can be tailored to meet the requirements of your organisation. The goal is to ensure that QCM is adopted and integrated seamlessly with existing systems in order to achieve a seamless transition.
</p>           
                        
                    
                    <a _ngcontent-tyv-c106=""  [routerLink]="['/qcm']" class="uk-button uk-button-secondary qt-btn qt-bg-dark qt-color-white qt-btn-hover uk-margin">Know More</a>
                </div>
            </div>
            <div> 
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right">
                    <div>
                        <img src="../../assets/images/qcm.png">
                    </div>
                </div>
           </div>
        </div>
    </div>
</div>

<div class="uk-section uk-section-muted " id="valet">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
           
            <div> 
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right">
                    <div>
                        <img src="../../assets/images/parking.png">
                    </div>
                </div>
           </div>

           <div>
            <div class="uk-text-left">
                <h5 class="qt-color-dark qt-font-bold uk-text-uppercase">Switch to a smarter Parking Management System</h5>
                <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Valet Parking</h2>
                <p>Valet Parking App is a digital Parking Management solution with an array of advanced features that will make your valet parking services more efficient, enabling you to provide a high-quality guest experience. With the Valet Parking App's digital key management system, you can track the location and status of all vehicles at all times. The app can also keep precise records of all the vehicles parked in the parking lot, along with guest information. With an easy-to-use interface, the application makes the complete process fast and effortless. Also, once the vehicles have been handed over, guests receive an SMS notification.                  
                    </p>
                    <a _ngcontent-tyv-c106=""  [routerLink]="['/valet-parking']" class="uk-button uk-button-secondary qt-btn qt-bg-dark qt-color-white qt-btn-hover uk-margin">Know More</a>
                    <!-- <a class="uk-button uk-button-secondary qt-btn qt-bg-dark qt-color-white qt-btn-hover uk-margin" target="_blank" href="https://www.loqqat.com/">Know More</a> -->
            </div>
        </div>

        </div>
    </div>
</div>

<div class="uk-section  " id="quee">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left">
                    <h5 class="qt-color-dark qt-font-bold uk-text-uppercase">Your personal assistant for managing appointments</h5>
                    <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom"> Quee </h2>
                    <p>Quee is a user-friendly and efficient solution to streamline the appointment scheduling process for the Host and the attendees. The solution will allow the attendees to book, reschedule, and cancel appointments through a responsive web interface, based on the availability and preferences of the Host.

<br>
<br>
<p>       The solution will give Host more control and visibility over his or her calendar and help to optimize the use of time and resources. With Quee, you can save time, reduce stress, and improve your productivity and communication.
</p>           
                        
                    
                    <a _ngcontent-tyv-c106=""  [routerLink]="['/quee']" class="uk-button uk-button-secondary qt-btn qt-bg-dark qt-color-white qt-btn-hover uk-margin">Know More</a>
                </div>
            </div>
            <div> 
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right">
                    <div>
                        <img src="../../assets/images/quee.jpg">
                    </div>
                </div>
           </div>
        </div>
    </div>
</div>