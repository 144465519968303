import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { MetaService } from '../meta.service';
@Component({
  selector: 'app-townbridge-case-study',
  templateUrl: './townbridge-case-study.component.html',
  styleUrls: ['./townbridge-case-study.component.scss']
})
export class TownbridgeCaseStudyComponent implements OnInit {
  title = 'Web Solution for Hospitality Sector | Hospitality Management';
  constructor(
    private titleService: Title, 
    private metaService: MetaService,
    private meta: Meta
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.meta.updateTag(
      { name: 'description', content: "Our solution for the hospitality management system enhances business operations, revenue, and reputation by optimally using superior technology tools." }
    );
    this.metaService.createCanonicalURL();
  }

}
