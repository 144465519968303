<div class="uk-position-relative uk-visible-toggle" tabindex="-1" uk-slideshow="ratio: 8:2">
    <ul class="uk-slideshow-items">
        <li>
            <img src="../../assets/images/meals-bnr.jpg" alt="Employee Meal Program" uk-cover>
            <div class="uk-position-bottom uk-position-medium uk-text-left uk-light">
                <div class="uk-container uk-container-large">
                    <h1 class="uk-margin-remove qt-font-bold">Qanteen</h1>
                    <p class="uk-margin-remove qt-color-white uk-visible@m">The complete solution to manage your daily canteen operations
                    </p>
                </div>
            </div>
        </li>
    </ul>
    <div class="uk-light">
        <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slideshow-item="previous"></a>
        <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slideshow-item="next"></a>
    </div>
</div>
 

<!-- <div id="quickatt"></div> -->
<div class="uk-section uk-section-muted qt-bg-white"  >
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left">
                    
                    <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Enhanced visibility of meal distribution and consumption patterns
                    </h2>
                    <p>A state-of-the-art application to manage your canteen services smoothly and efficiently. With our meal management application, the administration will be able to conveniently handle the daily operational activities of a canteen. They will be able to create, edit and publish the Meal Menus in real time. These menus can be curated on the basis of time slots using several parameters like meal type (Breakfast, Lunch, Dinner), Beverages and many other customizable parameters. </p>
                     
                    <p>The employees can select their desired dishes through a POS device installed at a counter or a self-service kiosk. The input generated will be instantly relayed to the management, and the canteen staff can then serve the employee with the food order. This drastically reduces the time that is required for ordering food and streamlines the whole process of food distribution. A limit can also be set for the amount of food that an employee can order. This will curtail any food wastage and will provide for a more optimal distribution of food. The application consists of two components- Admin Dashboard and Mobile App.</p>
                    <!-- <a [routerLink]="['/employee-meal-program']" >  <button class="privacy-btn">Privacy & Policy</button></a> -->
                    <!-- <a [routerLink]="['/c-privacy']" class="uk-button uk-button-secondary qt-btn qt-bg-light qt-color-black qt-btn-hover">Privacy & Policy</a> -->
                </div>
            </div>
            <div> 
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right" style="padding-top: 0;">
                   
                    <div class="uk-padding">
                        <img src="../../assets/images/meals-abt.jpg" alt="Canteen Management App for Employees">
                    </div>
                </div>
           </div>
        </div>
    </div>
</div>
 

<div class="uk-section uk-section-muted qt-bg-white"   style="padding-top: 0;">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
           
            <div> 
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right" style="padding-top: 0;">
                   
                    <div class="uk-padding" style="padding-top: 0;">
                        <img src="../../assets/images/tab.png" alt="Canteen Management Software for Employee">
                    </div>
                </div>
           </div>

           <div>
            <div class="uk-text-left">
                 
                <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom"> Feature rich Dashboard for efficient supervision
                </h2>
                <p>The Admin Dashboard is highly intuitive and offers a plethora of advanced features that can help the administration to monitor and supervise their canteen operations effectively. Data on canteen attendance, meal distribution, meal consumption pattern etc. can be captured and stored for auditing purposes. With a wide range of reports, the admin team gets increased visibility of food distribution and employee consumption patterns, among other things. Overall, the Meal management application elevates the performance of canteens by upgrading food distribution and enables the management to have increased visibility of their canteen operations.

                </p>
            </div>
        </div>


        </div>
    </div>
</div>


<div ></div>
<div class="uk-section uk-section-muted">
    <div class="uk-container uk-container-large">
        <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom" style="text-align:center;">Features</h2>
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div> 
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right" style="padding-top:0;">
                    <div>
                        <h4 class="qt-font-weight-500 uk-margin  uk-margin-remove-bottom">Menu management</h4>
                        <p>Menus can be created under several categories and sub-categories and can be configured on the basis of time slots.   </p>
                        <hr>
                        <h4 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Reduce manual overheads</h4>
                        <p>Meals can be distributed quickly as employees can place the orders directly from the application. </p>
                        <hr>
                        <h4 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Manage canteens across multiple locations                        </h4>
                        <p>Single login from the admin panel to monitor and supervise the activities of all the canteens under your purview.                        </p>
                        <hr>
                        <h4 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Extensive reports   </h4>
                        <p>Detailed analysis of the food distribution pattern based on days, categories, sub-categories, employees etc.                        </p>
                        <hr>   
                    </div>
                </div>
           </div>

            <div>
                <div class="uk-text-left">
                    <!-- <h5 class="qt-color-dark qt-font-bold uk-text-uppercase">Q-wallet</h5> -->
                    <h4 class="qt-font-weight-500 uk-margin  uk-margin-remove-bottom">Automated POS for meal distribution</h4>
                    <p>Avoid waiting time at the counters with a POS device that can hasten the process of ordering food. </p>
                    <hr>
                    <h4 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Optimal distribution of food  </h4>
                    <p>Track meal distribution and cease the wastage of food by allowing for optimised food distribution among the employees.      </p>
                    <hr>
                    <h4 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Analyse the consumption pattern                    </h4>
                    <p>Management can analyse the consumption of an individual item which would help them to take appropriate decisions.                    </p>
                    <hr>
                    <h4 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Pre-order for convenience</h4>
                    <p>Employees can place their orders beforehand for a hassle-free canteen experience.
                    </p>
                    <hr>
                </div>
            </div>
        </div>
    </div>
</div>
 
 
 
 
<app-contactform></app-contactform>