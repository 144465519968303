import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Contact } from './contact.model';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  selectedContact: Contact;
  body: any;
  headers: any;
  operation: string;
  constructor(private http: HttpClient, private router: Router) {
    this.headers = {
      headers: new HttpHeaders({
        'content-type': 'application/json',
        'Access-Control-Allow-Headers': 'x-requested-with',
        'Access-Control-Allow-Methods': 'POST, GET, PUT, OPTIONS, DELETE',
        'Access-Control-Max-Age': '3600'
      })
    };
   }

   contactSend(contact: Contact) {
    
    let httpHeaders = new HttpHeaders()
                         .set('Content-Type', 'application/json');    
    let options = {
      headers: httpHeaders
    }; 
    return this.http.post<Contact>(environment.apiPhpUrl + '/send_contact_mail', contact, options);
  }
  
}
