<div class="uk-position-relative uk-visible-toggle" tabindex="-1" uk-slideshow="ratio: 8:2">
    <ul class="uk-slideshow-items">
        <li>
            <img src="../../assets/images/creative_service_1.jpg" alt="web design" uk-cover>
            <div class="uk-position-bottom uk-position-medium uk-text-left uk-light">
                <div class="uk-container uk-container-large">
                    <h1 class="uk-margin-remove qt-font-bold">Web Design Service</h1>
                    <p class="uk-margin-remove qt-color-white uk-visible@m">Designing result-driven and customer-centric websites</p>
                </div>
            </div>
        </li>
    </ul>
    <div class="uk-light">
        <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slideshow-item="previous"></a>
        <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slideshow-item="next"></a>
    </div>
</div>

<div class="uk-section uk-section-muted">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left">
                    <h5 class="qt-color-dark qt-font-bold uk-text-uppercase">Client-focused website solutions that deliver tangible business results
                    </h5>
                    <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Transform your casual visitors into customers</h2>
                </div>
            </div>
            <div> 
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right">
                    <p>
                        We assure you, to develop a purely customized and eminent world-class design for your website. The design experts precisely convey the main idea of the website and help promote the application among the users. We help to create eye-catching web designs for web applications that are also attractive and fascinating. We ensure to bring exceptional growth to your business enterprise by designing result-driven and customer-centric websites as per our clientele requirements. 
                        <br>
                        <br>
                        We offer a wide versatile range of website design and deployment solutions that are structured to meet our users needs and wants. Our web design services are dedicated to create smoothly- functioning, flawless and visually-pleasing websites that would rank high in the search engine results and would also give out as an exceptional marketing and advertising tool for transforming your casual visitors into customers.
                        
                    </p>
                </div>
           </div>
        </div>
    </div>
</div>

<div class="uk-section uk-section-muted uk-padding-remove-top uk-visible@m">
    <div class="uk-container uk-container-large">
        <div class="uk-height-large uk-background-cover uk-light uk-flex" uk-parallax="bgy: -200" style="background-image: url('../../assets/images/web_creative_2.jpg');"> 
        </div> 
    </div>
</div>
<div class="uk-section uk-section-muted uk-padding uk-padding-remove-top uk-padding-remove-left uk-padding-remove-right uk-hidden@m">
    <div class="uk-container uk-container-large">
        <div><img src="../../assets/images/web_creative_2_mob.jpg"></div>
        <!-- uk-visible@m -->
    </div>
</div>
   
<div class="uk-section uk-section-muted uk-padding-remove-top">
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large" uk-grid>
            <div>
                <div class="uk-text-left">
                    <h5 class="qt-color-dark qt-font-bold uk-text-uppercase">Functionally-perfect creative design</h5>
                    <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Responsive Web Design Services That Encompasses</h2>
                </div>
            </div>
            <div> 
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right">
                    <ul class="uk-list">
                        <li>We provide CMS (content management system) website designing solution that includes functionally-perfect creative design and well-optimized content accompanied by high ranked <a href="https://www.qaptive.co.in/seo ">SEO</a>. We make use of the finest CMS platform such as Magento, WordPress, Joomla, etc.
                        </li>
                        <li>We help you for a seamless experience with digital storefronts. We provide custom e-commerce services and create modern e-commerce website designs, integrating payment gateways. We are experts in e-commerce marketplace development, & shopping cart development.
                        </li>
                        <li>Qaptive’s expert team the bases of website designing requirements, from developing the website layout to bug fixing, to creating graphics. We choose the right design elements to make sure that your website attracts more traffic in search engines.
                        </li>
                        <li>Our responsive web design services ensure that your website is compatible with multiple devices such as desktops, laptops, and mobile phones. We make user-friendly layout designs to boost search rankings & website speed and at scale.
                        </li>
                    </ul>
                    <h4 class="qt-font-bold">Why Our Web Design Services</h4>
                    <ul class="uk-list">
                       <li>Extensive customization capabilities</li>
                        <li>Provides end-to-end solution and services</li>
                        <li>Helps to automate your business needs</li>
                        <li>Innovative, trustworthy web application solution</li>
                        <li>Dynamic and holistic applications</li> 
                    </ul>
                </div>
           </div>
        </div>
    </div>
</div>