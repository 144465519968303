import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { MetaService } from '../meta.service';
@Component({
  selector: 'app-qwallet',
  templateUrl: './qwallet.component.html',
  styleUrls: ['./qwallet.component.scss']
})
export class QwalletComponent implements OnInit {
  title = 'Canteen Management Software System | Qafe';
  constructor(
    private titleService: Title, 
    private metaService: MetaService,
    private meta: Meta
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.meta.updateTag(
      { name: 'description', content: "Efficiently manage canteen operations with our Qafe software. Simplify food ordering, inventory management, and payments. Try it now." }
    );
    this.metaService.createCanonicalURL();
  }
}


 