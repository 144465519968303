<div class="uk-position-relative uk-visible-toggle" tabindex="-1" uk-slideshow="ratio: 8:2">
    <ul class="uk-slideshow-items">
        <li>
            <img src="../../assets/images/way-bnr.jpg" alt="Wayfinding App" uk-cover>
            <div class="uk-position-bottom uk-position-medium uk-text-left uk-light">
                <div class="uk-container uk-container-large">
                    <h1 class="uk-margin-remove qt-font-bold">Wayfinding App
                    </h1>
                    <p class="uk-margin-remove qt-color-white uk-visible@m" style="font-size: 1.2rem;">Effortless Indoor Wayfinding: Navigate Malls and Discover Businesses Easily
                    </p>
                </div>
            </div>
        </li>
    </ul>
    <div class="uk-light">
        <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slideshow-item="previous"></a>
        <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slideshow-item="next"></a>
    </div>
</div>
 

<!-- <div id="quickatt"></div> -->
<div class="uk-section uk-section-muted qt-bg-white"  >
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left" uk-grid>
            <div>
                <div class="uk-text-left">
                    
                    <h2 class="qt-font-weight-500  uk-margin-remove-top uk-margin-remove-bottom">Navigate complex spaces with confidence</h2>
                    <p>The Wayfinder application is a digital navigation tool that offers directional guidance to visitors or guests from various kiosks positioned across a property. It is a reliable mobile and web platform that aims to elevate the level of convenience for guests and visitors at a property.
                    </p>
                     
                    <p>As a cloud-based solution, it can be scaled up depending on operational needs and is a must-have for large properties such as malls, hospitals, and amusement parks. By assisting guests with the issues they encounter when navigating a property and by informing them about recent updates there, we intend to improve the experience of both new and returning visitors with our application. The management may also get in touch with the visitors to receive their insightful feedback, which will help the establishments uphold their commitments to the visitors.</p>
                    
                </div>
            </div>
            <div> 
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right" style="padding-top: 0;">
                   
                    <div class="uk-padding">
                        <img src="../../assets/images/Way-Finding.jpg" alt="Property App">
                    </div>
                </div>
           </div>
        </div>
        <div class="uk-child-width-expand@s uk-text-left" uk-grid>
            <div> 
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right" style="padding-top: 0;">
                   
                    <div class="uk-padding">
                        <img src="../../assets/images/way3.jpg" alt="Property directory app">
                    </div>
                </div>
           </div>
            <div>
                <div class="uk-text-left">
                    
                    <h2 class="qt-font-weight-500  uk-margin-remove-top uk-margin-remove-bottom">Find your way quickly and effortlessly  </h2>
                    <p>Numerous POIs will be marked on the map with navigational instructions from the corresponding kiosk locations. Based on the category that each POI is listed under, visitors will be able to search for different POIs and get to the location without any hassle. The management can even publish news and events that take place on the property using our Content Management Dashboard, helping them reach out to a broader audience. The banners or posters can also be rapidly updated in real-time across several properties from a central dashboard.
                    </p>
                     
                    <p>Using the application, visitors may look up a POI and receive directions to the desired place from their current location. A search of the POI may be done using the property's categories and sectors, which will be displayed in the kiosk. All the contents are controlled centrally via the Admin Dashboard. It allows you to broadcast the contents across several kiosks in real-time and to change the material at any moment. From the dashboard, you can also modify the way that each POI can be navigated.
                    </p>
                    
                </div>
            </div>
           
        </div>
    </div>
</div>
 

 


<div ></div>
<div class="uk-section uk-section-muted">
    <div class="uk-container uk-container-large">
  
        <div class="uk-child-width-expand@s uk-text-left" uk-grid>
            <div>
                <div class="uk-text-left">
                    
                    <h3 class="qt-font-weight-500 uk-margin-remove-top uk-margin-remove-bottom"> Benefits for Guests</h3>
                 
                    
                    <ul>
                        <li>Multilingual</li>
                            <li>Route map within the property</li>
                                <li>No staff intervention required</li>
                                    <li>Mobile app download is not required</li>
                                        <li>Quick access to current active events, news, etc.</li>
                                            <li>Contact information for each of the facilities</li>
                                                <li>Guest feedback form</li>
                            
                    </ul>
                </div>
            </div>
            <div> 
                <div class="uk-padding uk-padding-remove-bottom uk-padding-remove-left uk-padding-remove-right" style="padding-top: 0;">
                   
                    <h3 class="qt-font-weight-500 uk-margin-remove-top uk-margin-remove-bottom"> Benefits for Management
                    </h3>
                     
                    
                <ul>
                    <li>Single Back Office Application for Centralised Control</li>
                   <li>Pre-Schedule Content (Menu) Based on Days/Hours</li>
               <li>Publish news and events</li>
               <li>Manage content instantaneously</li>
               <li>Supreme guest experience</li>
                       
                    

                </ul>
                </div>
           </div>
        </div>
    
           
           

    </div>
    
</div>
 
 
 
 
<app-contactform></app-contactform>