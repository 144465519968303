<div class="uk-position-relative uk-visible-toggle" tabindex="-1"  uk-slideshow="autoplay:true" >
    <!-- uk-slideshow="ratio:7:2.8" -->
    <ul class="uk-slideshow-items qt-uk-slideshow-items">
        <li>
            <img src="../../assets/images/banner_1.jpg" alt="Software Development Company in India" uk-cover>
            <div class="uk-position-top-left uk-position-small qt_banner">
                <h2 class="uk-margin-remove uk-heading-small qt-color-white">Reassure, reinvent, rationalize <br>software services by utilizing strategy,<br> design
                    and technology.
                </h2>
                <p class="uk-margin-remove  qt-color-white">Software services using next-generation technologies for reinventing your<br> business and restructuring for global adaptability
                </p>
                <div class="uk-margin">
                    <a [routerLink]="['/software-services']" class="uk-button uk-button-secondary qt-btn qt-bg-light qt-color-black qt-btn-hover">View More</a>
                </div>
            </div>
        </li>
        <li>
            <img src="../../assets/images/banner_2.jpg" alt=" Product Development Firms " uk-cover>
            <div class="uk-position-top-left uk-position-small qt_banner">
                <h2 class="uk-margin-remove uk-heading-small qt-color-dark">When we say, best-in-class <br>software products, we mean it.
                </h2>
                <p class="uk-margin-remove qt-color-dark">We craft software products with unique business objectives <br>and rollout them to the customer audience to use them in<br> their specific niches.

                </p>
                <div class="uk-margin">
                    <a [routerLink]="['/software-product-development']" class="uk-button uk-button-secondary qt-btn qt-bg-light qt-color-black qt-btn-hover">View More</a>
                </div>
            </div>
        </li>
        <li>
            <img src="../../assets/images/banner_3.jpg" alt="Software product development" uk-cover>
            <div class="uk-position-top-left uk-position-small qt_banner">
                <h2 class="uk-margin-remove uk-heading-small qt-color-dark">Elevated experiences that drive<br> evident business results.</h2>
                <p class="uk-margin-remove qt-color-dark">We work at the intersection of strategy, creativity and technology to <br>support our clientele digitally to reinvent and rebranding their business,<br> which can be small or large enterprises.</p>
                <div class="uk-margin">
                    <a [routerLink]="['/it-consulting']" class="uk-button uk-button-secondary qt-btn qt-bg-light qt-color-black qt-btn-hover">View More</a>
                </div>
            </div>
        </li>
    </ul>
    <div class="uk-light">
        <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slideshow-item="previous"></a>
        <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slideshow-item="next"></a>
    </div>
    <ul class="uk-slideshow-nav uk-dotnav uk-flex-center uk-margin qt_slide_dot"></ul>
</div>
 
<div class="uk-section uk-section-muted" style="background-color: #7D27EE;">
    <div class="uk-container uk-container-xlarge">
        <div class="uk-grid-match uk-grid-small uk-text-left" uk-grid>
             <div class="uk-width-1-5@m">
                <div class="qt-label">
                    <h5 class="qt-color-white qt-font-bold">CORE SERVICES</h5>
                    <h1 class="core qt-color-white uk-margin-remove-top qt-font-weight-500">We provide a new set of eyes, that is a great way to reinvigorate the projects.
                    </h1>
                </div>
            </div>
             <div class="uk-width-1-5@m">
                <div class="uk-card uk-card-default uk-card-body qt-border-radius-20">
                    <p><img class="qt_svg_icon" src="../../assets/images/qt_icons-01.svg"></p>
                    <h3 class="uk-margin-remove qt-font-bold ">
                        Software Product Development
                    </h3>
                    <p class="uk-margin-remove qt-margin-t-10">Articulating the vision, setting the strategic roadmap, and identifying which customer pain points to solve.</p>
                    <a [routerLink]="['/software-product-development']" class="uk-button uk-button-secondary qt-btn qt-bg-dark qt-color-white qt-btn-hover uk-margin">Know More</a>
                </div>
            </div>
             <div class="uk-width-1-5@m">
                <div class="uk-card uk-card-default uk-card-body qt-border-radius-20">
                    <p><img class="qt_svg_icon" src="../../assets/images/qt_icons-02.svg"></p>
                    <h3 class="uk-margin-remove qt-font-bold">
                        Software Services
                    </h3>
                    <p class="uk-margin-remove qt-margin-t-10">Expertise is in building solutions for global businesses. We provide specialized software, that able to unlock customer insights with deeper understanding.

                    </p>
                    <!-- Restructure for global adaptability. -->
                    <a [routerLink]="['/software-services']" class="uk-button uk-button-secondary qt-btn qt-bg-dark qt-color-white qt-btn-hover uk-margin">Know More</a>
                </div>
            </div>
             <div class="uk-width-1-5@m">
                <div class="uk-card uk-card-default uk-card-body qt-border-radius-20">
                    <p><img class="qt_svg_icon" src="../../assets/images/qt_icons-04.svg"></p>
                    <h3 class="uk-margin-remove qt-font-bold">
                        IT Consulting
                    </h3>
                    <p class="uk-margin-remove qt-margin-t-10">We work with the inventive intersection of strategy, creativity, and technology to support our clientele digitally to reinvent and rebrand their business.

                   
                    </p>
                    <a [routerLink]="['/it-consulting']" class="uk-button uk-button-secondary qt-btn qt-bg-dark qt-color-white qt-btn-hover uk-margin">Know More</a>
                </div>
            </div>
             <div class="uk-width-1-5@m">
                <div class="uk-card uk-card-default uk-card-body qt-border-radius-20">
                    <p><img class="qt_svg_icon" src="../../assets/images/qt_icons-03.svg"></p>
                    <h3 class="uk-margin-remove qt-font-bold">
                        Creative Design
                    </h3>
                    <p class="uk-margin-remove qt-margin-t-10">World-class creative design, combining modern graphic designing tools to create unique & subtle graphics that portray the brand’s voice.
                    </p>
                    <a [routerLink]="['/creative-design-services']" class="uk-button uk-button-secondary qt-btn qt-bg-dark qt-color-white qt-btn-hover uk-margin">Know More</a>
                </div>
            </div>
           
        </div>

      
    </div>
</div>
<div class="uk-section uk-section-muted">
    <div class="uk-container uk-container-expand">
        <div class="uk-text-center">
            <h5 class="qt-color-dark qt-font-bold">SOLUTIONS</h5>
            <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top qt-color-black">Expertise is in building <br>intelligent solutions.
            </h2>
        </div>
        <div class="uk-position-relative uk-visible-toggle" tabindex="-1" uk-slider="autoplay: true">
            <ul class="uk-slider-items uk-child-width-1-4@m uk-grid-match uk-grid-small uk-padding uk-padding-remove-right uk-padding-remove-left uk-padding-remove-top" uk-grid>
                <li class="uk-width-1-4@m">
                    <div class="uk-card uk-card-default uk-card-body qt-border-radius-20">
                        <h3 class="uk-margin-remove qt-font-bold qt-color-black">
                            Fleet<br> Management Solutions
                        </h3>
                        <div class="qt-hr"><hr class="uk-divider-small qt-before-color-pink"></div>
                        <p class="uk-margin-remove qt-margin-t-10">Safety and Security of your students/employees is the need of the hour, to establish an easy commute.</p>
                        <div class="uk-margin">
                            <a [routerLink]="['/fleet-management-solutions']" class="uk-button uk-button-secondary qt-btn qt-bg-black">Know More</a>
                        </div>
                        <p class="uk-text-right"><img src="../../assets/images/vec_5.jpg" alt="Software services."></p>
                    </div>
                </li>
                <li class="uk-width-1-4@m">
                    <div class="uk-card uk-card-default uk-card-body qt-border-radius-20">
                        <h3 class="uk-margin-remove qt-font-bold qt-color-black">
                            Hospitality<br> Management Solutions
                        </h3>
                        <div class="qt-hr"><hr class="uk-divider-small qt-before-color-green"></div>
                        <p class="uk-margin-remove qt-margin-t-10">Robust IT solution to develop, manage, and upgrade business models in the hospitality industry.</p>
                        <div class="uk-margin">
                            <a [routerLink]="['/hospitality-management-solutions']" class="uk-button uk-button-secondary qt-btn qt-bg-black">Know More</a>
                        </div>
                        <p class="uk-text-right"><img src="../../assets/images/vec_3.jpg" alt="Best Software Development Agency Kochi"></p>
                    </div>
                </li>
                <li class="uk-width-1-4@m">
                    <div class="uk-card uk-card-default uk-card-body qt-border-radius-20">
                        <h3 class="uk-margin-remove qt-font-bold qt-color-black">
                            Budget <br>Management Solutions
                        </h3>
                        <div class="qt-hr"><hr class="uk-divider-small qt-before-color-orange"></div>
                        <p class="uk-margin-remove qt-margin-t-10">Online visual range to real and authentic presentation in anticipation of preserved budgets.
                        </p> 
                        <div class="uk-margin">
                            <a [routerLink]="['/budget-management-solutions']" class="uk-button uk-button-secondary qt-btn qt-bg-black">Know More</a>
                        </div>
                        <p class="uk-text-right"><img src="../../assets/images/budget.jpg" alt="Best Companies in Infopark"></p>
                    </div>
                </li>
                <li class="uk-width-1-4@m">
                    <div class="uk-card uk-card-default uk-card-body qt-border-radius-20">
                        <h3 class="uk-margin-remove qt-font-bold qt-color-black">
                            Healthcare<br>Management Solutions
                        </h3>
                        <div class="qt-hr"><hr class="uk-divider-small qt-before-color-blue"></div>
                        <p class="uk-margin-remove qt-margin-t-10">End-to-end eminent healthcare solution, to build innovative hospitals integrating, processes and  people.
                        </p>
                        <div class="uk-margin">
                            <a [routerLink]="['/healthcare-management-solutions']" class="uk-button uk-button-secondary qt-btn qt-bg-black">Know More</a>
                        </div>
                        <p class="uk-text-right"><img src="../../assets/images/vec_1.jpg"></p>
                    </div>
                </li>
                <li class="uk-width-1-4@m">
                    <div class="uk-card uk-card-default uk-card-body qt-border-radius-20">
                        <h3 class="uk-margin-remove qt-font-bold qt-color-black">
                            Education <br>Management Solutions
                        </h3>
                        <div class="qt-hr"><hr class="uk-divider-small qt-before-color-yellow"></div>
                        <p class="uk-margin-remove qt-margin-t-10">A complete system to manage and implement all small to big tasks related to all types of educational needs.
                        </p>
                        <div class="uk-margin">
                            <a [routerLink]="['/education-management-solutions']" class="uk-button uk-button-secondary qt-btn qt-bg-black">Know More</a>
                        </div>
                        <p class="uk-text-right"><img src="../../assets/images/vec_2.jpg" alt="Software Development"></p>
                    </div>
                </li>
                
            </ul>
            <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slider-item="previous"></a>
            <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slider-item="next"></a>
        </div>
    </div>
</div>

<div class="uk-section uk-section-muted uk-padding-remove-top">
    <div class="uk-container uk-container-expand">
        <div class="uk-text-center">
            <h5 class="qt-color-dark qt-font-bold">CASE STUDIES</h5>
            <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top qt-color-black">We Equip Businesses For  <br>Disruptive Growth
            </h2>
        </div>
        <div class="uk-position-relative uk-visible-toggle uk-light" tabindex="-1" uk-slider="autoplay: true">
            <ul class="uk-slider-items uk-child-width-1-1 uk-child-width-1-4@m uk-grid">
                <li> 
                    <a [routerLink]="['/loqqat-case-study']">
                        <div class="uk-panel qt-overflow-hidden qt-border-radius-20 uk-inline-clip uk-transition-toggle">
                            <img class="uk-transition-scale-up uk-transition-opaque" src="../../assets/images/slide_1.jpg" alt="top software development companies in India">
                            <div class="uk-position-center uk-panel qt-position-left-bottom"> 
                                <h3 class="uk-margin-remove qt-font-bold">School Bus Tracking & Student Ridership</h3>
                            </div>
                        </div> 
                    </a>
                </li>
                <li>
                    <a [routerLink]="['/peekay-case-study']">
                        <div class="uk-panel qt-overflow-hidden qt-border-radius-20 uk-inline-clip uk-transition-toggle">
                            <img class="uk-transition-scale-up uk-transition-opaque"  src="../../assets/images/slide_2.jpg" alt="Software Product Development Company in India">
                            <div class="uk-position-center uk-panel qt-position-left-bottom">
                                <!-- <p class="uk-margin-remove qt-color-white">PEEKAY EYE CARE</p> -->
                                <h3 class="uk-margin-remove qt-font-bold">Empowering Healthcare Management Solution</h3>
                            </div>
                        </div>
                    </a>
                </li>
                <li>
                    <a [routerLink]="['/loqqat-ride-case-study']">
                        <div class="uk-panel qt-overflow-hidden qt-border-radius-20 uk-inline-clip uk-transition-toggle">
                            <img class="uk-transition-scale-up uk-transition-opaque"  src="../../assets/images/slide_3.jpg" alt="best software outsourcing company in Kerala">
                            <div class="uk-position-center uk-panel qt-position-left-bottom">
                                <!-- <p class="uk-margin-remove qt-color-white">LOQQAT RIDE</p> -->
                                <h3 class="uk-margin-remove qt-font-bold">Employee <br>Ridership Solution</h3>
                            </div>
                        </div>
                    </a>
                </li>
                <li>
                    <a [routerLink]="['/townbridge-case-study']">
                        <div class="uk-panel qt-overflow-hidden qt-border-radius-20 uk-inline-clip uk-transition-toggle">
                            <img class="uk-transition-scale-up uk-transition-opaque"  src="../../assets/images/slide_4.jpg" alt="Software Service Companies">
                            <div class="uk-position-center uk-panel qt-position-left-bottom">
                                <!-- <p class="uk-margin-remove qt-color-white">TOWNBRIDGE</p> -->
                                <h3 class="uk-margin-remove qt-font-bold">Web Solution for the Hospitality Sector</h3>
                            </div>
                        </div>
                    </a>
                </li> 
            </ul>
            <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slider-item="previous"></a>
            <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slider-item="next"></a>
        </div>
    </div>
</div>


<div class="uk-section uk-section-muted uk-padding-remove-top" style="padding-bottom:0;">
    <div class="uk-container uk-container-small">
        <div class="uk-text-center">
            <h5 class="qt-color-dark qt-font-bold uk-text-uppercase">INSIGHTS</h5>
            <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom">Elevated experiences that drive <br>evident business results.</h2>
             <p>Our Software products are not just engineering excellence but backed with a solid product roadmap to ensure that all short-term and long-term objectives are planned from the day the product vision is formulated.</p>
        </div>
    </div>
    <div class="uk-container uk-container-large">
        <div class="uk-child-width-expand@s uk-text-left uk-margin-large uk-padding-large" uk-grid>
            <div>
                <div><img src="../../assets/images/workflow.png" alt="best IT software organization in Kerala"></div>
            </div>
            <div>
                <div class="uk-card-body uk-padding">
                    <ol class="numbered">
                        <li>
                            <h3 class="uk-margin-remove uk-margin-remove qt-font-bold">Design and development of web application and corporate systems</h3>
                            <p class="uk-margin-remove qt-margin-t-10">Design and develop leading web applications and the corporate solution used by enterprises to automate tasks and provide access to resources of cloud technologies. Web-based solutions assist business enterprises to increase productivity, facilitate workflow management, and boost their bottom line.
                            </p>
                        </li> 
                        <li>
                            <h3 class="uk-margin-remove uk-margin-remove qt-font-bold">Delivering business intelligence tools</h3>
                            <p class="uk-margin-remove qt-margin-t-10">We provide specialized software to unlock customer insights and acquisition concerns. We built business intelligence tools that help our clients implement data-driven decision making.
                            </p>
                        </li> 
                        <li>
                            <h3 class="uk-margin-remove uk-margin-remove qt-font-bold">IT consulting</h3>
                            <p class="uk-margin-remove qt-margin-t-10">We provide our clients, a solution for managing their software systems, infrastructure, and custom solutions. Our primary goal of IT consulting is to take the business to the next level by optimizing analytics, process, and workflows with specialized software solutions.

                            </p>
                        </li>  
                      </ol>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="uk-section uk-section-muted uk-padding-remove-top qt-bottom-blue-wrapper uk-position-relative">
    <div class="uk-container uk-container-xlarge uk-position-relative" style="z-index: 10;">
        <div class="uk-text-center">
            <h5 class="qt-color-dark qt-font-bold">TESTIMONIALS</h5>
            <h2 class="qt-font-weight-500 uk-margin-large uk-margin-remove-top uk-margin-remove-bottom qt-color-black">From some of our <br>happy clientele
            </h2>
                <p>Partner with us to define your strategy, create remarkable <br>experiences and build your business.</p>
        </div>
        <div class="uk-position-relative uk-visible-toggle uk-margin-large" tabindex="-1" uk-slider="autoplay: true">
            <ul class="uk-slider-items uk-child-width-1-4@m uk-grid-match uk-grid-small uk-padding uk-padding-remove-right uk-padding-remove-left uk-padding-remove-top" uk-grid>
                <li class="uk-width-1-4@m">
                    <div class="uk-card uk-card-default uk-card-body qt-border-radius-20">
                        <p class="uk-margin-remove qt-margin-t-10">“I really appreciate and conveying my gratitude to Loqqat Ride. It enhanced our strategies for employee transport in our organization. Now, it’s very easy for our employees to manage their commute to workplace.” </p>
                        <div class="uk-card-header uk-padding uk-padding-remove-left uk-padding-remove-right uk-padding-remove-bottom"  style="border:none;">
                            <div class="uk-grid-small uk-flex-middle uk-position-relative" uk-grid>
                                <div class="uk-width-auto">
                                    <img class="uk-border-circle" width="40" height="40" src="../../assets/images/avatar-1.jpg">
                                </div>
                                <div class="uk-width-expand">
                                    <h3 class="uk-margin-remove-bottom">Cory</h3>
                                    <p class="uk-text-meta uk-margin-remove-top"><time datetime="2016-04-01T19:00">President of leading shuttle services company</time></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="uk-width-1-4@m">
                    <div class="uk-card uk-card-default uk-card-body qt-border-radius-20">
                        <p class="uk-margin-remove qt-margin-t-10">“Our school transport is supported by Loqqat. Earlier, our students had lots of difficulties during their commute. With the help of Loqqat it really helped our students for a trouble-free commute to school.”</p>
                        <div class="uk-card-header uk-padding uk-padding-remove-left uk-padding-remove-right uk-padding-remove-bottom"  style="border:none;">
                            <div class="uk-grid-small uk-flex-middle uk-position-relative" uk-grid>
                                <div class="uk-width-auto">
                                    <img class="uk-border-circle" width="40" height="40" src="../../assets/images/avatar-1.jpg">
                                </div>
                                <div class="uk-width-expand">
                                    <h3 class="uk-margin-remove-bottom">Farid</h3>
                                    <p class="uk-text-meta uk-margin-remove-top"><time datetime="2016-04-01T19:00">Principal of leading school in Oman</time></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="uk-width-1-4@m">
                    <div class="uk-card uk-card-default uk-card-body qt-border-radius-20">
                        <p class="uk-margin-remove qt-margin-t-10">“I think the solution your team have developed for our TOWNBRIDGE is exceptional. I would be happy to discuss other projects we are looking at in the hospitality sector where I’m sure you can help us.” </p>
                        <div class="uk-card-header uk-padding uk-padding-remove-left uk-padding-remove-right uk-padding-remove-bottom"  style="border:none;">
                            <div class="uk-grid-small uk-flex-middle uk-position-relative" uk-grid>
                                <div class="uk-width-auto">
                                    <img class="uk-border-circle" width="40" height="40" src="../../assets/images/avatar-1.jpg">
                                </div>
                                <div class="uk-width-expand">
                                    <h3 class="uk-margin-remove-bottom">Sameer</h3>
                                    <p class="uk-text-meta uk-margin-remove-top"><time datetime="2016-04-01T19:00">Founder and Managing Director of Town Bridge Hotels</time></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="uk-width-1-4@m">
                    <div class="uk-card uk-card-default uk-card-body qt-border-radius-20">
                        <p class="uk-margin-remove qt-margin-t-10">“Qaptive is our go-to-partner, has played a pivotal role in our PEEKAY eyecare’s overall innovative health management strategy. It enabled our hospital to run in a very professional way with tremendous advantages.” </p>
                        <div class="uk-card-header uk-padding uk-padding-remove-left uk-padding-remove-right uk-padding-remove-bottom"  style="border:none;">
                            <div class="uk-grid-small uk-flex-middle uk-position-relative" uk-grid>
                                <div class="uk-width-auto">
                                    <img class="uk-border-circle" width="40" height="40" src="../../assets/images/avatar-1.jpg">
                                </div>
                                <div class="uk-width-expand">
                                    <h3 class="uk-margin-remove-bottom">Nidhina</h3>
                                    <p class="uk-text-meta uk-margin-remove-top"><time datetime="2016-04-01T19:00">Head of Hospital Administration, Peekay Eye Care
                                    </time></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
            <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slider-item="previous"></a>
            <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slider-item="next"></a>
        </div>
    </div>
</div>
 
<div class=" uk-background-cover uk-position-relative" uk-parallax="bgy: -200" style="background-image: url('../../assets/images/bg_experts.jpg');">
    <div class="uk-overlay-primary uk-position-cover" style="z-index: 1; opacity: 0.4;"></div>
    <div class="uk-container uk-container-xlarge uk-text-left uk-padding-large  uk-position-relative"  style="z-index: 21;">
        <h2 class="qt-color-white">Sleek. Superlative. Flair</h2>
        <p class="qt-font-400 qt-color-white uk-margin uk-margin-remove-top">We ensure to deliver value-added finest solutions and thereby helping<br>business enterprises to capitalize growth endeavors
            </p>
        <div class="qt-tab">
            <ul uk-tab data-uk-switcher="{connect:'#my-id'}">
                <li class="uk-active"><a class="uk-text-capitalize qt-font-weight-500" href="">Creative Design</a></li>
                <li><a class="uk-text-capitalize qt-font-weight-500" href="">Digital Marketing</a></li> 
                <li><a class="uk-text-capitalize qt-font-weight-500" href="">Web Design</a></li> 
                <li><a class="uk-text-capitalize qt-font-weight-500" href="">SEO</a></li> 
                <li><a class="uk-text-capitalize qt-font-weight-500" href="">Video Animations</a></li> 
            </ul>
            <!-- This is the container of the content items -->
            <ul id="my-id" class="uk-switcher uk-margin-medium uk-margin-remove-bottom uk-margin-remove-left uk-margin-remove-right">
                <li class="uk-animation-fade">
                    <div class="uk-card uk-card-default uk-grid-collapse uk-child-width-1-2@s uk-margin" uk-grid>
                        <div class="uk-flex-last@s uk-card-media-right uk-cover-container uk-padding uk-text-right">
                            <img src="../../assets/images/white_papers.jpg" alt="IT Consulting Services in India " uk-contain> 
                        </div>
                        <div>
                            <div class="uk-card-body uk-padding-large">
                                <h3 class="uk-margin-remove qt-font-bold qt-color-black">
                                    Creative Design

                                </h3> 
                                <p class="uk-margin-remove qt-margin-t-10">With the objective to build eye-catching, appealing designs that fulfill our client’s requirements as well as their target audience and market.
                                    <br>
                                    <br>
                                    We craft world-class designs, thoroughly and attentively empathize with the specifications of the customer.
                                    </p>
                                <div class="uk-margin">
                                    <a [routerLink]="['/creative-design']" class="uk-button uk-button-secondary qt-btn qt-bg-black">Know More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </li> 
                <li class="uk-animation-fade">
                    <div class="uk-card uk-card-default uk-grid-collapse uk-child-width-1-2@s uk-margin" uk-grid>
                        <div class="uk-flex-last@s uk-card-media-right uk-cover-container uk-padding uk-text-right">
                            <img src="../../assets/images/white_papers_1.jpg" alt="Software Outsourcing Company" uk-contain> 
                        </div>
                        <div>
                            <div class="uk-card-body uk-padding-large">
                                <h3 class="uk-margin-remove qt-font-bold qt-color-black">
                                    Digital Marketing
                                </h3> 
                                <p class="uk-margin-remove qt-margin-t-10">Leveraging versatile tactics, we produce predictive results.<br>
                                    We help to accelerate the investment in marketing initiatives, small as well as large business enterprises and organizations, for strategies and tools that have the most potential to engage people.
                                    </p>
                                <div class="uk-margin">
                                    <a [routerLink]="['/digital-marketing']" class="uk-button uk-button-secondary qt-btn qt-bg-black">Know More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </li> 
                <li class="uk-animation-fade">
                    <div class="uk-card uk-card-default uk-grid-collapse uk-child-width-1-2@s uk-margin" uk-grid>
                        <div class="uk-flex-last@s uk-card-media-right uk-cover-container uk-padding uk-text-right">
                            <img src="../../assets/images/white_papers_2.jpg" alt="Top software consulting company in India" uk-contain> 
                        </div>
                        <div>
                            <div class="uk-card-body uk-padding-large">
                                <h3 class="uk-margin-remove qt-font-bold qt-color-black">
                                    Web Design
                                </h3> 
                                <p class="uk-margin-remove qt-margin-t-10">Assured design and development of purely customized and eminent world-class designs.
                                    <br>
                                    <br>
                                    We ensure to bring exceptional growth to your business enterprise by designing result-driven and customer-centric websites as per our clientele requirements.
                                    
                                    
                                    </p>
                                <div class="uk-margin">
                                    <a [routerLink]="['/web-design']" class="uk-button uk-button-secondary qt-btn qt-bg-black">Know More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </li> 
                <li class="uk-animation-fade">
                    <div class="uk-card uk-card-default uk-grid-collapse uk-child-width-1-2@s uk-margin" uk-grid>
                        <div class="uk-flex-last@s uk-card-media-right uk-cover-container uk-padding uk-text-right">
                            <img src="../../assets/images/white_papers_3.jpg" alt="Top Creative Agency in India " uk-contain> 
                        </div>
                        <div>
                            <div class="uk-card-body uk-padding-large">
                                <h3 class="uk-margin-remove qt-font-bold qt-color-black">
                                    SEO
                                </h3> 
                                <p class="uk-margin-remove qt-margin-t-10">Helping business enterprises, large as well as small business entities to develop SEO strategies for holistic growth.
                                    <br>
                                    <br>
                                    Comprehensive search engine optimization portfolio that helps the business enterprises to leverage the power to be on the first page, bring quality improvements, increase visibility, and achieve profitable goals by bringing targeted traffic to their websites.
                                    
                                    </p>
                                <div class="uk-margin">
                                    <a [routerLink]="['/seo']" class="uk-button uk-button-secondary qt-btn qt-bg-black">Know More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </li> 
                <li class="uk-animation-fade">
                    <div class="uk-card uk-card-default uk-grid-collapse uk-child-width-1-2@s uk-margin" uk-grid>
                        <div class="uk-flex-last@s uk-card-media-right uk-cover-container uk-padding uk-text-right">
                            <img src="../../assets/images/white_papers_4.jpg" alt="Creative Design Services" uk-contain> 
                        </div>
                        <div>
                            <div class="uk-card-body uk-padding-large">
                                <h3 class="uk-margin-remove qt-font-bold qt-color-black">
                                    Video Animations
                                </h3> 
                                <p class="uk-margin-remove qt-margin-t-10">Every business enterprise deserves to be a great success story.
                                    <br>
                                    <br>
                                    Qaptive provides powerful video animation services that help business enterprises differentiate their content and take their marketing and social media presence to the next level.
                                    
                                    
                                    </p>
                                <div class="uk-margin">
                                    <a [routerLink]="['/video-animations']" class="uk-button uk-button-secondary qt-btn qt-bg-black">Know More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </li> 
            </ul>
        </div>
    </div>
</div> 

<div class="uk-card uk-card-default uk-grid-collapse uk-child-width-1-2@s" uk-grid>
    <div class="uk-flex-first@s uk-card-media-right uk-cover-container  uk-text-right">
         <iframe width="100%" height="500" src="https://maps.google.com/maps?q=Qaptive%20technologies&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe> 
    </div>
    <div>
        <div class="uk-card-body uk-padding-large">
            <h3 class="uk-margin-remove qt-font-bold qt-color-black">
                Qaptive Technologies Pvt. Ltd.
            </h3>  
            <ul class="qt-list uk-list">
                <li><span uk-icon="location" class="qt-margin-right-10"></span> 6A-3, Jyothirmaya IT Building, Infopark CSEZ Phase 2, <br>
                    Kakkanad, Kochi, Kerala, India - 682303 </li>
                    <li><span uk-icon="location" class="qt-margin-right-10"></span>  Al Muftah Building, 3rd Floor, Office No.6, Al Rayyan Road
                        P.O. Box: 11126, Doha, Qatar.
                   </li>
                <li><span uk-icon="mail" class="qt-margin-right-10"></span> info@qaptive.co.in</li>
                <!-- <li><span uk-icon="receiver" class="qt-margin-right-10"></span>+91 484 4855790</li> -->
                <li><span uk-icon="phone" class="qt-margin-right-10"></span>+91 974 609 4422</li>
               
            </ul>
            <div class="uk-margin">
                <a [routerLink]="['contact']" class="uk-button uk-button-secondary qt-btn qt-bg-black">Enquire Now</a>
            </div>
        </div>
    </div>
</div>
<div class="uk-card uk-card-default uk-grid-collapse uk-padding-large uk-child-width-1-2@s qt-bg-dark" uk-grid>
    <div class="uk-flex-first@s uk-card-media-right uk-cover-container uk-text-left uk-inline">
        <div class="uk-card-body uk-padding-large">
            <h5 class="qt-color-light qt-font-bold">CAREERS</h5> 
            <h2 class="qt-color-white uk-margin-remove-top qt-font-bold qt-mobile-h1">Join our team to create top-notch, sophisticated software solutions across different business domains
            </h2>
        </div>
        
    </div>
    <div>
        <div class="uk-card-body uk-padding-large">
            <h5 class="qt-color-white qt-font-bold">TEAM</h5> 
            <p class="qt-color-white">Qaptive is a team of technologists with expertise in building superior software excellences for world-class business organizations

            </p>
            <div class="uk-margin">
                <a [routerLink]="['/careers']" class="uk-button uk-button-secondary qt-btn qt-bg-red">Open Positions</a>
            </div>
        </div>
    </div>
</div>